const Close = () => {
  return (
    <svg
      width="18px"
      height="19px"
      viewBox="0 0 18 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>close</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-200.000000, -385.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="close" transform="translate(200.350000, 385.200000)">
            <line
              x1="0.575"
              y1="0.575"
              x2="16.675"
              y2="17.825"
              id="Line"
            ></line>
            <line
              x1="0.575"
              y1="0.575"
              x2="16.675"
              y2="17.825"
              id="Line-Copy"
              transform="translate(8.625000, 9.200000) scale(-1, 1) translate(-8.625000, -9.200000) "
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Close;
