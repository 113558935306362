import OnSendingVideo from "../../components/UI/OnSendingVideo";

const SendingVideoPage = () => {
  return (
    <main id="sending-video-page">
      <OnSendingVideo />
    </main>
  );
};

export default SendingVideoPage;
