import React from "react";

const SvgMonkey = () => {
  return (
    <svg
      width="82px"
      height="105px"
      xmlns="http://www.w3.org/2000/svg"
      id="emoji"
      viewBox="0 0 72 72"
    >
      <g id="line-supplement">
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M53.3026,38.9999 c1.3354-3.979-0.0762-8.7844-3.6538-10.9999c-4.1779-2.5872-10.4534-1.1119-13.4909,3.952 C33.1952,26.8785,26.8991,25.3716,22.667,28c-3.4854,2.1647-5.2226,6.9179-3.6538,11"
        />
      </g>
      <g id="color">
        <path
          fill="#6A462F"
          d="M19.6924,19.3322c-0.3299,0-7.1412-2.2454-10.3066,3.9209c-2.7954,5.4454,0.1467,9.812,3.4856,13.3114"
        />
        <path
          fill="#6A462F"
          d="M52.2203,19.3322c0.3299,0,7.1412-2.2454,10.3066,3.9209c2.7954,5.4454-0.1467,9.812-3.4856,13.3114"
        />
        <path
          fill="#6A462F"
          d="M59.8151,35.5725C59.8151,49.0635,49.5415,60,36.1189,60S11.8151,49.0635,11.8151,35.5725 C13.7908,21.8287,22.6963,12,36.1189,12C48.885,12,58.2975,22.3954,59.8151,35.5725z"
        />
        <path
          fill="#A57939"
          d="M49.6488,27.0208c-4.1779-2.5872-10.4534-1.1119-13.4909,3.952c-2.9627-5.0735-9.2588-6.5804-13.4909-3.952 c-3.4854,2.1647-5.2226,6.9179-3.6538,11c1.7617,4.5837,6.6722,5.6682,7.0266,5.7402c-1.3571,6.1786-0.9503,11.8229,0.0668,15.2261 c6.6579,1.3446,13.2877,1.3563,19.8885,0c1.8269-3.5132,2.108-7.9185,0.5621-14.664c0.4676-0.1376,5.1701-1.6083,6.7454-6.3024 C54.638,34.0417,53.2264,29.2363,49.6488,27.0208z"
        />
        <path
          fill="#FFFFFF"
          d="M41.9781,49.017c-0.2036,0.2681-0.7527,6.0217-6.3387,6.0246c-4.9521,0.0025-5.4413-5.6762-5.7048-6.0246 C33.9491,49.017,37.9636,49.017,41.9781,49.017z"
        />
        <path
          fill="#A57939"
          d="M22.3305,60.6233c1.8841-3.9279-0.7967,1.6609,1.0874-2.267c-0.5664-1.6119-1.2604-4.0223-1.5384-7.0087 c-0.425-4.5648,0.1233-10.4598,1.5384-10.6624c0.555-0.0795,1.4734,0.6838,3.0324,4.7202c2.6006-3.0933,8.3652-6.2699,8.999-5.4998 c0.6228,0.7568-0.0903,2.5375-0.0098,3.503c2.6552-1.2069,3.9497-1.4041,4.2664-1.0505c0.3186,0.3557-0.0824,1.6942-1.7721,4.3494 c3.1158-0.7429,4.1039,0.1181,3.9426,0.8046c-0.6076,2.5859-2.7004,5.9308-6.4366,9.6067 c-2.0968,2.0629-4.741,2.5726-6.3992,2.9133c-0.6095,5.022,0.0557-1.9975-0.5538,3.0245"
        />
        <path
          fill="#6A462F"
          d="M23.6494,58.3562c0,0-1.0936,1.828-1.7359,3.0995l6.1562,2.4335c0.6311-1.5839,1.2022-3.857,1.2022-3.857 L23.6494,58.3562z"
        />
        <path
          fill="#A57939"
          d="M50.0931,60.3934c-1.8841-3.9279,0.6032,0.978-1.2808-2.9498c0.5664-1.6119,1.2604-4.0223,1.5384-7.0087 c0.425-4.5648-0.1233-10.4598-1.5384-10.6624c-0.555-0.0795-1.4734,0.6838-3.0324,4.7202 c-2.6006-3.0933-8.3652-6.2699-8.999-5.4998c-0.6228,0.7568,0.0903,2.5375,0.0098,3.503 c-2.6552-1.2069-3.9497-1.4041-4.2664-1.0505c-0.3186,0.3557,0.0824,1.6942,1.7721,4.3494 c-3.1158-0.7429-4.1039,0.1181-3.9426,0.8046c0.6076,2.5859,2.7004,5.9308,6.4366,9.6067c2.0968,2.0629,4.741,2.5726,6.3992,2.9133 c0.6095,5.022-0.1317-1.0847,0.4778,3.9373"
        />
        <path
          fill="#6A462F"
          d="M43.1868,59.0958C43.181,59.0481,43.1697,58.9547,43.1868,59.0958L43.1868,59.0958z"
        />
        <path
          fill="#6A462F"
          d="M43.1897,59.1195L43.1897,59.1195c-0.0009-0.0074-0.0021-0.0173-0.0029-0.0237 C43.1884,59.1088,43.1897,59.1195,43.1897,59.1195z"
        />
        <path
          fill="#6A462F"
          d="M48.7522,57.4615l0.0601-0.0179C48.8123,57.4435,48.6578,57.1935,48.7522,57.4615z"
        />
        <path
          fill="#6A462F"
          d="M50.6131,60.9934c0,0-1.7696-3.2729-1.8609-3.5319l-5.5625,1.658 c0.0319,0.2634,0.1424,1.1735,0.4778,4.9373L50.6131,60.9934z"
        />
      </g>
      <g id="hair" />
      <g id="skin" />
      <g id="skin-shadow" />
      <g id="line">
        <circle cx="26.8369" cy="35.2509" r="3" />
        <circle cx="45.3933" cy="35.2509" r="3" />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M19.7774,19.6545c-0.3299,0-7.1412-2.2454-10.3066,3.9209c-2.7954,5.4454,0.1467,9.8121,3.4855,13.3115"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M52.3053,19.6545c0.3299,0,7.1412-2.2454,10.3066,3.9209c2.7954,5.4454-0.1467,9.812-3.4856,13.3114"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M28.49,63.0606l0.55-3.03c0,0,4.3-0.85,6.4-2.91c0.39-0.38,0.76-0.77,1.12-1.14"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M35.59,41.9806c0.12-0.79,0.23-1.62-0.14-2.07c-0.63-0.78-6.4,2.4-9,5.49c-1.56-4.03-2.48-4.79-3.03-4.72 c-1.42,0.21-1.97,6.1-1.54,10.67c0.28,2.98,0.97,5.39,1.54,7.01c-0.16,0.33-0.29,0.59-0.39,0.8v0.01 c-0.01,0.03-0.03,0.06-0.04,0.08c-0.02,0.03-0.04,0.07-0.05,0.11c-0.02,0.04-0.03,0.07-0.05,0.09c0,0.02-0.01,0.03-0.01,0.04 c-0.01,0-0.01,0.01-0.01,0.02c-0.01,0.01-0.02,0.03-0.02,0.04c-0.02,0.04-0.04,0.07-0.05,0.1"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M22.33,60.6206c0.15-0.32,0.28-0.58,0.38-0.78c0,0,0-0.01,0.01-0.02v-0.01c0.01-0.01,0.02-0.02,0.02-0.04 c0.02-0.04,0.04-0.08,0.06-0.12c0.01-0.03,0.03-0.06,0.05-0.1c0-0.01,0.01-0.03,0.02-0.04c0-0.01,0-0.02,0.01-0.02 c0.02-0.05,0.04-0.09,0.06-0.13"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M35.59,41.9806c0.12-0.79,0.23-1.62-0.14-2.07c-0.63-0.78-6.4,2.4-9,5.49c-1.56-4.03-2.48-4.79-3.03-4.72 c-1.42,0.21-1.97,6.1-1.54,10.67c0.28,2.98,0.97,5.39,1.54,7.01c-0.16,0.33-0.29,0.59-0.39,0.8v0.01 c-0.01,0.03-0.03,0.06-0.04,0.08c-0.02,0.03-0.04,0.07-0.05,0.11c-0.02,0.04-0.03,0.07-0.05,0.09c-0.01,0.02-0.02,0.04-0.02,0.06 c-0.01,0.01-0.02,0.03-0.02,0.04c-0.02,0.04-0.04,0.07-0.05,0.1c-0.02,0.04-0.04,0.08-0.06,0.12c0,0.02-0.01,0.03-0.02,0.04v0.01 c-0.01,0.01-0.01,0.02-0.01,0.02c-0.1,0.2-0.23,0.46-0.38,0.78"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M50.0931,60.1137c-1.8841-3.9279,0.6032,1.2576-1.2808-2.6702c0.5664-1.6119,1.2604-4.0223,1.5384-7.0087 c0.425-4.5648-0.1233-10.4598-1.5384-10.6624c-0.555-0.0795-1.4734,0.6838-3.0324,4.7202 c-2.6006-3.0933-8.3652-6.2699-8.999-5.4998c-0.6228,0.7568,0.0903,2.5375,0.0098,3.503 c-2.6552-1.2069-3.9497-1.4041-4.2664-1.0505c-0.3186,0.3557,0.0824,1.6942,1.7721,4.3494 c-3.1158-0.7429-4.1039,0.1181-3.9426,0.8046c0.6076,2.5859,2.7004,5.9308,6.4366,9.6067c2.0968,2.0629,4.741,2.5726,6.3992,2.9133 c0.6095,5.022-0.1317-1.0847,0.4778,3.9373"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M17.9101,50.2056 C14.8346,46.2945,13,41.3615,13,36c0-12.7025,10.2975-23,23-23s23,10.2975,23,23c0,5.2036-1.728,10.0035-4.6415,13.8573"
        />
      </g>
    </svg>
  );
};

export default SvgMonkey;
