import {useParams} from "react-router";
import {
    MainInfo,
    ModalChooseAction,
    TitlePage,
    VideoGridSceneSelect,
} from "../../components";
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {IUserSelectScene} from "../../utils/types";
import axios from "axios";
import {checkResponseErrors} from "../../utils/FunctionCommon";
import {set} from "js-cookie";

const SceneSelectPage = () => {
    const {router, setPageSelected, userJwt, navIsOpen, setNotify} =
        useContext(GlobalContext);
    const {batchId, optionalJobId} = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [jobId, setJobId] = useState<string | number>();
    const [configData, setConfigData] = useState<{
        batchId: string;
        videoConfiguration: {
            type: string;
            format: string;
            aspect_ratio: string;
            autocreate: boolean;
            masterAudio: string;
        };
    }>();
    const [userVideos, setUserVideos] = useState<IUserSelectScene[]>([]);
    const [toggleModal, setToggleModal] = useState<boolean>(false);

    /* ******** methods ******** */
    const onSceneSelected = (id: string) => {
        const copyVideo = userVideos;

        const selectedVideoIndex = copyVideo.findIndex((vid) => vid.id === id);

        copyVideo[selectedVideoIndex].selected =
            !copyVideo[selectedVideoIndex].selected;

        setUserVideos([...copyVideo]);
    };

    const onToggleModal = () => {
        if (!userVideos.some((vid) => vid.selected)) return;
        setToggleModal((prev) => !prev);
    };

    const goToManualEdit = () => {
        const copyUserVideo = userVideos;
        let videosToSend: any[] = [];

        for (let i = 0; i < copyUserVideo.length; i++) {
            const video: any = copyUserVideo[i];

            if (video.selected) {
                const {id, url, path} = video;
                const videoToSend = {id, url, path};

                videosToSend.push(videoToSend);
            }
        }

        router.navigate(`/scene-select/${batchId}/manual-edit`, {
            state: {
                videos: videosToSend,
                newJobId: jobId,
                config: configData,
            },
        });
    };

    const goToSendingVideo = () => {
        const copyUserVideo = userVideos;
        let videosToSend: any[] = [];

        for (let i = 0; i < copyUserVideo.length; i++) {
            const video: any = copyUserVideo[i];

            if (video.selected && video.path.includes("1080")) {
                const {path} = video;
                videosToSend.push(path);
            }
        }

        setPageSelected("/sending-video");
        router.navigate(`/sending-video`, {
            state: {
                videoPathKey: videosToSend,
                config: {...configData, jobId: jobId},
            },
        });
    };

    const handleMultipleDownload = () => {
        userVideos.forEach((video, i) => {
            setTimeout(() => {
                if (video.selected) {
                    const videoUrl = video.url;

                    fetch(videoUrl)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", "video" + i + ".mp4");
                            document.body.appendChild(link);
                            link.click();
                        });

                    // const contenutoFile = video.url; // Sostituisci con il contenuto del file effettivo
                    // const tipoMIME = "application/octet-stream"; // Specifica il tipo MIME del file

                    // const blob = new Blob([contenutoFile], { type: tipoMIME });
                    // const url = URL.createObjectURL(blob);

                    // const nomeFile = "video" + i + ".mp4"; // Sostituisci con il nome del file desiderato

                    // const linkDownload = document.createElement("a");
                    // linkDownload.href = url;
                    // linkDownload.download = nomeFile;
                    // document.body.appendChild(linkDownload);
                    // linkDownload.click();
                    // document.body.removeChild(linkDownload);
                }
            }, 1000);
        });
    };

    const handleVideoData = (response: any) => {
        const responseData = response.data;
        const responseEntries = Object.entries(responseData);
        let videosArr: IUserSelectScene[] = [];

        for (let i = 0; i < responseEntries.length; i++) {
            const [key, value] = responseEntries[i];

            const video: IUserSelectScene = {
                id: `video${i}`,
                url: String(value),
                path: key,
                selected: false,
            };
            videosArr.push(video);
        }

        setUserVideos(videosArr);
    }
    /* ******* API ****** */
    const getScenefromJob = (job: string) => {
        return axios
            .get(
                process.env.REACT_APP_API_URL +
                `media/preProcessedVideos/list?id=${jobId ? jobId : job}`,
                {headers: {Authorization: `Bearer ${userJwt}`}}
            );
    };

    const getConfigData = (batchId: string) => {
        return axios.get(
            process.env.REACT_APP_API_URL +
            `job/list?batchId=${batchId}`,
            {headers: {Authorization: `Bearer ${userJwt}`}}
        );
    };

    useEffect(() => {
        const routerState = router.state.location.state;

        if (!batchId) {
            setPageSelected("/");
            router.navigate("/");
            return;
        }

        const fetchData = async () => {
            if (!routerState) {
                // first, get the config data. If that is sucessful, get the job data
                await getConfigData("" + batchId)
                    .then((res) => {
                        console.log(batchId, res.data);
                        const job = res.data.find((job: any) => {
                            return job.batch_id === parseInt(batchId);
                        });

                        if (job) {
                            setConfigData({
                                batchId: job.batch_id,
                                videoConfiguration: job.config,
                            });
                            setJobId(job.id);
                        } else {
                            setJobId(optionalJobId);
                        }
                      return getScenefromJob("" + jobId)
                          .then((response) => {
                              handleVideoData(response);
                          })
                          .catch((error) => {
                              if (error) {
                                  console.log(error);
                              }
                          }).finally(() => setIsLoading(false));
                    }).catch((err) => {
                        console.log(err)
                    }).finally(() => {

                    });
            } else {
                const {newJobId, config} = routerState;
                await setJobId(newJobId);
                await setConfigData(config);
                await getScenefromJob(newJobId).then((response) => {
                    handleVideoData(response);
                })
                    .catch((error) => {
                        if (error) {
                            console.log(error);
                        }
                    }).finally(() => setIsLoading(false));
            }
        }
        fetchData();
    }, [userJwt, optionalJobId, batchId, jobId]);

    if (isLoading)
        return <p>Still loading</p>;
    return (
        <>
            <main id="scene-select-page" className="h_full-nav btn_inline">
                <TitlePage title="Scene select" classes="uppercase"/>
                <MainInfo
                    text={`Choose your favorite scenes and edit them.`}
                />

                {/* CONDIZIONE DA TOGLIERE BUG BUG BUG */}
                <VideoGridSceneSelect
                    videoList={userVideos}
                    onSceneSelected={onSceneSelected}
                />
                <div className="container-action-btn">
                    <button
                        className={`default-big primary-color h-48 bottom fs-1 ${
                            navIsOpen ? "btn-translate" : " "
                        }`}
                        style={
                            userVideos.some((vid) => vid.selected)
                                ? {opacity: 1}
                                : {opacity: 0.3}
                        }
                        onClick={goToManualEdit}
                    >
                        Edit Video
                    </button>
                    <button onClick={handleMultipleDownload}
                            className={`default-big secondary-color h-48 bottom fs-1 ${
                                navIsOpen ? "btn-translate" : " "
                            }`}
                            style={
                                userVideos.some((vid) => vid.selected)
                                    ? {opacity: 1}
                                    : {opacity: 0.3}
                            }
                    >Download selected Scenes
                    </button>
                </div>
            </main>
        </>
    );
};

export default SceneSelectPage;

/*
{toggleModal && (
        <ModalChooseAction
          clickAutomatedEdit={goToSendingVideo}
          clickManualEdit={goToManualEdit}
          closeModal={onToggleModal}
          downloadVideoSelected={handleMultipleDownload}
        />
      )}
 */