const SvgSignOut = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>sign-out</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-650.000000, -70.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="sign-out" transform="translate(650.000000, 70.000000)">
            <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
            <g
              id="Group"
              transform="translate(9.150000, 12.000000) rotate(90.000000) translate(-9.150000, -12.000000) translate(6.450000, 8.550000)"
            >
              <path
                d="M3.9,-1.5 L1.5,1.2 L3.9,3.9"
                id="Path-2"
                transform="translate(2.700000, 1.200000) rotate(90.000000) translate(-2.700000, -1.200000) "
              ></path>
              <line x1="2.64" y1="0.9" x2="2.64" y2="6.9" id="Line-2"></line>
            </g>
            <path
              d="M19.6207869,10.3235294 L19.6207869,11.7 C19.6207869,12.7915746 18.8686253,13.6764706 17.9407869,13.6764706 L12.9007869,13.6764706 C11.9729485,13.6764706 11.2207869,12.7915746 11.2207869,11.7 L11.2207869,10.3235294"
              id="Path"
              transform="translate(15.420787, 12.000000) scale(-1, 1) rotate(90.000000) translate(-15.420787, -12.000000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgSignOut;
