import { bgColorVideoEffect, svgEffectClass } from "../../utils/FunctionCommon";
import Svg from "../Svg/Svg";

interface Props {
  videoList: any[];
  videoSelected: string;
  onSelectVideo: (id: string) => void;
}

const VideoGridAddEffects = ({
  videoList,
  videoSelected,
  onSelectVideo,
}: Props) => {
  return (
    <div className="effect-video-container">
      <div className="video-container-scrollable">
        {videoList.map((video, i) => (
          <div
            key={i}
            className={`video ${videoSelected === video.id ? "selected" : ""}`}
            onClick={() => onSelectVideo(video.id)}
          >
            <video
              key={`${video.id}-${i}`}
              preload="metadata"
              muted
              src={video.url + "#t=0.001"}
              playsInline
            />

            {video.effect !== "no_effect" && (
              <Svg
                className={svgEffectClass(video.effect) + "-icon"}
                style={{ backgroundColor: bgColorVideoEffect(video.effect) }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoGridAddEffects;
