const SvgFullScreen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="27.09375px"
      height="22.5px"
      viewBox="0 0 27.09375 22.5"
      version="1.1"
    >
      <title>icons/video player/close blank</title>
      <g
        id="layout-mvp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="video-player/button-close-copy-2"
          transform="translate(-12.625000, -9.250000)"
          fill="#FFFFFF"
        >
          <g
            id="icons/video-player/close-blank"
            transform="translate(12.625000, 9.250000)"
          >
            <path
              d="M25.34375,0 C26.3102483,0 27.09375,0.783501688 27.09375,1.75 L27.09375,20.75 C27.09375,21.7164983 26.3102483,22.5 25.34375,22.5 L1.75,22.5 C0.783501688,22.5 0,21.7164983 0,20.75 L0,1.75 C0,0.783501688 0.783501688,0 1.75,0 L25.34375,0 Z M25.34375,1.5 L1.75,1.5 C1.61192881,1.5 1.5,1.61192881 1.5,1.75 L1.5,20.75 C1.5,20.8880712 1.61192881,21 1.75,21 L25.34375,21 C25.4818212,21 25.59375,20.8880712 25.59375,20.75 L25.59375,1.75 C25.59375,1.61192881 25.4818212,1.5 25.34375,1.5 Z"
              id="Rectangle"
              fillRule="nonzero"
            />
            <path
              d="M6.90690683,11.7505682 L6.93470786,11.7533354 C6.94340664,11.7542513 6.95208783,11.7553557 6.9607448,11.7566481 C7.0792201,11.7711084 7.19422063,11.8222091 7.28887301,11.9093119 L10.1432745,14.5360409 C10.4185367,14.7893476 10.4552501,15.2107458 10.2279403,15.5078381 C10.2168491,15.5223342 10.2051055,15.5363193 10.1927459,15.5497503 C9.96720334,15.7948418 9.5856791,15.8106893 9.34058763,15.5851468 L7.594,13.978 L7.59459764,18.6185497 C7.59459764,19.0327632 7.2588112,19.3685497 6.84459764,19.3685497 C6.43038408,19.3685497 6.09459764,19.0327632 6.09459764,18.6185497 L6.094,14.035 L4.41122698,15.5851468 C4.16613551,15.8106893 3.78461127,15.7948418 3.55906876,15.5497503 C3.54670908,15.5363193 3.5349655,15.5223342 3.5238743,15.5078381 C3.29656449,15.2107458 3.33327792,14.7893476 3.60854008,14.5360409 L6.4629416,11.9093119 C6.55798797,11.8218466 6.67355226,11.7706836 6.79258067,11.7551145 L6.81622987,11.7534284 L6.84621057,11.7506329 L6.90690683,11.7505682 Z"
              id="Combined-Shape"
              transform="translate(6.875907, 15.559275) scale(-1, 1) rotate(135.000000) translate(-6.875907, -15.559275) "
            />
            <path
              d="M19.9069068,3.75056815 L19.9347079,3.75333539 C19.9434066,3.75425133 19.9520878,3.75535565 19.9607448,3.7566481 C20.0792201,3.77110837 20.1942206,3.82220915 20.288873,3.90931186 L23.1432745,6.53604091 C23.4185367,6.78934764 23.4552501,7.21074581 23.2279403,7.50783807 C23.2168491,7.52233419 23.2051055,7.53631932 23.1927459,7.54975028 C22.9672033,7.79484176 22.5856791,7.81068932 22.3405876,7.5851468 L20.594,5.978 L20.5945976,10.6185497 C20.5945976,11.0327632 20.2588112,11.3685497 19.8445976,11.3685497 C19.4303841,11.3685497 19.0945976,11.0327632 19.0945976,10.6185497 L19.094,6.035 L17.411227,7.5851468 C17.1661355,7.81068932 16.7846113,7.79484176 16.5590688,7.54975028 C16.5467091,7.53631932 16.5349655,7.52233419 16.5238743,7.50783807 C16.2965645,7.21074581 16.3332779,6.78934764 16.6085401,6.53604091 L19.4629416,3.90931186 C19.557988,3.82184658 19.6735523,3.77068358 19.7925807,3.7551145 L19.8162299,3.75342837 L19.8462106,3.7506329 L19.9069068,3.75056815 Z"
              id="Combined-Shape-Copy"
              transform="translate(19.875907, 7.559275) scale(1, -1) rotate(135.000000) translate(-19.875907, -7.559275) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgFullScreen;
