import { useContext, useEffect, useState } from "react";
import {
  EmptyVideoUploadNotice,
  TitlePage,
  VideoGridMyVideos,
} from "../../components";
import { checkResponseErrors } from "../../utils/FunctionCommon";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";

const MyVideoPage = () => {
  const { router, setPageSelected, userJwt } = useContext(GlobalContext);
  const [userVideos, setUserVideos] = useState<any[] | []>([]);

  /* ******** methods ******** */
  const goToHome = () => {
    setPageSelected("/");
    router.navigate("/");
  };

  /* ******** API ******** */
  const getMyVideosList = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + `media/finalVideosList`, {
        headers: { Authorization: `Bearer ${userJwt}` },
      })
      .then((response) => {
        setUserVideos(response.data);
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  useEffect(() => {
    getMyVideosList();

    return () => {};
  }, []);
  return (
    <main id="my-videos-page" className="h_full-nav btn-inline">
      <TitlePage title="My videos" classes="uppercase" />

      {userVideos.length === 0 && (
        <EmptyVideoUploadNotice
          noticeText="It's pretty empty here.
        Start your first upload."
        />
      )}

      <VideoGridMyVideos videoList={userVideos} />
      {/* <VideoGridMyVideos videoList={arraySortForData(userVideos)} /> */}

      {userVideos.length === 0 && (
        <button
          className="default-big default-color h-48 bottom"
          onClick={goToHome}
        >
          Go to upload Page
        </button>
      )}
    </main>
  );
};

export default MyVideoPage;
