const SvgSpeakerOn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24.3216439px"
      height="20.5023602px"
      viewBox="0 0 24.3216439 20.5023602"
      version="1.1"
    >
      <title>icons/video player/close blank copy</title>
      <g
        id="layout-mvp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="video-player/button-audio-copy"
          transform="translate(-14.250000, -10.163666)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g
            id="icons/video-player/close-blank-copy"
            transform="translate(14.250000, 10.163666)"
          >
            <path
              d="M14.4545455,0.75123232 L14.4545455,19.7512323 C14.4545455,20.3298397 13.8270946,20.6905236 13.3271,20.3993333 L4.729,15.392 L0.75,15.3929112 C0.335786438,15.3929112 1.77635684e-15,15.0571248 1.77635684e-15,14.6429112 L1.77635684e-15,6.00654758 C1.77635684e-15,5.59233402 0.335786438,5.25654758 0.75,5.25654758 L5.06818182,5.25654758 C5.14872334,5.25654758 5.22629965,5.26924321 5.2990187,5.29274245 L13.2972898,0.121436448 C13.7963229,-0.201261851 14.4545455,0.156952712 14.4545455,0.75123232 Z M12.9541818,2.12823232 L5.81818182,6.74323232 L5.81818182,14.2902323 L12.9541818,18.4462323 L12.9541818,2.12823232 Z M21.9856936,4.20049652 C23.5358165,6.32243614 24.3216439,8.47385042 24.3216439,10.6429112 C24.3216439,12.811972 23.5358165,14.9633863 21.9856936,17.0853259 C21.7413547,17.4197977 21.272136,17.4928649 20.9376642,17.248526 C20.6031924,17.0041871 20.5301252,16.5349683 20.7744641,16.2004965 C22.1464279,14.3224361 22.8216439,12.4738504 22.8216439,10.6429112 C22.8216439,8.81197202 22.1464279,6.9633863 20.7744641,5.08532592 C20.5301252,4.75085413 20.6031924,4.28163536 20.9376642,4.03729647 C21.272136,3.79295757 21.7413547,3.86602472 21.9856936,4.20049652 Z M18.599996,5.56413288 C19.740584,7.12546645 20.3216439,8.71627466 20.3216439,10.3247294 C20.3216439,11.9331841 19.740584,13.5239924 18.599996,15.0853259 C18.3556571,15.4197977 17.8864383,15.4928649 17.5519665,15.248526 C17.2174947,15.0041871 17.1444276,14.5349683 17.3887664,14.2004965 C18.3511954,12.8830422 18.8216439,11.5950625 18.8216439,10.3247294 C18.8216439,9.05439626 18.3511954,7.7664166 17.3887664,6.44896229 C17.1444276,6.11449049 17.2174947,5.64527172 17.5519665,5.40093283 C17.8864383,5.15659394 18.3556571,5.22966109 18.599996,5.56413288 Z M4.318,6.75654758 L1.5,6.75654758 L1.5,13.8925476 L4.318,13.8925476 L4.318,6.75654758 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgSpeakerOn;
