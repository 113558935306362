import { FormEvent, useContext, useEffect, useState } from "react";
import Div from "../UI/Div";
import GlobalContext from "../../context/GlobalContext";
import Svg from "../Svg/Svg";
import { Link } from "react-router-dom";
import { postUserRegister } from "../../utils/apyService";

const checkbox = [
  {
    id: "check-0",
    text: "I accept that my video data will be processed via the AWS cloud.",
    error: "Please agree to the processing of your videos",
  },
  {
    id: "check-1",
    text: "I agree to be contacted by play",
    error: "Please agree the contract with play",
  },
];

interface Props {
  registerConfirmHandler: () => void;
  saveOnStateEmail: (email: string) => void;
}

const RegisterForm = ({ registerConfirmHandler, saveOnStateEmail }: Props) => {
  const { router, userJwt } = useContext(GlobalContext);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [userMail, setUserMail] = useState<{ mail: string; error: string }>({
    mail: "",
    error: "",
  });
  const [userPassword, setUserPassword] = useState<{
    psw: string;
    error: boolean;
  }>({
    psw: "",
    error: false,
  });

  const [check0, setCheck0] = useState<{
    clicked: boolean;
    errorVisible: boolean;
  }>({
    clicked: false,
    errorVisible: false,
  });
  const [check1, setCheck1] = useState<{
    clicked: boolean;
    errorVisible: boolean;
  }>({
    clicked: false,
    errorVisible: false,
  });

  const onChangeUserMail = (e: any) => {
    const value = e.target.value;

    setUserMail({ mail: value, error: "" });
  };

  const onChangeUserPassword = (e: any) => {
    const value = e.target.value;
    setUserPassword({ psw: value, error: false });
  };

  const onCheck0Handler = () => {
    setCheck0((prev) => {
      return {
        clicked: !prev.clicked,
        errorVisible: false,
      };
    });
  };

  const onCheck1Handler = () => {
    setCheck1((prev) => {
      return {
        clicked: !prev.clicked,
        errorVisible: false,
      };
    });
  };

  const onSubmitRegisterForm = (e: FormEvent) => {
    e.preventDefault();

    if (userPassword.psw.trim().length < 4) return;
    if (!userMail.mail.includes("@")) return;
    if (!check0.clicked) {
      setCheck0((prev) => {
        return {
          clicked: prev.clicked,
          errorVisible: true,
        };
      });
    }

    saveOnStateEmail(userMail.mail);

    postUserRegister(
      registerConfirmHandler,
      setUserMail,
      setUserPassword,
      userMail.mail,
      userPassword.psw
    );
  };

  useEffect(() => {
    if (!userJwt) return;
    router.navigate("/");
  }, [userJwt]);

  useEffect(() => {
    if (
      userPassword.psw.trim().length > 3 &&
      userMail.mail.includes("@") &&
      check0.clicked
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  });

  return (
    <form onSubmit={onSubmitRegisterForm}>
      <div className="register-form">
        <div
          className={`input-control ${userMail.error !== "" ? "error" : ""}`}
        >
          <input
            className="email"
            name="email"
            type="email"
            placeholder="Your email address"
            value={userMail.mail}
            onChange={onChangeUserMail}
            required
          />
          {userMail.error !== "" && (
            <span className="error-message">{userMail.error}</span>
          )}
        </div>

        <div className={`input-control ${userPassword.error ? "error" : ""}`}>
          <input
            className="password"
            name="password"
            type="password"
            placeholder="Your password"
            value={userPassword.psw}
            onChange={onChangeUserPassword}
            required
          />
          {userPassword.error && (
            <span className="error-message">Please enter a valid password</span>
          )}
        </div>

        <div className={`checkbox-control`}>
          <div className="check-container" onClick={onCheck0Handler}>
            <span
              className={`check`}
              style={check0.errorVisible ? { borderColor: "red" } : {}}
            >
              {check0.clicked && <Svg className="check-big" />}
            </span>
            {checkbox[0].text}
          </div>

          {check0.errorVisible && (
            <span className="error-message">{checkbox[0].error}</span>
          )}
        </div>

        <div className={`checkbox-control`}>
          <div className="check-container" onClick={onCheck1Handler}>
            <span
              className={`check`}
              style={check1.errorVisible ? { borderColor: "red" } : {}}
            >
              {check1.clicked && <Svg className="check-big" />}
            </span>
            {checkbox[1].text}
          </div>

          {check1.errorVisible && (
            <span className="error-message">{checkbox[1].error}</span>
          )}
        </div>

        <div className="privacy-policy">
          Of course we respect your privacy.
          <br />
          Find here our <Link to={"/privacy-policy"}>privacy policy</Link>
        </div>

        <div className="option-container">
          <button className={`default-big ${canSubmit ? "" : "not-active"}`}>
            Register
          </button>
          <Div
            className="go-to-login"
            onClick={() => router.navigate("/login")}
          >
            I already have an account
          </Div>
        </div>
      </div>
    </form>
  );
};

export default RegisterForm;
