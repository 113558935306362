import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";
import { IUserSelectScene } from "../../utils/types";
import { useEffect, useState } from "react";

interface Props {
  videos: IUserSelectScene[] | [];
  onDropVideos: (newArrayVideos: IUserSelectScene[]) => void;
}

const VideoGridManualEdit = ({ videos, onDropVideos }: Props) => {
  const [rowElement, setRowElement] = useState<number>(
    window.innerWidth < 768 ? 3 : 4
  );

  const [rowGridHeight, setRowGridHeight] = useState<number>(
    window.innerWidth < 525 ? 100 : window.innerWidth < 768 ? 130 : 100
  );

  const onDragDropHandle = (
    sourceId: string, // l'id della dropZone
    sourceIndex: number, // la posizione dell'elemento che spostiamo
    targetIndex: number, // la posizione dove deve essere l'elemento
    targetId: any // nel caso avessimo due griglie
  ) => {
    if (!videos) return;

    const result = swap(videos, sourceIndex, targetIndex);

    return onDropVideos(result);
  };

  const gridSetData = () => {
    const windowWidth = window.innerWidth;
    const video = document.querySelector("video");

    if (windowWidth < 788) {
      if (!video) return;
      if (rowElement !== 3) setRowElement(3);

      const videoHeight = video.clientHeight;
      setRowGridHeight(videoHeight + 20);
    } else {
      if (!video) return;
      if (rowElement !== 4) setRowElement(4);

      const videoHeight = video.clientHeight;
      setRowGridHeight(videoHeight + 28);
    }
  };

  useEffect(() => {
    gridSetData();
  }, []);

  useEffect(() => {
    const gridSetData = () => {
      const windowWidth = window.innerWidth;
      const video = document.querySelector("video");

      if (windowWidth < 788) {
        if (!video) return;
        if (rowElement !== 3) setRowElement(3);

        const videoHeight = video.clientHeight;
        setRowGridHeight(videoHeight + 20);
      } else {
        if (!video) return;
        if (rowElement !== 4) setRowElement(4);

        const videoHeight = video.clientHeight;
        setRowGridHeight(videoHeight + 28);
      }
    };

    window.addEventListener("resize", gridSetData);
  });

  return (
    <GridContextProvider onChange={onDragDropHandle}>
      <div className="edit-video-container">
        <div className="video-container-scrollable">
          <GridDropZone
            className="dropzone left"
            id="mock-videos"
            boxesPerRow={rowElement}
            rowHeight={rowGridHeight}
          >
            {videos.map((video: any, i: number) => (
              <GridItem key={video.id} className="video grid-item">
                <video
                  className="grid-item-content"
                  preload="metadata"
                  muted
                  src={video.url + "#t=0.001"}
                  playsInline
                />
              </GridItem>
            ))}
          </GridDropZone>
        </div>
      </div>
    </GridContextProvider>
  );
};

export default VideoGridManualEdit;
