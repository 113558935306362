import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import Svg from "../Svg/Svg";
import NavItem from "./NavItem";
import Cookies from "js-cookie";

const Nav = () => {
  const { router, setPageSelected, userJwt, navIsOpen, handlerOpenNav } =
    useContext(GlobalContext);

  const jwtExist = Cookies.get("token");

  const navItems: any = {
    firstRowItem: [
      {
        id: "nav-item-1",
        label: "Sign out",
        to: "/login",
        visible: jwtExist !== undefined,
        svg: "sign-out",
      },
      {
        id: "nav-item-2",
        label: "My uploads",
        to: "/my-uploads",
        visible: false /*jwtExist !== undefined*/,
        svg: "upload",
      },
      {
        id: "nav-item-3",
        label: "My videos",
        to: "/my-videos",
        visible: jwtExist !== undefined,
        svg: "play",
      },
      {
        id: "nav-item-4",
        label: "Support",
        to: "/support",
        visible: true,
        svg: "support",
      },
      {
        id: "nav-item-5",
        label: "Watch tutorials",
        to: "https://drive.google.com/drive/folders/1VO9WZyS-3ndW3B4zkwLksCY6Ub9K3-g7",
        visible: true,
        svg: "play",
      },
      {
        id: "nav-item-6",
        label: "Share the app",
        to: "",
        visible: true,
        svg: "share",
      },
      {
        id: "nav-item-7",
        label: "Delete my account",
        to: "/delete-account",
        visible: jwtExist !== undefined,
        svg: "trash",
      },
    ],
    secondRowItem: [
      {
        id: "nav-item-8",
        label: "Cookie policy",
        to: "/cookie-policy",
        visible: true,
      },
      {
        id: "nav-item-9",
        label: "Privacy policy",
        to: "/privacy-policy",
        visible: true,
      },
      {
        id: "nav-item-10",
        label: "Licence agreement",
        to: "/licence-agreement",
        visible: true,
      },
      {
        id: "nav-item-11",
        label: "Data Processing",
        to: "/data-processing",
        visible: true,
      },
      {
        id: "nav-item-12",
        label: "Imprint",
        to: "/imprint",
        visible: true,
      },
    ],
    copyrightItem: [
      {
        id: "nav-item-11",
        label: "Copyright 2023 play TRACKS GmbH",

        visible: true,
      },
    ],
  };

  return (
    <nav id="navbar" className={navIsOpen ? "show" : ""}>
      <section className="nav-header">
        <Svg
          className="play-logo"
          onClick={() => {
            router.navigate("/");
            setPageSelected("/");
            handlerOpenNav();
          }}
        />
        <Svg className="close" onClick={handlerOpenNav} />
      </section>

      <section className="nav-item-container">
        <div className="first-row-item">
          {navItems.firstRowItem.map((item: any) => (
            <NavItem
              key={item.id}
              label={item.label}
              to={item.to}
              svg={item.svg}
              visible={item.visible}
            />
          ))}
        </div>
        <div className="second-row-item">
          {navItems.secondRowItem.map((item: any) => (
            <NavItem
              key={item.id}
              label={item.label}
              to={item.to}
              visible={item.visible}
            />
          ))}
        </div>
        <div className="copyright-row-item">
          {navItems.copyrightItem.map((item: any) => (
            <NavItem
              key={item.id}
              label={item.label}
              to={item.to}
              visible={item.visible}
            />
          ))}
        </div>
      </section>
    </nav>
  );
};

export default Nav;
