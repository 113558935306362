import TitlePage from "../../components/UI/TitlePage";
import LoginForm from "../../components/LoginForm/LoginForm";
import NeverBeenHere from "../../components/LoginForm/NeverBeenHere";
import {useParams} from "react-router-dom";

const LoginPage = () => {
    let {reset} = useParams();

    return (
    <main id="login-page">
      <TitlePage title="Welcome to your own video crew" />
      <LoginForm reset={reset}/>
      <NeverBeenHere />
    </main>
  );
};

export default LoginPage;
