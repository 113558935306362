const Menu = () => {
  return (
    <svg
      width="23px"
      height="19px"
      viewBox="0 0 23 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>menu</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-112.000000, -384.000000)"
          stroke="#FFFFFF"
          strokeWidth="1.5"
        >
          <g id="menu" transform="translate(112.600000, 385.200000)">
            <line
              x1="0.494117647"
              y1="0.494117647"
              x2="21.2470588"
              y2="0.494117647"
              id="Line-3"
            ></line>
            <line
              x1="0.494117647"
              y1="8.4"
              x2="21.2470588"
              y2="8.4"
              id="Line-3-Copy"
            ></line>
            <line
              x1="0.494117647"
              y1="16.3058824"
              x2="21.2470588"
              y2="16.3058824"
              id="Line-3-Copy-2"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Menu;
