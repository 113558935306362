const SvgTrash = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <title>trash</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-739.000000, -70.000000)"
          stroke="#FFFFFF"
          strokeWidth="1.5"
        >
          <g id="trash" transform="translate(739.000000, 70.000000)">
            <circle
              id="Oval"
              fillOpacity="0.467494191"
              fill="#12161E"
              cx="12"
              cy="12"
              r="11.25"
            />
            <path
              d="M15.7466287,8.61145769 L15.7466287,15.6351072 C15.7466287,16.5816303 15.0944164,17.3489389 14.2898718,17.3489389 L9.91960099,17.3489389 C9.11505635,17.3489389 8.46284406,16.5816303 8.46284406,15.6351072 L8.46284406,8.87914587 M12.24,11.3250559 L12.24,14.7 M14.1858177,8.34893887 L14.1858177,6.53382854 C14.1858177,6.21080289 13.813125,5.94893887 13.3533852,5.94893887 L10.8560876,5.94893887 C10.3963478,5.94893887 10.0236551,6.21080289 10.0236551,6.53382854 L10.0236551,8.34893887 M7.05743184,8.61145769 L16.9425682,8.61145769"
              id="Combined-Shape"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgTrash;
