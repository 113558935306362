import { useContext } from "react";
import { dateFormatConverter } from "../../utils/FunctionCommon";
import { IUserUploadVideos } from "../../utils/types";
import Svg from "../Svg/Svg";
import GlobalContext from "../../context/GlobalContext";
import Div from "../UI/Div";

interface Props {
  videoList: IUserUploadVideos[] | [];
  onDeleteVideo: (id: string | number) => void;
}

const VideoGridMyUploads = ({ videoList, onDeleteVideo }: Props) => {
  const { setVideoToView, handleOpenFullVideo } = useContext(GlobalContext);

  return (
    <div className="my-video-container">
      <div className="video-container-scrollable">
        {videoList.length > 0 ? (
          videoList.map((video, i) => (
            <div key={i} className="video">
              <video
                key={`${video.id}-${i}`}
                preload="metadata"
                muted
                src={video.url + "#t=0.001"}
                playsInline
                onClick={() => {
                  setVideoToView(video.url + "#t=0.001");
                  handleOpenFullVideo();
                }}
              />
              <div className="date">
                {dateFormatConverter(video.creation_date)}
              </div>
              <Div
                className="delete-video"
                onClick={() => onDeleteVideo(video.id)}
              >
                <Svg className="trash" />
                Delete
              </Div>
            </div>
          ))
        ) : (
          <>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoGridMyUploads;
