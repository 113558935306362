
import { IUserUploadVideos } from "./types";
import { Router } from "react-router";


const generateName = (numLenght: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length: number) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const generateNameString = generateString(numLenght);

  return generateNameString;
};

const checkResponseErrors = (error: any) => {
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    window.location.reload();
  }
};

const bgColorVideoEffect = (svg: string) => {
  if (svg === "slow_mo") return "rgb(226 93 0 / 80%)";

  if (svg === "reverse") return "rgb(226 188 0 / 80%)";
};

const svgEffectClass = (effect: string) => {
  if (effect === "slow_mo") return "slow-motion";
  if (effect === "reverse") return "reverse-play";
};

const formatVideoTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const formattedTime = `${minutes} : ${seconds.toString().padStart(2, "0")}`;
  return formattedTime;
};



/* ********* DATE ********* */

/**
 *
 * @param date
 * @returns i giorni in una data con due numeri
 */
const day2Digit = (date: any) => {
  return String(date.getDate()).padStart(2, "0");
};

/**
 *
 * @param date
 * @returns i mesi in una data con due numeri
 */
const month2Digit = (date: any) => {
  return String(date.getMonth() + 1).padStart(2, "0");
};

/**
 *
 * @param date
 * @returns data in formato "YYYY-MM-GG | HH:MM"
 */
const dateFormatConverter = (date: any) => {
  const dateToConvert = new Date(date);
  const day = day2Digit(dateToConvert);
  const month = month2Digit(dateToConvert);
  const fullYear = dateToConvert.getFullYear().toString();
  const hh = String(dateToConvert.getHours()).padStart(2, "0");
  const mm = String(dateToConvert.getMinutes()).padStart(2, "0");

  return `${fullYear}-${month}-${day} | ${hh}:${mm}`;
};

function arraySortForData(array: IUserUploadVideos[]) {
  array.sort(function (a, b) {
    const dataA = new Date(a.creation_date);
    const dataB = new Date(b.creation_date);

    if (dataA < dataB) {
      return 1;
    }
    if (dataA > dataB) {
      return -1;
    }
    return 0;
  });

  return array;
}

export {
  generateName,
  bgColorVideoEffect,
  svgEffectClass,
  formatVideoTime,
  checkResponseErrors,

  // DATE
  day2Digit,
  month2Digit,
  dateFormatConverter,
  arraySortForData,
};
