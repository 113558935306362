const SvgPlaySign = () => {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>logo/klack</title>
      <defs>
        <linearGradient
          x1="29.4910428%"
          y1="74.3391769%"
          x2="72.0647056%"
          y2="25.4167132%"
          id="linearGradient-1"
        >
          <stop stopColor="#D64228" offset="0%"></stop>
          <stop stopColor="#F29232" offset="46%"></stop>
          <stop stopColor="#F4A029" offset="52%"></stop>
          <stop stopColor="#F8BE17" offset="64%"></stop>
          <stop stopColor="#FCD30A" offset="77%"></stop>
          <stop stopColor="#FEE002" offset="89%"></stop>
          <stop stopColor="#FFE500" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="34.8893925%"
          y1="91.7120387%"
          x2="58.4994426%"
          y2="13.9443436%"
          id="linearGradient-2"
        >
          <stop stopColor="#B60F5A" offset="0%"></stop>
          <stop stopColor="#C01150" offset="6%"></stop>
          <stop stopColor="#CB1448" offset="9%"></stop>
          <stop stopColor="#D0103B" offset="15%"></stop>
          <stop stopColor="#DE081B" offset="25%"></stop>
          <stop stopColor="#E20613" offset="28%"></stop>
          <stop stopColor="#E42317" offset="39%"></stop>
          <stop stopColor="#E8461E" offset="56%"></stop>
          <stop stopColor="#EA6022" offset="71%"></stop>
          <stop stopColor="#EC6F25" offset="85%"></stop>
          <stop stopColor="#ED7526" offset="96%"></stop>
        </linearGradient>
        <linearGradient
          x1="-5.61828079%"
          y1="29.3663488%"
          x2="102.356068%"
          y2="66.7723764%"
          id="linearGradient-3"
        >
          <stop stopColor="#E73F5C" offset="0%"></stop>
          <stop stopColor="#DB3B5A" offset="26%"></stop>
          <stop stopColor="#BD3155" offset="71%"></stop>
          <stop stopColor="#BD3155" offset="72%"></stop>
          <stop stopColor="#820D41" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="-0.00414733656%"
          y1="49.9969925%"
          x2="99.9703292%"
          y2="49.9969925%"
          id="linearGradient-4"
        >
          <stop stopColor="#CB3982" offset="0%"></stop>
          <stop stopColor="#9C2D6E" offset="38%"></stop>
          <stop stopColor="#662057" offset="80%"></stop>
          <stop stopColor="#43194B" offset="96%"></stop>
        </linearGradient>
        <linearGradient
          x1="16.7925341%"
          y1="90.6644354%"
          x2="71.7837406%"
          y2="13.2221717%"
          id="linearGradient-5"
        >
          <stop stopColor="#7B2683" offset="0%"></stop>
          <stop stopColor="#782582" offset="27%"></stop>
          <stop stopColor="#70257F" offset="43%"></stop>
          <stop stopColor="#62247A" offset="55%"></stop>
          <stop stopColor="#5F2479" offset="57%"></stop>
          <stop stopColor="#0F1E49" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="46.3467082%"
          y1="96.9166128%"
          x2="54.8393469%"
          y2="-1.81929697%"
          id="linearGradient-6"
        >
          <stop stopColor="#41378C" offset="0%"></stop>
          <stop stopColor="#3D3B8F" offset="16%"></stop>
          <stop stopColor="#334798" offset="33%"></stop>
          <stop stopColor="#2758A4" offset="48%"></stop>
          <stop stopColor="#0091D7" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="1.00271491%"
          y1="39.2608651%"
          x2="90.5147633%"
          y2="72.1515606%"
          id="linearGradient-7"
        >
          <stop stopColor="#3DA1A2" offset="0%"></stop>
          <stop stopColor="#42A3A4" offset="2%"></stop>
          <stop stopColor="#69B4B3" offset="17%"></stop>
          <stop stopColor="#78BBBA" offset="26%"></stop>
          <stop stopColor="#5FB7C2" offset="35%"></stop>
          <stop stopColor="#20ADD7" offset="55%"></stop>
          <stop stopColor="#00A8E2" offset="65%"></stop>
        </linearGradient>
        <linearGradient
          x1="4.18221867%"
          y1="57.2676014%"
          x2="90.9225202%"
          y2="30.9509207%"
          id="linearGradient-8"
        >
          <stop stopColor="#E4CA00" offset="0%"></stop>
          <stop stopColor="#E7E000" offset="16%"></stop>
          <stop stopColor="#E0DE16" offset="21%"></stop>
          <stop stopColor="#CCDA64" offset="39%"></stop>
          <stop stopColor="#C4D983" offset="48%"></stop>
          <stop stopColor="#ABD190" offset="59%"></stop>
          <stop stopColor="#8CC9A1" offset="76%"></stop>
          <stop stopColor="#7AC3AB" offset="90%"></stop>
          <stop stopColor="#74C2AF" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-109.000000, -633.000000)"
          fillRule="nonzero"
        >
          <g id="logo/klack" transform="translate(109.000000, 633.000000)">
            <path
              d="M7.80981532,4.10713411 C7.80059538,3.80696508 7.70224935,1.39089871 7.19515263,0.658133145 C7.04456027,0.437420626 6.52209698,-0.033432748 5.95046068,0.00188125506 C5.6800091,0.016595423 5.40033757,0.116651765 5.09607954,0.299107447 C4.38921745,0.725818317 4.20174533,1.44386971 4.59820276,2.22077778 C5.52941674,4.0365061 5.48024372,6.04940428 4.47527023,7.22948054 C3.943587,7.85336126 2.72962819,8.91572419 1.56176908,8.93043836 C1.43576323,8.93043836 1.32512394,9.0040092 1.27595093,9.11289404 C1.22677791,9.22177888 1.25443773,9.34832073 1.34356382,9.43072007 L3.19062521,11.1964202 C3.24901816,11.2523341 3.33199762,11.2847052 3.41497709,11.2847052 C3.45185685,11.2847052 3.4856633,11.2788196 3.52254306,11.2670482 C4.93319393,10.7844235 6.159446,9.57197608 6.63888289,8.87746735 C7.49941066,7.62676308 7.93889448,5.84340592 7.81288863,4.10713411 L7.80981532,4.10713411 Z"
              id="Path"
              fill="url(#linearGradient-1)"
            ></path>
            <path
              d="M4.97622032,14.5277078 C4.92397399,12.9532919 4.51829661,11.8055868 3.66084216,10.810909 C2.98778652,9.94865878 2.08115905,8.93043836 1.5679157,8.93043836 L1.55562245,8.93043836 C0.90408,8.95103819 0.387763341,9.28063555 0.175704713,9.81623127 C-0.020987348,10.3047416 0.0896519361,10.846223 0.446156296,11.1611062 C1.48493624,12.0792703 2.0012529,13.2093184 1.98281302,14.5218222 C1.95822651,16.240437 1.5433292,17.4175704 0.747341014,18.0179085 C0.64592167,18.0944221 0.605968595,18.220964 0.64592167,18.3357345 C0.685874745,18.450505 0.796514029,18.5329044 0.925593194,18.53879 L3.13223225,18.6594462 C3.13223225,18.6594462 3.1445255,18.6594462 3.15067213,18.6594462 C3.25209147,18.6594462 3.3504375,18.6123609 3.40883046,18.5299615 C4.20789196,17.4293418 5.03461327,16.2904652 4.97622032,14.5218222 L4.97622032,14.5277078 Z"
              id="Path"
              fill="url(#linearGradient-2)"
            ></path>
            <path
              d="M6.95850749,19.6629524 C5.74454868,18.609418 4.2416984,18.2180212 3.20599177,18.079708 C1.80148753,17.8413385 0.956326328,17.8295671 0.698167999,18.0473368 C0.0496988613,18.5947039 -0.0978201843,19.2568414 0.0558454882,19.6747238 C0.332443698,20.4280892 0.98398615,20.8606857 2.4591766,20.8047719 C3.00929971,20.784172 4.02656646,20.9666277 4.85021447,21.75825 C6.12256623,22.9795259 6.11949292,24.7569974 5.80294164,26.0606727 C5.7722085,26.1901573 5.83674808,26.319642 5.95660731,26.3843844 C6.00270701,26.407927 6.05495334,26.4196984 6.10719967,26.4196984 C6.18710582,26.4196984 6.27008528,26.39027 6.32847824,26.3314134 L8.48901759,24.2773155 C8.55048386,24.2184588 8.58429031,24.1360595 8.57814368,24.0507173 C8.47057771,22.4498159 8.25851908,20.7930005 6.95543418,19.6629524 L6.95850749,19.6629524 Z"
              id="Path"
              fill="url(#linearGradient-3)"
            ></path>
            <path
              d="M17.4231398,22.8000131 C16.1415681,22.0260478 14.5711049,21.6287653 13.1051344,21.7141075 C11.3379792,21.8171066 9.31573891,22.6734712 8.06490033,23.8476618 C7.68073615,24.1242882 5.9781205,25.3838209 5.80601495,26.054787 C5.59395632,26.8758376 5.97504719,27.5968318 6.77410869,27.8911152 C6.98002069,27.9676288 7.17363943,28 7.35496493,28 C8.18783287,28 8.7656158,27.3231483 8.98074774,27.0700646 C9.92118166,25.9694448 11.2058267,24.462714 12.954542,24.3597149 C14.1254745,24.2949725 15.7082309,24.6245699 16.621005,25.7457895 C16.6978378,25.8399601 16.826917,25.8811598 16.9467762,25.8517315 C17.0697087,25.8223031 17.1588348,25.7251896 17.1772747,25.6104191 L17.5645122,23.0884107 C17.5829521,22.9736402 17.5276324,22.8618126 17.4231398,22.8000131 L17.4231398,22.8000131 Z"
              id="Path"
              fill="url(#linearGradient-4)"
            ></path>
            <path
              d="M26.8366989,19.5834959 L24.3196552,17.4793699 C24.2704821,17.4381703 24.2059426,17.4116848 24.141403,17.4057991 C23.9754441,17.3910849 20.0969225,17.0850302 18.2006881,19.5687818 C17.2664008,20.7900577 17.0113158,21.952477 16.8453569,22.9589261 L16.5533921,25.5397911 C16.5441721,25.613362 16.5656853,25.6898756 16.6148583,25.7487323 C16.8914566,26.090101 17.3155738,26.2107572 17.7058846,26.2107572 C17.8779902,26.2107572 18.0439491,26.1872145 18.1883948,26.1519005 C18.9628698,25.9517878 19.7158316,25.6516188 19.6543653,24.3862004 C19.611339,23.4562649 19.660512,22.0878473 20.6163125,20.9313137 C21.8087581,19.4834396 23.8217784,20.1279202 24.906658,20.4751745 C25.0787636,20.5310884 25.2232093,20.5752309 25.3338486,20.6046592 C25.896265,20.7459152 26.504781,20.4987172 26.8858719,19.9690071 C26.974998,19.8454081 26.9534848,19.6806095 26.8366989,19.5834959 L26.8366989,19.5834959 Z"
              id="Path"
              fill="url(#linearGradient-5)"
            ></path>
            <path
              d="M27.7341064,7.8916181 C27.8416724,7.79744743 27.8662589,7.64736291 27.7955727,7.52964957 C27.7218131,7.40899339 27.5742941,7.35602239 27.4329217,7.39427923 L23.4437608,8.52138449 C23.3730746,8.54198432 23.3116084,8.58318399 23.2716553,8.64204067 C22.7368987,9.4160059 22.0945762,10.4312835 21.8702243,11.3847616 C21.3170279,13.7419713 22.5094735,16.3934643 23.8555848,17.867824 C24.2182358,18.4622763 25.376875,20.272119 26.0591506,20.4398605 C26.1083236,20.4516318 26.1574966,20.4575175 26.200523,20.4575175 C26.3050156,20.4575175 26.3879951,20.4280892 26.4433148,20.395718 C26.8950918,20.1426343 27.4759481,19.2185846 27.5466343,18.4093053 C27.5896607,17.9090236 27.4390683,17.5058554 27.1071505,17.2410004 C25.8470919,16.2345513 24.1352564,14.0568545 24.5870334,12.1822695 C25.0295906,10.3429985 27.1040771,8.43309948 27.7310331,7.8916181 L27.7341064,7.8916181 Z"
              id="Path"
              fill="url(#linearGradient-6)"
            ></path>
            <path
              d="M27.896992,6.79688401 C27.6511269,6.14357495 26.974998,5.47260889 26.0960303,5.53146556 C25.9546579,5.54029407 25.6995729,5.61975057 25.1310099,5.79632059 C24.5317138,5.98466194 23.7879719,6.21714579 23.210189,6.33485913 C21.661239,6.64679949 20.4073271,6.53791465 19.7711513,6.03469011 C18.876817,5.32841005 18.3574271,2.93294351 18.4895796,1.74403874 C18.5049461,1.61749689 18.4281133,1.49389788 18.3051807,1.44386971 C18.1822482,1.39089871 18.0347292,1.42326988 17.9456031,1.51744055 L16.0401487,3.55388139 C15.9848291,3.61273807 15.9571693,3.69219457 15.9633159,3.77165108 C16.0770285,5.12535453 16.7654507,7.07939603 17.8226705,8.03581695 C18.8307173,8.94809536 20.0907759,9.4160059 21.4891335,9.4160059 C22.1437492,9.4160059 22.8290981,9.31300672 23.5298136,9.10700837 C23.5574734,9.09817987 23.5789866,9.0922942 23.5943532,9.08935137 C23.6066464,9.08935137 23.6189397,9.0834657 23.6281596,9.08052287 C23.6558195,9.0746372 23.7111391,9.0658087 23.7910452,9.05109453 C24.5286405,8.92160986 26.7783059,8.52138449 27.7033733,7.91516077 C28.0107046,7.71504809 28.0844641,7.28539438 27.9000653,6.79394117 L27.896992,6.79688401 Z"
              id="Path"
              fill="url(#linearGradient-7)"
            ></path>
            <path
              d="M18.0808289,0.661075979 C17.6198318,0.272621945 16.9375563,0.352078452 16.7162777,0.378563954 C16.2429874,0.434477792 15.8127235,0.758189487 15.6129582,1.03481584 C13.9380023,3.37142571 13.3848059,3.60390957 12.2261667,3.67159474 C12.1954336,3.67159474 12.1677738,3.67159474 12.1370407,3.67159474 C10.7786361,3.67159474 8.64575658,2.36203379 7.17671275,0.631647643 L7.17671275,0.631647643 C7.17671275,0.631647643 7.19207931,0.646361811 7.19515263,0.655190311 C7.70224935,1.38795587 7.80059538,3.80402225 7.80981532,4.10419128 C7.82518189,4.33078946 7.83132851,4.56033048 7.8282552,4.78692867 C8.95616123,5.67566441 10.483598,6.55851449 12.1124541,6.56440015 C12.1708471,6.56440015 12.2292401,6.56440015 12.2845597,6.55851449 C13.9011226,6.46434381 15.1181547,5.28426754 16.1938144,4.24250445 L16.4919258,3.9570496 L16.7408642,3.74516558 C17.8165239,2.82405866 18.5203127,2.17957811 18.4926529,1.6881249 C18.4957262,1.23787136 18.3574271,0.890616998 18.0808289,0.655190311 L18.0808289,0.661075979 Z"
              id="Path"
              fill="url(#linearGradient-8)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgPlaySign;
