const mockResolution: string[] = ["720p", "900p", "1080p", "2k", "4k"]

const effectMock = [
    {
        id: "effect-none",
        effect: "no_effect",
    },
    {
        id: "slow-mo",
        effect: "slow_mo",
        color: "#e25d00",
        label: "Slow motion",
        svg: "slow-motion",
    },
    {
        id: "effect-reverse",
        effect: "reverse",
        color: "#e2bc00",
        label: "Reverse play",
        svg: "reverse-play",
    },
];

export {
    mockResolution, effectMock
}