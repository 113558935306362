import { stringify } from 'qs'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

//create navigator object
export const navigator = {
    push: (url: string) => history.push(url),
    replace: (url: string) => history.replace(url),

    go: (n: number) => history.go(n),
    createHref: (url: string) => history.createHref(url),
}

export const replaceMatchParams = (page: string, params: Record<string, any>) => {
    Object.keys(params).forEach((key) => {
        if (page.includes(`:${key}`)) {
            page = page.replace(`:${key}`, params[key]) as string
            delete params[key]
        }
    })
    return page
}

export function reloadApp() {
    window.location.reload()
}

export function navigate(_path: string, routeParams?: any, options?: any): boolean {
    // add query params to path
    const route = routeParams ? replaceMatchParams(_path as string, routeParams) : _path
    const stringParams = routeParams ? stringify(routeParams, { arrayFormat: 'repeat' }) : ''
    const path = `${route}${stringParams ? `?${stringParams}` : ''}`
    const props = options ? options.props : undefined

    if (options?.replace) history.replace(path, props)
    else {
        history.push(path, options?.state)
    }

    return true
}

export function navigateBack(): void {
    //   history.
}

export function refresh() {
    history.go(0)
}
