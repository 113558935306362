const SvgShare = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>share</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-560.000000, -70.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="share" transform="translate(560.000000, 70.000000)">
            <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
            <polygon
              id="Path-6"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="6.40405024 14.0415072 14.8191003 6.09488667 14.1029647 16.9117782 11.6789076 12.4946468"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgShare;
