import {TitlePage} from "../../components";

const LicenceAgreementPage = () => {
    return (
        <main id="privacy-page" className="h_full_hidden">
            <TitlePage title="Licence Agreement" classes="uppercase"/>
            <div className="privacy-text">
                <div className="scroll-container">
                    <b>End User License Agreement (EULA) for Holly App</b><br/><br/>

                    This End User License Agreement (the "Agreement") is a legal agreement between you and play
                        TRACKS GmbH ("Company") for the use of Holly App (the "App"). By downloading and using the App,
                        you agree to be bound by the terms of this Agreement.
                    <br/><br/>
                    <p>1. Grant of License<br/>
                        Subject to your compliance with the terms of this Agreement, Company grants you a limited,
                        non-exclusive, non-transferable, revocable license to use the App on a single mobile device that
                        you own or control.
                    </p><br/><br/>
                    <p>
                        2. Restrictions on Use<br/>
                        You may not: (a) use the App for any commercial purpose; (b) copy, modify, or distribute the App
                        or any part of it; (c) reverse engineer, decompile, or disassemble the App or attempt to
                        discover its source code; (d) remove or alter any trademark, copyright, or other proprietary
                        notices from the App; (e) use the App in a way that violates any applicable laws, regulations,
                        or third-party rights; or (f) sublicense, sell, or transfer the App or any part of it to any
                        third party.
                    </p><br/><br/>
                    <p>
                        3. Ownership and Intellectual Property<br/>
                        The App and all rights, including intellectual property rights, in and to the App are and shall
                        remain the property of Company or its licensors. You acknowledge and agree that the App is
                        protected by copyright, trademark, and other laws.
                    </p><br/><br/>
                    <p>
                        4. Disclaimer of Warranties<br/>
                        The App is provided "as is" without warranty of any kind, whether express or implied, including
                        but not limited to the implied warranties of merchantability, fitness for a particular purpose,
                        and non-infringement. Company does not warrant that the App will meet your requirements or that
                        the operation of the App will be uninterrupted or error-free.
                    </p><br/><br/>
                    <p>
                        5. Limitation of Liability<br/>
                        Company shall not be liable for any indirect, incidental, special, or consequential damages
                        arising
                        out of or in connection with the use of the App, whether or not Company has been advised of the
                        possibility of such damages. In no event shall Company's total liability to you exceed the
                        amount
                        paid by you for the App.
                    </p><br/><br/>
                    <p>
                        6. Termination<br/>
                        This Agreement will terminate automatically if you fail to comply with any of its terms. Upon
                        termination, you must immediately cease all use of the App and delete the App from your device.
                    </p><br/><br/> <p>
                    7. Governing Law and Jurisdiction<br/>
                    This Agreement shall be governed by and construed in accordance with the laws of Austria, without
                    giving
                    effect to any choice of law or conflict of law provisions. Any disputes arising under or in
                    connection
                    with this Agreement shall be resolved exclusively in the courts of Austria.
                </p><br/><br/><p>
                    8. Entire Agreement<br/>
                    This Agreement constitutes the entire agreement between you and Company regarding the use of the App
                    and
                    supersedes all prior agreements and understandings, whether written or oral, with respect to the
                    App.
                </p><br/><br/><p>
                    By downloading and using the App, you agree to be bound by the terms of this Agreement. If you do
                    not
                    agree to the terms of this Agreement, do not download or use the App.
                </p>
                    <br/>            <br/>            <br/>
                </div>
            </div>
        </main>
    )
        ;
};

export default LicenceAgreementPage;
