import { FormEvent, useContext, useEffect, useState } from "react";
import Div from "../UI/Div";
import GlobalContext from "../../context/GlobalContext";
import { postUserLogin } from "../../utils/apyService";
import Cookies from "js-cookie";

const LoginForm = (reset: any) => {
  const { router, userJwt, setUserJwt } = useContext(GlobalContext);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [userMail, setUserMail] = useState<{ mail: string; error: boolean }>({
    mail: "",
    error: false,
  });
  const [userPassword, setUserPassword] = useState<{
    psw: string;
    error: boolean;
  }>({
    psw: "",
    error: false,
  });

  const onChangeUserMail = (e: any) => {
    const value = e.target.value;

    setUserMail({ mail: value, error: false });

    if (value.includes("@") && userPassword.psw.trim().length > 7) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const onChangeUserPassword = (e: any) => {
    const value = e.target.value;
    setUserPassword({ psw: value, error: false });

    if (value.trim().length > 3 && userMail.mail.includes("@")) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const cookiesSetter = (token: string) => {
    Cookies.set("token", token, { expires: 48 });
  };

  const onSubmitLoginForm = (e: FormEvent) => {
    e.preventDefault();

    if (userPassword.psw.trim().length < 4) return;
    if (!userMail.mail.includes("@")) return;

    postUserLogin(
      setUserJwt,
      setUserMail,
      setUserPassword,
      userMail.mail,
      userPassword.psw,
      cookiesSetter
    );
  };

  useEffect(() => {
    console.log(reset)
    const jwtExist = Cookies.get("token");
    if (!jwtExist) return;
    router.navigate("/");
  }, [userJwt]);

  return (
    <form onSubmit={onSubmitLoginForm}>
      <div className="login-form">
        {reset?.reset && reset.reset === 'reset-success' ? (
            <div className={"banner"}>
              Password reset successfully, please login below!
            </div>
        ) : null }
        <div className={`input-control ${userMail.error ? "error" : ""}`}>
          <input
            className="email"
            name="email"
            type="email"
            placeholder="Your email address"
            value={userMail.mail}
            onChange={onChangeUserMail}
            required
          />
          {userMail.error && (
            <span className="error-message">Please enter a correct e-mail</span>
          )}
        </div>

        <div className={`input-control ${userPassword.error ? "error" : ""}`}>
          <input
            className="password"
            name="password"
            type="password"
            placeholder="Your password"
            value={userPassword.psw}
            onChange={onChangeUserPassword}
            required
          />
          {userPassword.error && (
            <span className="error-message">
              Please enter your correct password
            </span>
          )}
        </div>

        {}

        <div className="option-container">
          <button className={`default-big ${canSubmit ? "" : "not-active"}`}>
            Sign in
          </button>
          <Div
            className="forgot-password"
            onClick={() => router.navigate("/recovery-password")}
          >
            Forgot your password?
          </Div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
