import {useContext, useEffect, useRef, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {Svg, TitlePage, VideoGridUpload} from "../../components";
import {Button, Panel, Popover, RadioTile, RadioTileGroup, Stack, Whisper} from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import {Icon} from '@rsuite/icons';
import {IoPhoneLandscapeOutline, IoPhonePortraitOutline, IoSquareOutline} from "react-icons/io5";
import {BsCameraVideoFill} from "react-icons/bs";
import {Container, Header, Content, Footer, Sidebar} from 'rsuite';


const UploadPageV2 = () => {
    const {router, setPageSelected} = useContext(GlobalContext);
    const [isAutocreate, setIsAutocreate] = useState<boolean>(false);

    const [videoToUpload, setVideoToUpload] = useState<{ key:string,    id: string; src: any; name: string; file: any; selected: boolean }[] | []>([]);
    const [autocreateVideo, setAutocreateVideo] = useState<string | null>(null);
    const whisperRef = useRef<any>();
    const uploadRef = useRef<any>();

    enum Category {
        keynote = "Presentation",
        dance = "Dance",
        skate = "skateboard",
        bike = "Bike",
        disabled = "disabled"
    }

    let categoryID = new Map<string, string>([
        [Category.keynote, "06"],
        [Category.skate, "01"],
        [Category.bike, "04"],
        [Category.dance, "05"],
        [Category.disabled, "00"],]);

    enum NavFlow {
        category = "category",
        // output = "output",
        multicam = "multicam",
        uploadVideos = "uploadVideos",
    }

    enum AspectRatio {
        landscape = "16:9",
        portrait = "9:16",
        square = "1:1",
        undefined = ""
    }

    const [category, setCategory] = useState<Category>(Category.disabled);
    const [outputAspectRatio, setOutputAspectRatio] = useState<AspectRatio>(AspectRatio.undefined);
    const [isMulticam, setIsMulticam] = useState<string>("none");
    const [navFlow, setNavFlow] = useState<NavFlow>(NavFlow.category);


    useEffect(() => {
        if (videoToUpload.length === 0) return;
        whisperRef?.current?.open();
    }, [whisperRef, uploadRef]);

    const toSelectedCategory = (category: Category): { id: string, label: string } => {
        return {
            id: categoryID.get(category)?.toString() || "00",
            label: category.toString(),
        }
    }
    const onStartUploadVideos = () => {
        if (category === Category.disabled) return;
        if (!outputAspectRatio) return;
        if (videoToUpload.length === 0) return;

        if ((category === Category.skate || category === Category.bike) && isMulticam === "singlecam") {

            setPageSelected("/uploading");
            // faccio gestire alla pagina uploading gli upload
            router.navigate("/uploading", {
                state: {
                    videos: videoToUpload,
                    category: category.toString(),
                    output: outputAspectRatio.toString(),
                    autocreate: false,
                    masterAudio: null
                },
            });
        } else {
            if (!autocreateVideo && !isMulticam) return;
            setPageSelected("/uploading");
            // faccio gestire alla pagina sending-video gli upload ed il processamento
            router.navigate("/uploading", {
                state: {
                    videos: videoToUpload,
                    category: category.toString(),
                    output: outputAspectRatio.toString(), // prendo solo l'aspect ratio es 16:9
                    autocreate: true,
                    masterAudio: autocreateVideo,
                },
            })
        }
    }

    return (
        <>
            <main id="upload-main-v2" className="h_full-nav scroll-container">
                <Container className="h_full_hidden">
                    <Header><TitlePage title="video upload" classes="uppercase"/></Header>
                    <Content>
                        <div className="show-grid">
                            {navFlow === NavFlow.category ? (
                                <Panel header={<h3>Choose Category</h3>} className={"category-tiles"}>
                                    <Stack direction="row" spacing={10} justifyContent={'space-between'} alignItems={"stretch"}
                                           className={"select-category"} wrap>
                                        <RadioTileGroup disabled={navFlow !== NavFlow.category} value={category} inline
                                                        aria-label="Category" className={"select-options"}>
                                            <RadioTile
                                                className={(category === Category.disabled || category === Category.keynote) ? Category.keynote : "default"}
                                                value={Category.keynote}
                                                onChange={(v, e) => {
                                                    setCategory(Category.keynote);
                                                    setIsAutocreate(true);
                                                }}

                                            > <Stack direction="column" spacing={0} justifyContent={'center'}
                                                     alignItems="center"> <Icon className={"keynote-icon"}/>
                                                <span className={"category-text-keynote"}>Keynote</span>
                                            </Stack>
                                            </RadioTile>
                                            <RadioTile
                                                className={(category === Category.disabled || category === Category.dance) ? Category.dance : "default"}
                                                value={Category.dance}

                                                onChange=
                                                    {(v, e) => {
                                                        setCategory(Category.dance);
                                                        setIsAutocreate(true);
                                                    }}
                                            >
                                                <Stack direction="column" spacing={10} justifyContent={'center'}
                                                       alignItems="center">
                                                    <Icon className={"dance-icon"}/>
                                                    <span className={"category-text-dance"}>Dance</span>
                                                </Stack>
                                            </RadioTile>
                                        </RadioTileGroup>
                                        <RadioTileGroup disabled={navFlow !== NavFlow.category} value={category} inline
                                                        aria-label="Category" className={"select-options"}>

                                            <RadioTile
                                                className={(category === Category.disabled || category === Category.skate) ? Category.skate : "default"}
                                                value={Category.skate}
                                                onChange=
                                                    {(v, e) => {
                                                        setCategory(Category.skate);
                                                    }}
                                            >
                                                <Stack direction="column" spacing={10} justifyContent={'center'}
                                                       alignItems="center">
                                                    <Icon className={"skate-icon"}/>
                                                    <span className={"category-text-skate"}>Skate</span>
                                                </Stack>
                                            </RadioTile>
                                                <RadioTile
                                            className={(category === Category.disabled || category === Category.bike) ? Category.bike : "default"}
                                            value={Category.bike}
                                            onChange=
                                                {(v, e) => {
                                                    setCategory(Category.bike);
                                                }}
                                        >
                                            <Stack direction="column" spacing={10} justifyContent={'center'}
                                                   alignItems="center">
                                                <Icon className={"bike-icon"}/>
                                                <span className={"category-text-bike"}>Bike</span>
                                            </Stack>
                                        </RadioTile>
                                        </RadioTileGroup>
                                    </Stack>

                                </Panel>
                            ) : null}
                            {navFlow === NavFlow.category ? (
                                <Panel header={<h3>Output Aspect Ratio</h3>} className={"h3-aspect-ratio"}>
                                    <RadioTileGroup inline aria-label="Output Aspect Ratio" className={"select-options aspect-ratio"}
                                                    disabled={category === Category.disabled}
                                    >
                                        <Stack direction={"row"} spacing={10} alignItems="center"
                                               justifyContent={'space-around'} className={"select-options"}>
                                            <RadioTile
                                                className={outputAspectRatio === AspectRatio.landscape ? category : "default"}
                                                icon={<Icon as={IoPhoneLandscapeOutline}/>}
                                                value="landscape"
                                                onChange={(v, e) => setOutputAspectRatio(AspectRatio.landscape)}

                                            >
                                                16:9
                                            </RadioTile>
                                            <RadioTile
                                                className={outputAspectRatio === AspectRatio.portrait ? category : "default"}
                                                icon={<Icon as={IoPhonePortraitOutline}/>}
                                                value="portrait"
                                                onChange={(v, e) => setOutputAspectRatio(AspectRatio.portrait)}

                                            > 9:16
                                            </RadioTile>
                                            <RadioTile
                                                className={outputAspectRatio === AspectRatio.square ? category : "default"}
                                                icon={<Icon as={IoSquareOutline}/>}
                                                value="square"
                                                onChange={(v, e) => setOutputAspectRatio(AspectRatio.square)}
                                            > 1:1
                                            </RadioTile>
                                        </Stack>
                                    </RadioTileGroup>
                                </Panel>) : null}
                            {navFlow === NavFlow.multicam ? (
                                <Panel header={<h3>How many cameras did you use?</h3>}>
                                    <RadioTileGroup inline aria-label="Multicam mode" className={"select-multicam multi-cam"}>
                                        <RadioTile icon={<Icon as={BsCameraVideoFill}/>} label="Single Camera"
                                                   value="singlecam"
                                                   className={isMulticam === "singlecam" ? category : "default"}
                                                   onChange={(v, e) => {
                                                       setIsMulticam("singlecam");
                                                       setIsAutocreate(false);
                                                   }}
                                        >
                                            One Camera or multiple subsequent shots.
                                        </RadioTile>
                                        <RadioTile
                                            icon={<Icon as={BsCameraVideoFill}/>}
                                            label="Multiple Cameras"
                                            value="multicam"
                                            className={isMulticam === "multicam" ? category : "default"}
                                            onChange={(v, e) => {
                                                setIsMulticam("multicam")
                                                setIsAutocreate(true);
                                            }}

                                        > Simultaneous recording of 2 or more cameras.
                                        </RadioTile>
                                    </RadioTileGroup>
                                </Panel>
                            ) : null}


                            {navFlow === NavFlow.uploadVideos ? (
                                <Panel header={<h3>Upload from your device</h3>}>
                                    <VideoGridUpload
                                        videoList={videoToUpload}
                                        setVideoList={setVideoToUpload}
                                        category={category}
                                        setAutocreateVideo={setAutocreateVideo}
                                        autocreate={isAutocreate}
                                        maxVideo={6}
                                    />
                                </Panel>) : null}
                            {(category === Category.disabled || outputAspectRatio === AspectRatio.undefined) ? (
                                    <Panel className={"notice-panel"}>
                                        <Stack direction="row" spacing={20} alignItems="center">
                                            <Icon className={"notice-icon"}/>
                                            <p><b>Please Notice:</b> Record as steady as possible, preferable on a tripod,
                                                and
                                                upload
                                                either portrait or landscape videos only.
                                            </p>
                                        </Stack>
                                    </Panel> ) : null}
                        </div>
                    </Content>
                    <Footer className={"fixed-bottom"}>
                        <div className="action-container">
                            <Panel>

                                <Stack justifyContent={"space-between"} alignItems={"center"}>
                                    {
                                        (navFlow === NavFlow.multicam || navFlow === NavFlow.uploadVideos) ? (
                                            <Button
                                                className={"go-back"}
                                                onClick={(e) => setNavFlow(category !== Category.skate ? NavFlow.category
                                                : (navFlow === NavFlow.multicam ? NavFlow.category : NavFlow.multicam))}> <Svg
                                                className="arrow-left"/> Back</Button>
                                        ) : null}
                                    {navFlow === NavFlow.uploadVideos && isAutocreate ? (
                                        <Panel className={"back-button-visible"}> <Stack justifyContent={'center'}
                                                                                         alignItems={"stretch"}>
                                            <Whisper
                                                ref={whisperRef}
                                                trigger="hover"
                                                placement="right"
                                                controlId={`control-id-right`}
                                                speaker={(
                                                    <Popover visible arrow className={"popover"}><b>Create
                                                        Video</b></Popover>)
                                                }>

                                                <Button ref={uploadRef}
                                                        className={'oval ' + (videoToUpload.length > 0 ? category : 'disabled')}
                                                        onClick={onStartUploadVideos}
                                                /></Whisper>
                                        </Stack>
                                        </Panel>
                                    ) : (
                                        <>
                                            <Button
                                                className={
                                                navFlow === NavFlow.multicam ?
                                                    (
                                                        "default-big " + (isMulticam !== "none" ? category : "disabled")
                                                    ) : ( navFlow === NavFlow.category ?
                                                        "default-big " + (outputAspectRatio ? category : "hidden")
                                                     : (navFlow === NavFlow.uploadVideos ?
                                                        "default-big " + (videoToUpload.length > 0 ? category : "disabled")
                                                            : "default-big disabled")
                                                    )
                                            }
                                                active={
                                                    navFlow === NavFlow.multicam ?
                                                        isMulticam !== "none"
                                                        : (navFlow === NavFlow.category ?
                                                            outputAspectRatio !== AspectRatio.undefined : (navFlow === NavFlow.uploadVideos ? videoToUpload.length > 0 : false))
                                                }
                                                onClick={(e) => {
                                                    if (navFlow === NavFlow.multicam &&isMulticam !== "none")
                                                        setNavFlow(NavFlow.uploadVideos);
                                                    else if (navFlow === NavFlow.category && outputAspectRatio) {
                                                        setNavFlow((category === Category.skate || category === Category.bike )? NavFlow.multicam : NavFlow.uploadVideos);
                                                    }
                                                    else if(navFlow === NavFlow.uploadVideos && videoToUpload.length > 0)
                                                        onStartUploadVideos();
                                                }}
                                            >Next</Button>

                                        </>)}
                                </Stack>
                            </Panel></div>


                    </Footer>
                </Container>

            </main>
        </>);
};
export default UploadPageV2;
