import { useContext, useEffect, useState} from "react";
import { Routes, Route, useParams } from 'react-router-dom';

import { TitlePage, VideoProcessed } from "../../components";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import AxiosResponse from "axios";

import { checkResponseErrors } from "../../utils/FunctionCommon";

const VideoDownloadPage = () => {
  const [loaded, setLoaded] = useState(false);
    const { router, setPageSelected, userJwt } = useContext(GlobalContext);
  const [videoProcessed, setVideoProcessed] = useState<{
    path: string;
    url: string;
  } | null>(null);

    const { finalJobId, autocreated } = useParams<{ finalJobId: string, autocreated: string }>();

  /* ********** API ********** */
  const getFinalVideo = (finalJobId: string) => {
      console.log(userJwt);
    return axios.get(
        process.env.REACT_APP_API_URL +
          `media/${autocreated ? 'autoCreateVideos' : 'postProcessedVideos'}/list?id=${finalJobId}`,
        {
          headers: { Authorization: `Bearer ${userJwt}` },
        }
      );
  };

  useEffect(() => {

    if(!finalJobId) {
        setPageSelected("/");
        router.navigate("/");
        return;
    }

    const fetchData = async () => {
      await getFinalVideo(finalJobId).then((response) => {
        console.log(response);
        const responseEntries = Object.entries(response.data);
        const [key, value] = responseEntries[0];
        setVideoProcessed({ path: String(key), url: String(value) });
      }).finally(() => {
            setLoaded(true);
        }).catch((error) => {
          console.log(error);
          if (error?.response?.status === 401) {
              localStorage.removeItem("token");
          }

      });
      }
    fetchData();
  }, [userJwt]);

  if( !loaded )
    return <p>Still loading</p>;
  return (
    <main id="video-ready-page" className="h_full-nav btn-inlne">
      <TitlePage title="Your video is ready" classes="uppercase" />
      <VideoProcessed video={videoProcessed} />

      <div className="feedback">
        <div className="feedback-text">
          How was this workflow for you? We would love to hear your ideas.
        </div>
        <button className="default-big default-color bottom h-48">
          <a href="mailto:support@play-tracks.com">Give feedback</a>
        </button>
      </div>
    </main>
  );
};

export default VideoDownloadPage;
