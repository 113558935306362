const Div = ({
  onClick,
  style,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  let _style = style ?? {};
  if (!!onClick) {
    _style.cursor = "pointer";
  }

  return <div {...props} style={_style} onClick={onClick} />;
};

export default Div;
