import { useContext, useEffect } from "react";
import Svg from "../Svg/Svg";
import GlobalContext from "../../context/GlobalContext";
import Cookies from "js-cookie";

const Header = () => {
  const { handlerOpenNav, navIsOpen, userJwt, router, setPageSelected } =
    useContext(GlobalContext);

  const goTo = () => {
    const jwtExist = Cookies.get("token");

    if (!jwtExist) {
      router.navigate("/login");
      setPageSelected("/login");
      return;
    }
    router.navigate("/");
    setPageSelected("/");
  };

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const menuElement: HTMLElement = document.querySelector(
      ".menu"
    ) as HTMLElement;
    if (windowWidth >= 1920) {
      if (navIsOpen) {
        menuElement.classList.add("invisible");
      } else {
        menuElement.classList.remove("invisible");
      }
    } else {
      menuElement.classList.remove("invisible");
    }

    return () => {};
  }, [navIsOpen]);

  return (
    <header id="header">
      <Svg className="play-logo" onClick={goTo} />

      <Svg className="menu" onClick={handlerOpenNav} />
    </header>
  );
};

export default Header;
