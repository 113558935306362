import React from "react";

const SvgSpeaker = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
      <g id="color">
        <circle
          cx="59.3832"
          cy="36.38"
          r="5"
          fill="#3F3F3F"
          stroke="#3F3F3F"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          fill="#d0cfce"
          stroke="none"
          d="M57.7385,11.3752l-16.435,13.346h-28.712c-1.6548,0.005-2.995,1.3452-3,3v16.692 c0.005,1.6548,1.3452,2.995,3,3h28.712l16.436,13.128L57.7385,11.3752z"
        />
        <line
          x1="18.7508"
          x2="18.7508"
          y1="25.3332"
          y2="46.5832"
          fill="#d0cfce"
          stroke="none"
        />
        <polygon
          fill="#9b9b9a"
          stroke="none"
          points="18.6869,24.5114 11.1519,24.9482 9.623,26.8593 9.5684,45.0418 11.6979,47.4989 18.5231,47.4443"
        />
        <path
          fill="#9b9b9a"
          stroke="none"
          d="M27.7502,47.7464v12.2934c0,1.5088-1.1175,2.7319-2.4962,2.7319h-4.9924 c-1.3785,0-2.4962-1.2231-2.4962-2.7319V47.7464"
        />
      </g>
      <g id="hair" />
      <g id="skin" />
      <g id="skin-shadow" />
      <g id="line">
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M57.7385,11.3752 l-16.435,13.346h-28.712c-1.6548,0.005-2.995,1.3452-3,3v16.692c0.005,1.6548,1.3452,2.995,3,3h28.712l16.436,13.128 L57.7385,11.3752z"
        />
        <line
          x1="18.7508"
          x2="18.7508"
          y1="25.3332"
          y2="46.5832"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M26.7508,51.4447v9 c0,1.1046-0.8954,2-2,2h-4c-1.1045,0-2-0.8954-2-2v-9"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M61.6145,31.9073 c1.6486,0.8173,2.782,2.5175,2.782,4.4823c0,1.9659-1.1346,3.6667-2.7846,4.4836"
        />
      </g>
    </svg>
  );
};

export default SvgSpeaker;
