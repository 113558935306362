import { useContext, useEffect, useState } from "react";
import {
  MainInfo,
  Svg,
  TitlePage,
  VideoGridAddEffects,
} from "../../components";
import GlobalContext from "../../context/GlobalContext";
import { effectMock } from "../../utils/mock";

const AddEffectPage = () => {
  const { router, setPageSelected, navIsOpen } = useContext(GlobalContext);
  const [effectVideos, setEffectVideos] = useState<any[]>([]);
  const [videoSelected, setVideoSelected] = useState<string>("");
  const [batchId, setBatchId] = useState<string>();
  const [jobId, setJobId] = useState<string>();
  const [configData, setConfigData] = useState<{
    jobId: string;
    batchId: string;
    videoConfiguration: {
      type: string;
      format: string;
      aspect_ratio: string;
      autocreate: boolean;
      masterAudio: string;
    };
  }>();

  /* ********** Methods ********** */
  const onSelectVideo = (id: string) => {
    setVideoSelected(id);
  };

  const addEffectToVideoSelected = (effect: string) => {
    if (videoSelected === "") return;

    const copyVideo = effectVideos;
    const videoSelectedIndex = copyVideo.findIndex(
      (vid) => vid.id === videoSelected
    );

    if (copyVideo[videoSelectedIndex].effect === effect) {
      copyVideo[videoSelectedIndex].effect = "no_effect";
    } else {
      copyVideo[videoSelectedIndex].effect = effect;
    }

    /*   console.log(copyVideo[videoSelectedIndex].effect); */
    setEffectVideos([...copyVideo]);
  };

  const goBack = () => {
    setPageSelected("/manual-edit");
    router.navigate(-1);
  };

  const goNext = () => {
    setPageSelected("/sending-video");

    // creazione di array di key dei video per il db (key = path)
    let videoPathKey = [];

    let videoEffect = [];

    for (let i = 0; i <= effectVideos.length - 1; i++) {
      const videoPath = effectVideos[i].path;

      videoPathKey.push(videoPath);

      const videoWithEffect = {
        id: effectVideos[i].path,
        effect: effectVideos[i].effect,
      };

      videoEffect.push(videoWithEffect);
    }

    // faccio gestire alla pagina SendingVideoPage
    router.navigate("/sending-video", {
      state: {
        videoPathKey: videoPathKey,
        config: configData,
        videoEffect,
      },
    });
  };

  useEffect(() => {
    const routerState = router.state.location.state;
    if (!routerState) {
      setPageSelected("/");
      router.navigate("/");
    }

    const { videos, config } = routerState;

    setBatchId(config.batchId);
    setJobId(config.newJobId);
    setConfigData({ ...config });
    setEffectVideos(videos);

    const firstLoad = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
    };
    firstLoad();
  }, []);

  return (
    <main id="add-effect-page" className="h_full-nav">
      <TitlePage title="Add effects" classes="uppercase" />
      <MainInfo text="Select scene and apply effect" />

      {/* griglia dei video */}
      <VideoGridAddEffects
        videoList={effectVideos}
        videoSelected={videoSelected}
        onSelectVideo={onSelectVideo}
      />

      <div className="action-effect-container">
        <div className="effects-container">
          {effectMock.map((effect) => {
            if (effect.effect !== "no_effect") {
              return (
                <div
                  className={`effect`}
                  style={{ color: effect.color }}
                  onClick={() =>
                    addEffectToVideoSelected(String(effect.effect))
                  }
                >
                  <Svg className={effect.svg} />
                  <span className="effect-text">{effect.label}</span>
                </div>
              );
            }

            return null;
          })}
        </div>

        <div className="action-container">
          <div className="go-back" onClick={goBack}>
            <Svg className="arrow-left" /> Back
          </div>
          <button
            className={`default w-96  ${navIsOpen ? "btn-translate" : ""}`}
            onClick={goNext}
          >
            <Svg className="play-sign" />
            Start edit
          </button>
        </div>
      </div>
    </main>
  );
};

export default AddEffectPage;
