const SvgPauseControl = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="17px"
      height="22px"
      viewBox="0 0 17 22"
      version="1.1"
    >
      <title>icons/video player/close blank copy</title>
      <g
        id="layout-mvp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="video-player/player-bottom-copy"
          transform="translate(-163.500000, -47.500000)"
          fill="#FFFFFF"
        >
          <g
            id="icons/video-player/close-blank-copy"
            transform="translate(163.500000, 47.500000)"
          >
            <path
              d="M2,0 C3.1045695,-2.02906125e-16 4,0.8954305 4,2 L4,20 C4,21.1045695 3.1045695,22 2,22 C0.8954305,22 1.3527075e-16,21.1045695 0,20 L0,2 C-1.3527075e-16,0.8954305 0.8954305,1.09108455e-15 2,0 Z M15,0 C16.1045695,-2.02906125e-16 17,0.8954305 17,2 L17,20 C17,21.1045695 16.1045695,22 15,22 C13.8954305,22 13,21.1045695 13,20 L13,2 C13,0.8954305 13.8954305,1.09108455e-15 15,0 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgPauseControl;
