import { Router } from '@remix-run/router'
import { createContext } from 'react'
import { TPageSelected } from '../utils/types'

interface GlobalContextType {
    userJwt?: string | null
    setUserJwt: (userJwt: string | null) => void
    pageSelected?: TPageSelected
    setPageSelected: (page: TPageSelected) => void
    navIsOpen?: boolean
    handlerOpenNav: () => void
    videoToView?: string
    setVideoToView: (videoToView: string) => void
    videoFullIsOpen?: boolean
    handleOpenFullVideo: () => void
    notify?: boolean
    setNotify: (notify: boolean) => void
    userOn?: boolean
    router: Router
}

const GlobalContext = createContext<GlobalContextType>({
    setUserJwt: () => { },
    setPageSelected: () => { },
    setVideoToView: () => { },
    setNotify: () => { },
    handlerOpenNav: () => { },
    handleOpenFullVideo: () => { },
    router: {} as Router,
})


export default GlobalContext
