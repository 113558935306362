import {TitlePage} from "../../components";

const TermsAndConditionsPage = () => {
    return (
        <main id="privacy-page" className="h_full_hidden">
            <TitlePage title="Terms & Conditions" classes="uppercase"/>
            <div className="privacy-text">
                <div className="scroll-container">
                    <b>End User License Agreement (EULA) for the Holly App</b><br/><br/>

                    This End User License Agreement (the "Agreement") is a legal agreement between you and play
                    TRACKS GmbH ("Company") for the use of Holly App (the "App"). By downloading and using the App,
                    you agree to be bound by the terms of this Agreement.
                    <br/><br/>
                    <p>1. Grant of License<br/>
                        Subject to your compliance with the terms of this Agreement, Company grants you a limited,
                        non-exclusive, non-transferable, revocable license to use the App on a single mobile device that
                        you own or control.
                    </p><br/><br/>
                    <p>
                        2. Restrictions on Use<br/>
                        You may not: (a) use the App for any commercial purpose; (b) copy, modify, or distribute the App
                        or any part of it; (c) reverse engineer, decompile, or disassemble the App or attempt to
                        discover its source code; (d) remove or alter any trademark, copyright, or other proprietary
                        notices from the App; (e) use the App in a way that violates any applicable laws, regulations,
                        or third-party rights; or (f) sublicense, sell, or transfer the App or any part of it to any
                        third party.
                    </p><br/><br/>
                    <p>
                        3. Ownership and Intellectual Property<br/>
                        The App and all rights, including intellectual property rights, in and to the App are and shall
                        remain the property of Company or its licensors. You acknowledge and agree that the App is
                        protected by copyright, trademark, and other laws.
                    </p><br/><br/>
                    <p>
                        4. Disclaimer of Warranties<br/>
                        The App is provided "as is" without warranty of any kind, whether express or implied, including
                        but not limited to the implied warranties of merchantability, fitness for a particular purpose,
                        and non-infringement. Company does not warrant that the App will meet your requirements or that
                        the operation of the App will be uninterrupted or error-free.
                    </p><br/><br/>
                    <p>
                        5. Limitation of Liability<br/>
                        Company shall not be liable for any indirect, incidental, special, or consequential damages
                        arising
                        out of or in connection with the use of the App, whether or not Company has been advised of the
                        possibility of such damages. In no event shall Company's total liability to you exceed the
                        amount
                        paid by you for the App.
                    </p><br/><br/>
                    <p>
                        6. Termination<br/>
                        This Agreement will terminate automatically if you fail to comply with any of its terms. Upon
                        termination, you must immediately cease all use of the App and delete the App from your device.
                    </p><br/><br/> <p>
                    7. Governing Law and Jurisdiction<br/>
                    This Agreement shall be governed by and construed in accordance with the laws of Austria, without
                    giving
                    effect to any choice of law or conflict of law provisions. Any disputes arising under or in
                    connection
                    with this Agreement shall be resolved exclusively in the courts of Austria.
                </p><br/><br/><p>
                    8. Entire Agreement<br/>
                    This Agreement constitutes the entire agreement between you and Company regarding the use of the App
                    and
                    supersedes all prior agreements and understandings, whether written or oral, with respect to the
                    App.
                </p><br/><br/><p>
                    By downloading and using the App, you agree to be bound by the terms of this Agreement. If you do
                    not
                    agree to the terms of this Agreement, do not download or use the App.
                </p>
                    <br/>            <br/>            <br/>
                    Description:
                    <p>
                        The legal term for this arrangement is a "data processing agreement" or "data processing addendum" (DPA). This type of agreement is used when one party (in this case, the user) provides personal data to another party (play TRACKS GmbH) for processing purposes (in this case, to train the company's AI models).
                    </p><br />
                    <p>
                        The DPA would outline the specific details of how the data (i.e. videos) will be processed, including the purpose, scope, and duration of the processing, as well as the security measures that will be in place to protect the data. It would also include provisions regarding the user's rights, such as the right to access, rectify, and delete their data.
                    </p><br />
                    Overall, a well-drafted DPA can help ensure that both parties are clear on their respective rights and obligations with respect to the processing of personal data, and can help to promote transparency and trust between the parties.

                    <br /><br />
                    <b>DPA</b><br /><br />
                    <p>
                        Data Processing Addendum
                    </p><br /><p>
                    This Data Processing Addendum ("DPA") forms part of the Terms of Use agreement between the user ("User") and play TRACKS GmbH ("Company"). Capitalized terms used but not defined in this DPA shall have the meaning given to them in the Terms of Use.
                </p><br /><p>
                    1. Definitions
                </p><br /><p>
                    "Personal Data" means any information relating to an identified or identifiable natural person.
                </p><br /><p>
                    "Processing" means any operation or set of operations which is performed on Personal Data, such as collection, recording, organization, structuring, storage, retrieval, use, disclosure by transmission, dissemination, or otherwise making available, alignment or combination, restriction, erasure, or destruction.
                </p><br /><p>
                    "Controller" means the natural or legal person, public authority, agency, or other body which, alone or jointly with others, determines the purposes and means of the Processing of Personal Data.
                </p><br /><p>
                    "Processor" means a natural or legal person, public authority, agency, or other body which processes Personal Data on behalf of the Controller.
                </p><br /><p>
                    2. Purpose and Scope
                </p><br /><p>
                    The purpose of this DPA is to set out the terms and conditions governing the Processing of Personal Data by Company as Processor on behalf of User as Controller. Company will Process Personal Data solely for the purpose of training its AI video models.
                </p><br /><p>
                    3. Processing of Personal Data
                </p><br /><p>
                    3.1 The Personal Data to be Processed under this DPA consists of the videos uploaded by User to the App.
                </p><br /><p>
                    3.2 Company will Process Personal Data only in accordance with User's documented instructions, including those set forth in this DPA and the Terms of Use, and only to the extent necessary to provide the services to User.
                </p><br /><p>
                    3.3 Company will implement appropriate technical and organizational measures to ensure the security of the Personal Data, including protection against unauthorized or unlawful Processing, accidental loss, destruction, or damage.
                </p><br /><p>
                    4. Subprocessors
                </p><br /><p>
                    4.1 User acknowledges and agrees that Company may engage Subprocessors to Process Personal Data on its behalf.
                </p><br /><p>
                    4.2 Company shall ensure that any Subprocessor it engages is bound by obligations of confidentiality and data protection at least as strict as those set out in this DPA.
                </p><br /><p>
                    5. User Rights
                </p><br /><p>
                    5.1 User has the right to access, rectify, and delete Personal Data.
                </p><br /><p>
                    5.2 User may request the deletion of Personal Data by deleting their account or contacting Company's customer support team.
                </p><br /><p>
                    6. Data Transfers
                </p><br /><p>
                    6.1 Company will not transfer Personal Data to any third country outside the European Economic Area without User's prior written consent.
                </p><br /><p>
                    6.2 In the event that Personal Data is transferred to a third country, Company will ensure that appropriate safeguards are in place to protect the Personal Data, such as the use of standard contractual clauses or binding corporate rules.
                </p><br /><p>
                    7. Term and Termination
                </p><br /><p>
                    7.1 This DPA will remain in effect for the duration of the User's use of the App.
                </p><br /><p>
                    7.2 Company will delete or return all Personal Data to User upon termination of the Terms of Use, unless otherwise required by applicable law.
                </p><br /><p>
                    8. Governing Law and Jurisdiction
                </p><br /><p>
                    This DPA shall be governed by and construed in accordance with the laws of Austria, without giving effect to any choice of law or conflict of law provisions. Any disputes arising under or in connection with this DPA shall be resolved exclusively in the courts of Austria.
                </p><br /><p>
                    9. Entire Agreement
                </p><br /><p>
                    This DPA constitutes the entire agreement between the parties with respect to the Processing of Personal Data and supersedes all prior agreements and understandings, whether written or oral, with respect thereto.
                </p><br /><p>
                    By agreeing to the Terms of Use, User acknowledges and agrees to be bound by the terms of this DPA.
                </p>
                    <br/>            <br/>            <br/>
                </div>
            </div>
        </main>
    )
        ;
};

export default TermsAndConditionsPage;
