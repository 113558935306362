import React, { FormEvent, useContext, useRef, useState } from "react";
import MainInfo from "../UI/MainInfo";
import GlobalContext from "../../context/GlobalContext";
import { postVerifyOtp } from "../../utils/apyService";
import { TPageSelected } from "../../utils/types";

interface Props {
  userRegisteredMail: string;
}

const OtpForm = ({ userRegisteredMail }: Props) => {
  const { router, setPageSelected } = useContext(GlobalContext);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [otpError, setOtpError] = useState<boolean>(false);
  const [otpValues, setOTPValues] = useState<string[]>(Array(8).fill(""));

  
  // per eliminare il valore al click dell'input
  const onClick = (index: number) => {
    const inputValue = otpValues[index];
    if (inputValue !== "") {
      const newOTPValues = [...otpValues];
      newOTPValues[index] = "";
      setOTPValues(newOTPValues);
    }
  };

  // per cambiare il valore all'input
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setOtpError(false);
    const inputValue = e.target.value.substr(0, 1);
    const newOTPValues = [...otpValues];
    newOTPValues[index] = inputValue;
    setOTPValues(newOTPValues);

    // cambio focus
    if (inputValue !== "") {
      const nextIndex = index + 1;
      if (nextIndex < otpValues.length) {
        const nextInput = e.currentTarget
          .nextElementSibling as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else {
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        const prevInput = e.currentTarget
          .previousElementSibling as HTMLInputElement;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  // per quando si copia in un input
  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const otpArray = pastedData.split("").slice(0, 8);
    const newOTPValues = Array(8)
      .fill("")
      .map((_, index) => otpArray[index] || "");
    setOTPValues(newOTPValues);
  };

  const onSetOtpError = () => {
    setOtpError(true);
  };

  const goToLogin = () => {
    setPageSelected("/login");
    router.navigate("/login");
  };

  const verifyAndGoToLogin = () => {
    const otpValue = otpValues.join("");
    if (otpValue.length !== 8) {
      onSetOtpError();
      return;
    }

    postVerifyOtp(
      goToLogin,
      onSetOtpError,
      userRegisteredMail,
      otpValues.join("")
    );
  };

  const onSubmitOtp = (e: FormEvent) => {
    e.preventDefault();
    const actualPage: TPageSelected = router.state.location
    .pathname as TPageSelected;
    if (actualPage === '/recovery-password') {
      // ATTESA API
    } else {
      verifyAndGoToLogin();
    }
  };

  return (
    <form onSubmit={onSubmitOtp} className="otp-form">
      <MainInfo
        text="Please enter the 8-digit code we sent to your email address. If you can’t find our message, please check your spam folder."
        classes="m-normal text-normal"
      />

      <div className="input-control-otp">
        <div className={`input-container ${otpError ? "error" : ""}`}>
          {otpValues.map((otp, index) => (
            <input
              key={index}
              className={`otp-${index}`}
              type="text"
              value={otp}
              maxLength={1}
              onClick={() => onClick(index)}
              onChange={(event) => onChange(event, index)}
              onPaste={onPaste}
              ref={(ref) => (inputRefs.current[index] = ref)}
              autoFocus={index === 0}
            />
          ))}
        </div>
        {otpError && (
          <span className="error-message">
            Something went wrong. Please enter the correct code.
          </span>
        )}
      </div>

      <button className="default-big m-t-24">Verify</button>
      <div className="request-new-code">Request a new code</div>
    </form>
  );
};

export default OtpForm;
