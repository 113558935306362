import { useContext, useRef } from "react";
import Svg from "../Svg/Svg";
import GlobalContext from "../../context/GlobalContext";

interface Props {
  video: any;
}

const VideoProcessed = ({ video }: Props) => {
  const { setVideoToView, handleOpenFullVideo } = useContext(GlobalContext);
  const videoRef = useRef<HTMLVideoElement>(null);

  const openFullVideo = () => {
    setVideoToView(video.url + "#t=0.001");
    handleOpenFullVideo();
  };

  const handleDownload = () => {
    const videoUrl = video.url;

    fetch(videoUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "final_videos_1.mp4");
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div className="video-container">
      {video && (
        <>
          {/*  <div className="info-action-container">
            <div className="info">
              <span>{video.category}</span>
              {" | "}
              {dateFormatConverter(video.date)}
            </div>
            <Svg className="trash" />
          </div> */}
          <div className="content-video">
            <video
              ref={videoRef}
              key={`${video.id}`}
              id={video.id}
              preload="metadata"
              muted
              src={video.url + "#t=0.001"}
              playsInline
            />
            <Svg className="play" onClick={openFullVideo} />
          </div>

          <div className="double-button">
            <button className="download h-56" onClick={handleDownload}>
              Download the video
            </button>
            {/* <button className="remix">Remix the scenes</button> */}
          </div>
        </>
      )}
    </div>
  );
};

export default VideoProcessed;
