import React from "react";

const SvgStars = () => {
  return (
    <svg
      width="82px"
      height="105px"
      xmlns="http://www.w3.org/2000/svg"
      id="emoji"
      viewBox="0 0 72 72"
    >
      <g id="color">
        <path
          fill="#FCEA2B"
          stroke="none"
          d="M25.7052,40.1067c0.1453,0.8051,0.9245,1.4619,1.9675,1.6589l11.9059,2.2495 c0.5576,0.1054,0.564,0.7031,0.0064,0.8085c-3.1597,0.597-9.0176,1.7037-11.9188,2.2519c-1.043,0.1971-1.8155,0.8529-1.9608,1.6579 l-3.1563,17.4924c-0.0868,0.4811-1.0073,0.4811-1.0942,0l-3.1563-17.4921c-0.1453-0.8051-0.9179-1.461-1.9611-1.6581 c-2.9014-0.5479-8.7594-1.6546-11.9193-2.2516c-0.5576-0.1053-0.5576-0.7019,0-0.8072c3.1601-0.597,9.0184-1.7039,11.9197-2.2523 c1.0429-0.1971,1.172-1.1419,1.3173-1.9469l2.6528-14.0921c0.0868-0.4811,2.1538-3.594,2.2406-3.1128 C23.5311,28.057,25.5109,39.0291,25.7052,40.1067z"
        />
        <path
          fill="#F1B31C"
          stroke="none"
          d="M22.6499,66.2258l1.5818-15.4034C21.4322,46.2039,20.3623,41.4122,19.0589,38l-1.3554,2.1201 c-0.1349,0.8046-0.8519,1.46-1.8203,1.6571c-2.6937,0.548-8.133,1.6544-11.0668,2.2512c-0.5177,0.1053-0.5177,0.7015,0,0.8068 c2.9338,0.5967,8.3727,1.7028,11.0664,2.2504c0.9685,0.197,2.6859,0.8525,2.8208,1.6572l2.9305,17.4831 C21.7147,66.7068,22.5694,66.7068,22.6499,66.2258z"
        />
        <path
          fill="#FCEA2B"
          stroke="none"
          d="M46.676,19.3048c0.1049,0.5815,0.6677,1.0558,1.421,1.1981l8.5987,1.6246 c0.4027,0.0761,0.4073,0.5078,0.0046,0.5839c-2.282,0.4312-6.5127,1.2304-8.6081,1.6263c-0.7533,0.1423-1.3112,0.616-1.4161,1.1974 l-2.2795,12.6334c-0.0627,0.3475-0.7275,0.3475-0.7902,0l-2.2795-12.6332c-0.1049-0.5815-0.6629-1.0552-1.4163-1.1975 c-2.0955-0.3957-6.3263-1.195-8.6084-1.6262c-0.4027-0.0761-0.4027-0.5069,0-0.583c2.2823-0.4312,6.5133-1.2306,8.6086-1.6267 c0.7532-0.1423,0.8465-0.8247,0.9514-1.4061l1.7931-10.1776c0.0627-0.3475,1.6784-2.5957,1.741-2.2481 C45.1058,10.6022,46.5356,18.5265,46.676,19.3048z"
        />
        <path
          fill="#F1B31C"
          stroke="none"
          d="M44.7472,38.1686l0.8646-11.1247c-2.0219-3.3355-3.5168-6.7963-4.4581-9.2606l-0.2567,1.5312 c-0.0974,0.5811-0.6153,1.0544-1.3147,1.1968c-1.9454,0.3958-5.8738,1.1948-7.9927,1.6259c-0.3739,0.076-0.3739,0.5066,0,0.5827 c2.1189,0.431,6.0469,1.2298,7.9924,1.6253c0.6995,0.1423,2.2176,0.6157,2.315,1.1969l2.1164,12.6266 C44.0717,38.516,44.689,38.516,44.7472,38.1686z"
        />
        <path
          fill="#FCEA2B"
          stroke="none"
          d="M56.8824,48.9358c0.0862,0.4637,0.5487,0.8419,1.1677,0.9554l7.0663,1.2955 c0.3309,0.0607,0.3347,0.4049,0.0038,0.4656c-1.8753,0.3438-5.352,0.9812-7.074,1.2969c-0.619,0.1135-1.0775,0.4912-1.1637,0.9548 l-1.8733,10.0742c-0.0515,0.2771-0.5978,0.2771-0.6494,0l-1.8733-10.074c-0.0862-0.4637-0.5448-0.8414-1.1639-0.9549 c-1.722-0.3156-5.1988-0.9529-7.0743-1.2967c-0.3309-0.0607-0.3309-0.4042,0-0.4649c1.8755-0.3438,5.3525-0.9813,7.0745-1.2972 c0.619-0.1135,0.6956-0.6577,0.7818-1.1213l1.4736-8.1159c0.0515-0.2771,1.3793-2.0699,1.4308-1.7927 C55.592,41.9962,56.767,48.3151,56.8824,48.9358z"
        />
        <path
          fill="#F1B31C"
          stroke="none"
          d="M55.2315,63.9782l1.0538-8.8711c-1.6123-2.6598-2.8044-5.4195-3.555-7.3846l-0.2047,1.221 c-0.0777,0.4634-0.4906,0.8408-1.0484,0.9543c-1.5513,0.3156-4.6839,0.9528-6.3736,1.2965c-0.2982,0.0606-0.2982,0.404,0,0.4646 c1.6896,0.3437,4.822,0.9807,6.3733,1.2961c0.5578,0.1134,0.9709,0.491,1.0486,0.9544l2.1208,10.0688 C54.6929,64.2552,55.1851,64.2552,55.2315,63.9782z"
        />
      </g>
      <g id="hair" />
      <g id="skin" />
      <g id="skin-shadow" />
      <g id="line">
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M20,32l2.1418-11.1605l0.6996,4.1763c0.1122,0.6679,2.3298,13.9055,2.3298,13.9055c0.2615,1.5604,1.6435,2.8154,3.5205,3.1974 L40,44.4198l-11.3161,2.3026c-1.8724,0.381-3.2513,1.6356-3.5127,3.1963L22.1422,68l-3.029-18.0806 c-0.2615-1.5607-1.6405-2.8153-3.5131-3.1964L4.2836,44.4205l11.3171-2.3031C17.4727,41.7362,18.7387,39.5604,19,38"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M42.5556,13.4499l1.5469-8.0604l0.5052,3.0162c0.081,0.4824,1.6827,10.0429,1.6827,10.0429 c0.1889,1.1269,1.1869,2.0334,2.5426,2.3092L57,22.4198l-8.1728,1.663c-1.3523,0.2752-2.3482,1.1812-2.5369,2.3084l-2.1876,13.0587 l-2.1876-13.0582c-0.1889-1.1272-1.1848-2.0333-2.5372-2.3085l-8.173-1.6629l8.1735-1.6634 c1.352-0.2753,2.2663-1.8467,2.455-2.9736"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M53.8482,44.267l1.2335-6.4275l0.4029,2.4052c0.0646,0.3847,1.3418,8.0084,1.3418,8.0084 c0.1506,0.8986,0.9465,1.6215,2.0275,1.8414l6.5126,1.3253l-6.5171,1.3261c-1.0783,0.2194-1.8725,0.9419-2.023,1.8408L55.0819,65 l-1.7445-10.4129c-0.1506-0.8988-0.9448-1.6214-2.0232-1.8409l-6.5174-1.326l6.5177-1.3264 c1.0781-0.2195,1.8072-1.4726,1.9577-2.3712"
        />
      </g>
    </svg>
  );
};

export default SvgStars;
