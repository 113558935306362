const SvgSlowMotionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="64.6578947px"
      height="16.5px"
      viewBox="0 0 64.6578947 16.5"
      version="1.1"
    >
      <title>Path</title>
      <g
        id="layout-mvp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="04-scene-select-06"
          transform="translate(-37.671053, -340.250000)"
          stroke="#FFFFFF"
          strokeWidth="1.5"
        >
          <g
            id="slow-motion-active"
            transform="translate(16.000000, 318.000000)"
          >
            <path
              d="M22.4210526,23 L32.893494,23 L32.893494,38 L75.2630383,38 L75.2630383,23 L85.5789474,23"
              id="Path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgSlowMotionIcon;
