const Play = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>play</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-829.000000, -70.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="play" transform="translate(829.000000, 70.000000)">
            <circle
              id="Oval"
              fillOpacity="0.467494191"
              fill="#12161E"
              cx="12"
              cy="12"
              r="11.25"
            ></circle>
            <polygon
              id="Path-3"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="9.69589415 6.97963103 9.69589415 16.579631 17.4710166 11.779631"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Play;
