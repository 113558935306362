import { FormEvent, useContext, useState } from "react";
import TitlePage from "../../components/UI/TitlePage";
import { RegisterForm, OtpForm } from "../../components";

const RegisterPage = () => {
  const [registerConfirm, setRegisterConfirm] = useState<boolean>(false);
  const [userRegisterMail, setUserRegisterMail] = useState<string>("");

  const registerConfirmHandler = () => setRegisterConfirm((prev) => !prev);

  return (
    <main id="register-page">
      <TitlePage
        title={
          !registerConfirm
            ? "Register for free"
            : "Thanks for your registration"
        }
      />
      {!registerConfirm && (
        <RegisterForm
          registerConfirmHandler={registerConfirmHandler}
          saveOnStateEmail={setUserRegisterMail}
        />
      )}

      {registerConfirm && <OtpForm userRegisteredMail={userRegisterMail} />}
    </main>
  );
};

export default RegisterPage;
