import { useContext, useState, FormEvent, useRef, useEffect } from "react";
import { MainInfo } from "../../components";
import TitlePage from "../../components/UI/TitlePage";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";

const RecoveryPswPage = () => {
  // useContext
  const { router } = useContext(GlobalContext);
  // state Variables
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [canChangePassword, setCanChangePassword] = useState<boolean>(false);
  const [userMail, setUserMail] = useState<{ mail: string; error: boolean }>({
    mail: "",
    error: false,
  });
  const [userPassword, setUserPassword] = useState<{
    psw: string;
    error: boolean;
  }>({
    psw: "",
    error: false,
  });
  // Visibility toggle
  const [mailContentVisibility, setMailContentVisibility] =
    useState<boolean>(true);
  // OTP state variable
  const [otpError, setOtpError] = useState<boolean>(false);
  const [otpValues, setOTPValues] = useState<string[]>(Array(8).fill(""));

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  //#region OTP Methods

  // per eliminare il valore al click dell'input
  const onClick = (index: number) => {
    const inputValue = otpValues[index];
    if (inputValue !== "") {
      const newOTPValues = [...otpValues];
      newOTPValues[index] = "";
      setOTPValues(newOTPValues);
    }
  };

  // per cambiare il valore all'input
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setOtpError(false);
    const inputValue = e.target.value.substr(0, 1);
    const newOTPValues = [...otpValues];
    newOTPValues[index] = inputValue;
    setOTPValues(newOTPValues);

    // cambio focus
    if (inputValue !== "") {
      const nextIndex = index + 1;
      if (nextIndex < otpValues.length) {
        const nextInput = e.currentTarget
          .nextElementSibling as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else {
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        const prevInput = e.currentTarget
          .previousElementSibling as HTMLInputElement;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  // per quando si copia in un input
  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const otpArray = pastedData.split("").slice(0, 8);
    const newOTPValues = Array(8)
      .fill("")
      .map((_, index) => otpArray[index] || "");
    setOTPValues(newOTPValues);
  };

  // Error handler per l'OTP
  const onSetOtpError = () => {
    setOtpError(true);
  };

  //#endregion

  //#region Inputs Methods

  // onChangeUserMail handler
  const onChangeUserMail = (e: any) => {
    const value = e.target.value;
    setUserMail({ mail: value, error: false });

    if (value.includes("@")) {
      setCanSubmit(true);
      // Aggiungere opzione per mostrare OTP a schermo
    } else {
      setCanSubmit(false);
    }
  };

  // onChangeUserPassword handler
  const onChangeUserPassword = (e: any) => {
    const value = e.target.value;
    setUserPassword({ psw: value, error: false });

    if (userPassword.psw.trim().length > 4) {
      setCanChangePassword(true);
    } else {
      setCanChangePassword(false);
    }
  };

  //#endregion

  //#region Buttons handler

  const apiUrl: any = process.env.REACT_APP_API_URL;
  // Handler onClick on Send OTP btn
  const sendOtpHandler = async () => {
    if (!userMail.mail.includes("@")) {
      return;
    } else {
      axios
          .post(apiUrl + `auth/resetPassword/sendOtp`, {email: userMail.mail})
          .then((response: any) => {
            if (response.status !== 200) return;
            //jwtState(response.data.jwt);
            //setCookies(response.data.jwt)
            /*  localStorage.clear();
             localStorage.setItem("token", response.data.jwt); */
            setCanChangePassword(true);
          })
          .catch((error: any) => {
            if (error) {
              if (error.response.status === 400) {
               // emailState({ mail: userMail, error: true });
              } else {
                //pswState({ psw: userPassword, error: true });
              }
            }
          });
      // Change Visibility on mail input and send-otp btn
      setMailContentVisibility(false);
    }
  };

  //#endregion

  // Login Handler
  const onSubmitLoginForm = (e: FormEvent) => {
    e.preventDefault();
    const otpValue = otpValues.join("");
    if (otpValue.length !== 8) {
      onSetOtpError();
      return;
    }

    if (!userMail.mail.includes("@")) return;
    if (userPassword.psw.trim().length < 4) return;
    // We need to implement a condition for empty value in OTP field
    axios
        .post(apiUrl + `auth/resetPassword/resetPasswordOtp`, {email: userMail.mail, otp_code: otpValue, password: userPassword.psw})
        .then((response: any) => {
          if (response.status !== 200) return;
          //jwtState(response.data.jwt);
          //setCookies(response.data.jwt)
          /*  localStorage.clear();
           localStorage.setItem("token", response.data.jwt); */
          // Then navigate to Login page
          router.navigate("/login/reset-success");
        })
        .catch((error: any) => {
          if (error) {
            if (error.response.status === 400) {
              // emailState({ mail: userMail, error: true });
            } else {
              //pswState({ psw: userPassword, error: true });
            }
          }
        });
  };

  return (
    <main id="recovery-page">
      <TitlePage title="Reset your password" />
      <form onSubmit={onSubmitLoginForm}>
        {/* recovery-form */}
        <div className="recovery-form">
          {mailContentVisibility ? (
            <>
              <div className={`input-control ${userMail.error ? "error" : ""}`}>
                <input
                  className="email"
                  name="email"
                  type="email"
                  placeholder="Your email address"
                  value={userMail.mail}
                  onChange={onChangeUserMail}
                  required
                />
                {userMail.error && (
                  <span className="error-message">
                    Please enter a correct e-mail
                  </span>
                )}
              </div>
              <div className="option-container">
                <button
                  disabled={!userMail.mail.includes("@")}
                  onClick={sendOtpHandler}
                  className={`default-big ${canSubmit ? "" : "not-active"}`}
                >
                  Send OTP
                </button>
              </div>
            </>
          ) : (
            <>
              <MainInfo
                text="Please enter the 8-digit code we sent to your email address. If you can’t find our message, please check your spam folder."
                classes="m-normal text-normal"
              />
              <div className="input-control-otp">
                <div className={`input-container ${otpError ? "error" : ""}`}>
                  {otpValues.map((otp, index) => (
                    <input
                      key={index}
                      className={`otp-${index}`}
                      type="text"
                      value={otp}
                      maxLength={1}
                      onClick={() => onClick(index)}
                      onChange={(event) => onChange(event, index)}
                      onPaste={onPaste}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                      autoFocus={index === 0}
                    />
                  ))}
                </div>
                {otpError && (
                  <span className="error-message">
                    Something went wrong. Please enter the correct code.
                  </span>
                )}
              </div>
              <div
                className={`input-control ${userPassword.error ? "error" : ""}`}
              >
                <input
                  className="password"
                  name="password"
                  type="password"
                  placeholder="Your password"
                  value={userPassword.psw}
                  onChange={onChangeUserPassword}
                  required
                />
                {userPassword.error && (
                  <span className="error-message">
                    Please enter a valid password
                  </span>
                )}
              </div>
              <div className="option-container">
                <button
                  disabled={userPassword.psw.trim().length < 4}
                  onClick={onSubmitLoginForm}
                  className={`default-big ${
                    canChangePassword ? "" : "not-active"
                  }`}
                >
                  Change Password
                </button>
              </div>
            </>
          )}
        </div>
      </form>
      {/*  
        <Div className="go-to-login" onClick={() => router.navigate("/login")}>
          Back to sign in
        </Div>
      */}
    </main>
  );
};

export default RecoveryPswPage;
