import OnUploadVideo from "../../components/UI/OnUploadVideo";

const UploadingPage = () => {
  return (
    <main id="waiting-upload-page">
      <OnUploadVideo />
    </main>
  );
};

export default UploadingPage;
