import React, {useEffect, useRef, useState} from "react";
import {VideoGridUpload,} from "../../components";
import {v4 as uuid} from 'uuid';
import {Link, useNavigate, useParams} from "react-router-dom";

import {
    Button,
    Carousel,
    Container,
    Content,
    Footer,
    Modal,
    Nav,
    Navbar,
    Panel,
    Progress,
    RadioTile,
    RadioTileGroup,
    Stack
} from "rsuite";
import {Icon} from "@rsuite/icons";
import {IoPhoneLandscapeOutline, IoPhonePortraitOutline, IoSquareOutline} from "react-icons/io5";
import axios from "axios";


const PresentationPage = () => {
    const [videoToUpload, setVideoToUpload] = useState<{
        key: string,
        id: string;
        src: any;
        name: string;
        file: any;
        selected: boolean
    }[] | []>([]);
    const [autocreateVideo, setAutocreateVideo] = useState<string | null>(null);

    const uploadRef = useRef<any>();

    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [userMail, setUserMail] = useState<{ mail: string; error: boolean }>({
        mail: "",
        error: false,
    });

    const onChangeUserMail = (e: any) => {
        const value = e.target.value;

        setUserMail({mail: value, error: false});

        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    };

    enum API_URL {
        presign = "https://hsuc27e3zceut2pzdazxvti4ae0cjots.lambda-url.eu-west-1.on.aws/",
        cinemate = "https://jzusbkywjvix34ugk7xwdhupky0xrowy.lambda-url.eu-west-1.on.aws/",
        manifest = 'https://play-app.video/public/cinemate/'
    }

    enum Category {
        keynote = "Keynote",
        disabled = "disabled",
    }

    enum NavFlow {
        cinemate = "keynote",
        output = "output",
        uploadVideos = "uploadVideos",
        failed = "failed"
    }

    enum AspectRatio {
        landscape = "16:9",
        portrait = "9:16",
        square = "1:1",
        undefined = ""
    }

    const [category, setCategory] = useState<Category>(Category.keynote);
    const [outputAspectRatio, setOutputAspectRatio] = useState<AspectRatio>(AspectRatio.undefined);
    const [isMulticam, setIsMulticam] = useState<string>("none");
    const [navFlow, setNavFlow] = useState<NavFlow>();
    const [jobStarted, setJobStarted] = useState<boolean>(false);
    const [jobUuid, setJobUuid] = useState<string>("");
    const navigate = useNavigate();

    const [infoOpen, setInfoOpen] = React.useState(false);
    const handleInfoOpen = () => setInfoOpen(true);
    const handleInfoClose = () => setInfoOpen(false);
    const [open, setOpen] = useState(false);

    // Define the type for individual video information
    type VideoInfo = {
        filename: string,
        progress: number;
        size?: number;       // Assuming size is optional and in MB
        uploadedSize?: number;  // Assuming uploadedSize is optional and in MB
    };

// Define the type for the state
    type UploadedVideosState = {
        [key: string]: VideoInfo;
    };
    const [uploadedVideos, setUploadedVideos]
        = useState<UploadedVideosState>({});

    const uploadedCount = Object.values(uploadedVideos).filter(video => video.progress === 100).length;

    const [uploadStarted, setUploadStarted] = useState<boolean>(false);
    const [jobMetadata, setJobMetadata] = useState<{ id: string, manifest: string }>({id: "", manifest: ""});
    const [jobState, setJobState] = useState<{ status: string, detail: string, timestamp: string }>({
        status: "",
        detail: "Submitting Job",
        timestamp: ""
    });

    const videoRef = useRef<HTMLVideoElement>(null);

    let {hash, videoId} = useParams();

    const [jobFailure, setJobFailure] = useState<string>("");


    function checkJobInProgress(response: any) {
        function jobExpired(data: any) {
            if (data.status !== "SUCCESS") {
                const timestampDate = new Date(data.timestamp);

                const currentTime = new Date();
                const hoursDifference = (currentTime.getTime() - timestampDate.getTime()) / (1000 * 60 * 60);

                if (hoursDifference > 4) {
                    setJobFailure(`Failure Reason: Job timed out after 4 hours`);
                    return true;
                }
            }
            return false;
        }

        setJobState(response.data);

        if (jobExpired(response.data) || response.data.status === "FAILURE") {
            if (response.data.status === "FAILURE") {
                setJobFailure(`Failure Reason: ${response.data.detail}`);
            }
            setNavFlow(NavFlow.failed);
            console.log("Job failed miserably");
            return false;
        }
        if (response.data.status === "SUCCESS") {
            setNavFlow(NavFlow.output);
            console.log("Job succeeded");
            return false;
        }
        return true;
    }

    function jobRunner(id: any, manifest_url: any) {
        const controlJob = setInterval(() => {
            axios
                .get(manifest_url)
                .then((response) => {
                    if (!response.data?.status) {
                        clearInterval(controlJob);
                        return;
                    }
                    setJobState(response.data)
                    if (!checkJobInProgress(response)) {
                        clearInterval(controlJob);
                        return;
                    }
                })
                .catch((error) => {
                    if (error) {
                        console.log(error);
                    }
                });
        }, 5000);
    }

    useEffect(() => {
        if (videoId && hash) {
            setJobUuid(videoId);
            const manifest_url = `${API_URL.manifest}${hash}/${videoId}/manifest.json`;
            setJobMetadata({
                "id": hash,
                "manifest": manifest_url
            })
            axios
                .get(manifest_url)
                .then((response) => {

                    // if status null:
                    if (!response.data.status) {
                        console.log("Job does not exist");
                        setNavFlow(NavFlow.cinemate);
                        navigate(`/cinemate/`);
                        return;
                    }

                    if (checkJobInProgress(response)) {
                        setNavFlow(NavFlow.output);
                        console.log("Job not yet completed");
                        jobRunner(hash, manifest_url)
                    }
                })
                .catch((error) => {
                    if (error) {
                        console.log(error);
                    }
                });
        } else {
            if (uploadStarted) {
                setNavFlow(NavFlow.uploadVideos);
            }
            if (navFlow !== NavFlow.cinemate) {
                setNavFlow(NavFlow.cinemate);
            }
        }
        if (videoToUpload.length === 0) return;
    }, [videoToUpload.length]);

    const uploadVideo = async (video: any, file_id: any) => {
        return await axios
            .post(
                API_URL.presign,
                {
                    auth: 'play-prod-cinemate',
                    file_id: file_id,
                    file_name: video.name,
                    user_email: userMail.mail,
                }
            )
            .then((response) => {
                return putUploadVideo(
                    response.data.url,
                    response.data,
                    video
                );
            })
            .catch((error) => {
                if (error) {
                    console.log(error);
                }
            });
    }
    const formatSizeToMB = (bytes: any) => {
        return (bytes / 1024 / 1024).toFixed(1); // 1 MB = 1024 * 1024 bytes
    };

    const onStartUploadVideos = async () => {
        if (category === Category.disabled) return;
        if (!outputAspectRatio) return;
        if (videoToUpload.length === 0) return;

        if (!autocreateVideo && !isMulticam) return;

        const file_id = uuid();
        setJobUuid(file_id)
        setUploadStarted(true)
        await Promise.all(videoToUpload.map(async (video) => {
            await uploadVideo(video, file_id)
        })).then(
            () => {
                setJobStarted(true)
                axios.post(API_URL.cinemate, {
                    auth: "play-prod-cinemate",
                    user_email: userMail.mail,
                    file_id: file_id,
                    num_vid: videoToUpload.length,
                    aspect_ratio: outputAspectRatio,
                    audio_vid: autocreateVideo?.toLowerCase()
                }, {timeout: 60_000}).then((response) => {
                    console.log(response);
                    const manifest_url = response.data.manifest;
                    const id = response.data.id;

                    setJobMetadata({
                        "id": response.data.id,
                        "manifest": response.data.manifest
                    });
                    navigate(`${id}/${file_id}`);
                    setNavFlow(NavFlow.output)
                    jobRunner(id, manifest_url);
                }).catch((error) => {
                    if (error) {
                        console.log(error);
                    }
                });
            }
        )
    }

    const [activeIndex, setActiveIndex] = React.useState(0);

    const putUploadVideo = async (
        url: string,
        data: any,
        video: any,
    ) => {
        let extension = video.name.split(".").slice(-1)[0]
        if (extension === "mov" || extension === "MOV") {
            extension = "quicktime"
        }
        const onUploadProgress = (progressEvent: any) => {
            const {loaded, total} = progressEvent;
            let percent = Math.round((loaded * 100) / total);
            setUploadedVideos(prevVideos => ({
                ...prevVideos,
                [video.id]: {
                    ...prevVideos[video.id],
                    filename: video.name,
                    progress: percent,
                    size: total,
                    uploadedSize: loaded
                }
            }));

        };

        await axios
            .put(url, video.file, {
                headers: {
                    "Content-Type": "video/" + extension,
                },
                onUploadProgress,
            })
            .then((response) => {
                // update uploaded videos with the given video id
                console.log("Uploaded video " + video.name);
            });

    };

    const getVideoUrl = () => {
        return `${API_URL.manifest}${jobMetadata.id}/${jobUuid}/${jobUuid}.mp4`;
    };

    const [agreeTerms, setAgreeTerms] = useState<boolean>(false);

    function progress() {
        switch (jobState.status) {
            case "CREATED":
                return 0;
            case "STARTED":
                return 5;
            case "TRANSCODING":
                return 10;
            case "STARTING_CLUSTER":
                return 20;
            case "VALIDATING":
                return 30;
            case "DETECTOR":
                return 40;
            case "PRODUCE":
                return 75;
            case "PUBLISH":
                return 95;
            case "SUCCESS":
                return 100;
            case "FAILURE":
                return 0;
            default:
                return 0;
        }
    }

    function calculateTotalUploadProgress(uploadedVideos: UploadedVideosState) {
        // Get all video info objects
        const allVideos = Object.values(uploadedVideos);
        // Calculate the sum of the progress of all videos
        const totalProgress = allVideos.reduce((acc, video) => acc + video.progress, 0);
        // Calculate the average progress
        return allVideos.length > 0 ? Math.floor(totalProgress / allVideos.length) : 0;
    }

    return (
        <>
            <main id="upload-main-v2" className="h_full scroll-container">
                <div className={"presentation-page"}>
                    <Container className="h_full_hidden">
                        <Content>
                            <div className="show-grid">
                                <Panel>
                                    <Stack direction={"row"}>
                                        <Stack.Item grow={0.7}><a href={"/cinemate"}>
                                            <img src={'/cinemate-logo-single-white.svg'} height={35} /></a>
                                        </Stack.Item>
                                        <Stack.Item grow={0.3} alignSelf={'flex-end'}>
                                            {navFlow === NavFlow.cinemate ?
                                                <Button className={"watch-tutorial"} onClick={handleInfoOpen}>Quick guide</Button>
                                                : null}
                                        </Stack.Item>
                                    </Stack>
                                </Panel>
                                {navFlow === NavFlow.cinemate ?
                                    (
                                        <>
                                            <Panel header={<h3>1. Upload your videos</h3>}>
                                                <VideoGridUpload
                                                    videoList={videoToUpload}
                                                    setVideoList={setVideoToUpload}
                                                    category={category}
                                                    setAutocreateVideo={setAutocreateVideo}
                                                    autocreate={true}
                                                    maxVideo={2}
                                                />
                                            </Panel>
                                            <Panel header={<h3>2. Select Output Format</h3>}
                                                   className={"h3-aspect-ratio"}>
                                                <RadioTileGroup inline aria-label="Output Aspect Ratio"
                                                                className={"select-options aspect-ratio"}
                                                >
                                                    <Stack direction={"row"} spacing={10} alignItems="center"
                                                           justifyContent={'space-around'} className={"select-options"}>
                                                        <RadioTile
                                                            className={outputAspectRatio === AspectRatio.landscape ? category : "default"}
                                                            icon={<Icon as={IoPhoneLandscapeOutline}/>}
                                                            value="landscape"
                                                            onChange={(v, e) => setOutputAspectRatio(AspectRatio.landscape)}

                                                        >
                                                            16:9
                                                        </RadioTile>
                                                        <RadioTile
                                                            className={outputAspectRatio === AspectRatio.portrait ? category : "default"}
                                                            icon={<Icon as={IoPhonePortraitOutline}/>}
                                                            value="portrait"
                                                            onChange={(v, e) => setOutputAspectRatio(AspectRatio.portrait)}

                                                        > 9:16
                                                        </RadioTile>
                                                        <RadioTile
                                                            className={outputAspectRatio === AspectRatio.square ? category : "default"}
                                                            icon={<Icon as={IoSquareOutline}/>}
                                                            value="square"
                                                            onChange={(v, e) => setOutputAspectRatio(AspectRatio.square)}
                                                        > 1:1
                                                        </RadioTile>
                                                    </Stack>
                                                </RadioTileGroup>
                                            </Panel>
                                            <Panel header={<h3>3. Enter your Email Address</h3>} className={"h3-audio"}>
                                                <div id={"login-page"}>
                                                    <div className={`input-control ${userMail.error ? "error" : ""}`}>
                                                        <input
                                                            className="email"
                                                            name="email"
                                                            type="email"
                                                            placeholder="Your email address"
                                                            value={userMail.mail}
                                                            onChange={onChangeUserMail}
                                                            required
                                                        />
                                                        {userMail.error && (
                                                            <span
                                                                className="error-message">Please enter a correct e-mail</span>
                                                        )}
                                                    </div>
                                                    <Stack spacing={15} justifyContent={"center"} alignItems={"center"}>
                                                        <Stack.Item>
                                                            <input
                                                                className="terms"
                                                                name="terms"
                                                                type="checkbox"
                                                                value="agree"
                                                                onChange={(e) => setAgreeTerms(e.target.checked)}
                                                                required
                                                            />
                                                        </Stack.Item>
                                                        <Stack.Item><br/>
                                                            <label htmlFor="terms">I agree to the <a href={"/terms"}>terms and
                                                                conditions</a> and authorize the delivery of a link to my video
                                                                via email. Of course we respect your privacy: our <a href={'/privacy-policy'}>privacy policy</a>.

                                                        </label>

                                                        </Stack.Item>
                                                    </Stack>
                                                </div>
                                            </Panel></>
                                    ) : null}
                                {navFlow === NavFlow.uploadVideos ? (
                                    <>
                                        <Panel header={<h3>Uploading your videos</h3>} className={"cin-panel"}>
                                            <Stack direction={"column"} spacing={25} justifyContent={"center"}
                                                   alignItems={"center"}>
                                                <div style={{width: 120, marginTop: 10}}>
                                                    <Progress.Circle percent={calculateTotalUploadProgress(uploadedVideos)}
                                                                     strokeColor={"#009976"}/>
                                                </div>
                                                <Stack.Item>
                                                    <strong style={{color: '#fff'}}>Uploaded {uploadedCount}/{videoToUpload.length} videos</strong>
                                                </Stack.Item>
                                            </Stack>
                                        </Panel>
                                    </>
                                ) : null}
                                {navFlow === NavFlow.failed ? (
                                    <>
                                        <Panel className={"cin-panel"} header={<h3>Something went wrong</h3>}>
                                            <Stack direction={"column"} spacing={25} justifyContent={"center"}
                                                   alignItems={"center"}>
                                                <Stack.Item>
                                                    <div style={{width: 120, marginTop: 10, display: 'inline-block'}}>
                                                        <Progress.Circle percent={35} status="fail"/>
                                                    </div>
                                                </Stack.Item>
                                                <Stack.Item><center>
                                                    <strong style={{color: '#fff'}}>JOB FAILED</strong>
                                                    <p style={{color: '#fff'}}>{jobFailure}</p>
                                                    <p style={{color: '#fff'}}>Job ID: {jobUuid}</p>

                                                    <Button className={"primary-dl"}
                                                            href={"/cinemate"}>Try again
                                                    </Button>
                                                    <Button className={"secondary-dl"}
                                                            href={"mailto:support@play-tracks.com"} target={"_blank"}>Get support
                                                    </Button>
                                                </center>
                                                </Stack.Item>
                                            </Stack>
                                        </Panel></>
                                ) : null}
                                {(navFlow === NavFlow.output) ? (
                                    <>
                                        {jobState.status === 'SUCCESS' ? (
                                                <Panel header={<h3>Your video is ready</h3>}>
                                                    <div className="content-video">
                                                        <Stack direction={"row"} wrap alignItems={"center"}
                                                               justifyContent={"center"} className={"stack-pad"}>
                                                            <Stack.Item>
                                                                <video
                                                                    ref={videoRef}
                                                                    key={videoId}
                                                                    id={videoId}
                                                                    controls={true}
                                                                    loop={false}
                                                                    src={getVideoUrl()}
                                                                    playsInline
                                                                />
                                                                </Stack.Item>
                                                            <Stack.Item>
                                                                <Button className={"primary-dl"} href={getVideoUrl()}
                                                                        target={"_blank"}>Download your
                                                                    video</Button>
                                                            </Stack.Item>
                                                            <Button className={"secondary-dl"}
                                                                    href={"mailto:support@play-tracks.com"} target={"_blank"}>Support
                                                                & feedback</Button>
                                                        </Stack>
                                                    </div>
                                                </Panel>
                                            ) :
                                            <>
                                                <Panel header={<h3>Processing your video</h3>} className={"cin-panel"}>
                                                    <Stack direction={"column"} spacing={25} justifyContent={"center"}
                                                           alignItems={"center"}>
                                                        <div style={{width: 120, marginTop: 10}}>
                                                            <Progress.Circle percent={progress()}
                                                                             strokeColor={"#009976"}/>
                                                        </div>
                                                        <Stack.Item><center>
                                                            <strong style={{color: '#fff'}}>{jobState.detail}</strong><br/><br/>
                                                            {jobState.status !== "" ?
                                                                <p style={{color: '#fff'}}>You can close this tab. We will notify you via email when your video is ready.</p> : null}
                                                        </center>
                                                        </Stack.Item>
                                                    </Stack>
                                                </Panel>
                                            </>
                                        }
                                    </>
                                ) : null
                                }
                            </div>
                        </Content>

                        <Footer className={"fixed-bottom cinemate-footer"}>
                            <div className="action-container">
                                <Panel>

                                    <Stack justifyContent={"space-between"} alignItems={"center"}>
                                        {
                                            (navFlow === NavFlow.cinemate ?
                                                <>
                                                    <Button
                                                        className={canSubmit && agreeTerms && outputAspectRatio !== AspectRatio.undefined && videoToUpload.length > 0 ?
                                                            "default-big Keynote" : "default-big disabled"
                                                        }
                                                        active={canSubmit && agreeTerms && outputAspectRatio !== AspectRatio.undefined && videoToUpload.length > 0}
                                                        disabled={!canSubmit || !agreeTerms || outputAspectRatio === AspectRatio.undefined || videoToUpload.length === 0}
                                                        onClick={(e) => {
                                                            if (videoToUpload.length > 0) {
                                                                setNavFlow(NavFlow.uploadVideos);
                                                                onStartUploadVideos()
                                                                window.scrollTo(0, 0)
                                                            }
                                                        }}
                                                    >Create my video</Button>

                                                </> : null)}
                                    </Stack>
                                </Panel></div>
                            <div className={"backdrop"}>
                            </div>
                            <Navbar className={"cinemate-bar rs-theme-dark"}>
                                <Nav>
                                    <Navbar.Brand href="mailto:support@play-tracks.com" className={"highlight"} target={'_blank'}>Support</Navbar.Brand>
                                    <Navbar.Brand href="/imprint">Imprint</Navbar.Brand>
                                    <Navbar.Brand href="/privacy-policy">Privacy</Navbar.Brand>
                                    <Navbar.Brand href="/cookie-policy">Cookie Policy</Navbar.Brand>
                                    <Navbar.Brand href="/terms">Terms & Conditions</Navbar.Brand>
                                </Nav><br/>
                                <span className={"copyr"}>Copyright {
                                    // get current year
                                    new Date().getFullYear()
                                } play TRACKS
                        GmbH</span>
                            </Navbar>
                        </Footer>

                        <Modal className={"loc-modal"} open={infoOpen} onClose={handleInfoClose} size={"sm"}>
                            <Modal.Header>
                                <Modal.Title style={{textAlign: 'center', textTransform: 'uppercase'}}>Quick Start Guide</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Stack alignItems={"center"} justifyContent={"center"}>
                                    <Carousel
                                        placement={"left"}
                                        shape={"dot"}
                                        activeIndex={activeIndex}
                                        className="custom-slider"
                                        onClick={() => {
                                            setActiveIndex((activeIndex + 1) % 7);
                                        }}
                                    >
                                        <img src="/onboarding_assets/onboarding-01.png"/>
                                        <img src="/onboarding_assets/onboarding-02.png"/>
                                        <img src="/onboarding_assets/onboarding-03.png"/>
                                        <img src="/onboarding_assets/onboarding-04.png"/>
                                        <img src="/onboarding_assets/onboarding-05.png"/>
                                        <img src="/onboarding_assets/onboarding-06.png"/>
                                        <img src="/onboarding_assets/onboarding-07.png"/>
                                    </Carousel>
                                </Stack>
                            </Modal.Body>
                            <Modal.Footer>
                                <Stack direction={"row"} spacing={12} alignItems={"center"}
                                       justifyContent={"space-between"}>

                                    <Button onClick={() => {
                                        setActiveIndex(activeIndex === 0 ? 6 : activeIndex - 1)
                                    }} appearance="subtle">
                                        Previous
                                    </Button>
                                    <Button onClick={() => {
                                        setActiveIndex((activeIndex + 1) % 7);
                                    }} appearance="subtle">
                                        Next
                                    </Button>
                                </Stack>
                                {/*
                                <Stack direction={"row"} spacing={12} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack.Item grow={1}>
                                    <Button onClick={handleInfoClose} appearance="primary">
                                        Close Tutorial
                                    </Button>
                                    </Stack.Item>
                                </Stack>
                                */}

                            </Modal.Footer>
                        </Modal>
                    </Container>
                </div>
            </main>

        </>);
};

export default PresentationPage;