const SvgSlowMotion = () => {
  return (
    <svg
      width="80px"
      height="54px"
      viewBox="0 0 80 54"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>slow-motion</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="assets" transform="translate(-107.000000, -209.000000)">
          <g id="slow-motion" transform="translate(107.000000, 209.000000)">
            <rect
              id="Rectangle-Copy-18"
              fill="#E25D00"
              opacity="0.25"
              x="0"
              y="0"
              width="80"
              height="54"
            ></rect>
            <rect
              id="Rectangle"
              fill="#E25D00"
              opacity="0.3"
              x="13"
              y="26"
              width="54"
              height="19"
            ></rect>
            <polyline
              id="Path"
              stroke="#E25D00"
              strokeWidth="2"
              points="0 26 13.2650925 26 13.2650925 45 66.9331818 45 66.9331818 26 80 26"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgSlowMotion;
