import { TitlePage } from "../../components";

const CookiePrivacyPage = () => {
  return (
    <main id="cookie_privacy-page" className="h_full_hidden">
      <TitlePage title="Cookie Policy" classes="uppercae" />
      <div className="privacy-text">
        <div className="scroll-container">
          {/* inner-container */}
          <div className="inner-container">
            <p>
              Cookie Policy for play TRACKS GmbH and <a>https://play-ai.video</a>
            </p>
            <br />
            <p>
              At play TRACKS GmbH, we are committed to protecting the privacy of our
              users. This Cookie Policy explains how we use cookies and similar
              technologies on our website, www.play-ai.video By using our website,
              you consent to the use of cookies and similar technologies in
              accordance with this policy.
            </p>
          </div>
          {/* inner-container */}
          <div className="inner-container">
            <h4>What are cookies?</h4>
            <p>
              Cookies are small text files that are placed on your device when you
              visit a website. They are widely used to make websites work or to work
              more efficiently, as well as to provide information to the owners of
              the site.
            </p>
          </div>
          {/* inner-container */}
          <div className="inner-container">
            <h4>Types of cookies we use</h4>
            <p>We use the following types of cookies on our website:</p>
            <ol>
              <li>
                Strictly necessary cookies: These cookies are essential for the
                website to function properly and cannot be turned off in our
                systems. They are usually only set in response to actions made by
                you, such as setting your privacy preferences, logging in, or
                filling in forms. You can set your browser to block or alert you
                about these cookies, but some parts of the site may not work then.
              </li>
              <li>
                Performance cookies: These cookies allow us to count visits and
                traffic sources, so we can measure and improve the performance of
                our site. They help us to know which pages are the most and least
                popular and see how visitors move around the site. All information
                these cookies collect is aggregated and therefore anonymous. If
                you do not allow these cookies, we will not know when you have
                visited our site and will not be able to monitor its performance.
              </li>
              <li>
                Functionality cookies: These cookies enable the website to
                provide enhanced functionality and personalization. They may be
                set by us or by third-party providers whose services we have added
                to our pages. If you do not allow these cookies, some or all of
                these services may not function properly. 
              </li>
              <li>
                Targeting cookies: These cookies may be set through our site by
                our advertising partners. They may be used by those companies to
                build a profile of your interests and show you relevant
                advertisements on other sites. They do not store directly personal
                information but are based on uniquely identifying your browser and
                internet device. If you do not allow these cookies, you will
                experience less targeted advertising.
              </li>
            </ol>
          </div>
          {/* inner-container */}
          <div className="inner-container">
            <h4>Managing cookies</h4>
            <p>
              You can control the use of cookies at the individual browser level.
              You can also delete cookies from your browser's cache at any time.
              However, if you do so, some parts of our website may not function
              properly.
            </p>
          </div>
          {/* inner-container */}
          <div className="inner-container">
            <h4>Changes to our Cookie Policy</h4>
            <p>
              We may update our Cookie Policy from time to time. We will notify you
              of any changes by posting the new policy on our website. You are
              advised to review this policy periodically for any changes.
            </p>
          </div>
          {/* inner-container */}
          <div className="inner-container">
            <h4>Contact Us</h4>
            <p>
              If you have any questions about our Cookie Policy, please contact us
              at <a className="redirectTo" href="mailto:office@play-tracks.com">office@play-tracks.com</a>
            </p>          <br/>            <br/>            <br/>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CookiePrivacyPage;
