import DeleteAccountPage from "./DeleteAccountPage/DeleteAccountPage";
import ImprintPage from "./ImprintPage/ImprintPage";
import LoginPage from "./LoginPage/LoginPage";
import RecoveryPswPage from "./RecoveryPswPage/RecoveryPswPage";
import RegisterPage from "./RegisterPage/RegisterPage";
import MyUploadPage from "./MyUploadPage/MyUploadPage";
import MyVideoPage from "./MyVideoPage/MyVideoPage";
import PrivacyPage from "./PrivacyPage/PrivacyPage";
import SupportPage from "./SupportPage/SupportPage";
import TutorialPage from "./TutorialPage/TutorialPage";
import UploadPage from "./UploadPage/UploadPage";
import UploadingPage from "./UploadingPage/UploadingPage";
import SceneSelectPage from "./SceneSelectPage/SceneSelectPage";
import ManualEditPage from "./ManualEditPage/ManualEditPage";
import SendingVideoPage from "./SendingVideoPage/SendingVideoPage";
import VideoProcessedPage from "./VideoProcessedPage/VideoProcessedPage";
import ErrorPage from "./ErrorPage/ErrorPage";
import AddEffectPage from "./AddEffectPage/AddEffectPage";
import CookiePrivacyPage from "./CookiePrivacyPage/CookiePrivacyPage";
import LicenceAgreementPage from "./LicenceAgreement/LicenceAgreement";
import DataProcessingPage from "./DataProcessingPage/DataProcessingPage";
import UploadPageV2 from "./UploadV2/UploadPageV2";
import VideoDownloadPage from "./VideoDownloadPage/VideoDownloadPage";
import LakeOfCharity from "./LakeOfCharity2023/LakeOfCharity2023Page";
import PresentationPage from "./Presentation/PresentationPage";
import TermsAndConditionsPage from "./TermsAndConditions/TermsAndConditionsPage";

const pages = [
  {
    path: "/login/:reset?/",
    element: <LoginPage />,
  },
  {
    path: "/lake-of-charity-2023/video?/:videoId?/",
    element: <LakeOfCharity />,
  },
  {
    path: "/cinemate/:hash?/:videoId?/",
    element: <PresentationPage />,
  },
  {
    path: "/recovery-password",
    element: <RecoveryPswPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  // pagina con i video che abbiamo uploadato nella pagina "/"
  {
    path: "/my-uploads",
    element: <MyUploadPage />,
  },
  // pagina con i video processati dopo la pagina "/scene-select/:batchId o => /manual-edit "
  {
    path: "/my-videos",
    element: <MyVideoPage />,
  },
  // pagina dove si carica i video che si vogliono processare
  {
    path: "/",
    element: <UploadPageV2 />,
  },
  // pagina di loading dove si aspetta che i video vengono uploadati
  {
    path: "/uploading",
    element: <UploadingPage />,
  },
  // pagina dove si sceglie i video da processare
  {
    path: "/scene-select/:batchId/:optionalJobId?",
    element: <SceneSelectPage />,
  },
  // pagina dove si può spostare i video con il drag&drop dei video che si vogliono processare dopo averli selezionati da "./scene-select"
  {
    path: "/scene-select/:batchId/manual-edit",
    element: <ManualEditPage />,
  },
  // pagina dopo "manual-edit" per aggiungere gli effetti
  {
    path: "/scene-select/:batchId/manual-edit/add-effects",
    element: <AddEffectPage />,
  },
  // pagina di loading dove si aspetta che il video venga creato dopo "/scene-select o => /manual-edit o =/add-effects"
  {
    path: "/sending-video",
    element: <SendingVideoPage />,
  },

  {
    path: "/video-processed/",
    element: <VideoProcessedPage />,
  },
  {
    path: "/video-processed/:finalJobId/:autocreated?",
    element: <VideoDownloadPage />,
  },
  {
    path: "/support",
    element: <SupportPage />,
  },
  {
    path: "/imprint",
    element: <ImprintPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPage />,
  },
  {
    path: "/licence-agreement",
    element: <LicenceAgreementPage />,
  },
  {
    path: "/data-processing",
    element: <DataProcessingPage />,
  },
  {
    path: "/tutorial",
    element: <TutorialPage />,
  },
  {
    path: "/delete-account",
    element: <DeleteAccountPage />,
  },
  {
    path: "/something-went-wrong",
    element: <ErrorPage />,
  },
  {
    path: "/cookie-policy",
    element: <CookiePrivacyPage />,
  },
  {
    path: "/terms",
    element: <TermsAndConditionsPage />,
  }
 /* {
    path: "/v2",
    element: <UploadPageV2 />,
  },*/
];

export default pages;
