import { useContext, useEffect, useState} from "react";
import { TitlePage, VideoProcessed } from "../../components";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import { checkResponseErrors } from "../../utils/FunctionCommon";

const VideoProcessedPage = () => {
  const { router, setPageSelected, userJwt } = useContext(GlobalContext);
  const [videoProcessed, setVideoProcessed] = useState<{
    path: string;
    url: string;
  } | null>(null);


  /* ********** API ********** */
  const getFinalVideo = async (finalJobId: string) => {
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `media/postProcessedVideos/list?id=${finalJobId}`,
        {
          headers: { Authorization: `Bearer ${userJwt}` },
        }
      )
      .then((response) => {
        const responseEntries = Object.entries(response.data);
        const [key, value] = responseEntries[0];
        setVideoProcessed({ path: String(key), url: String(value) });
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  const getAutoCreateVideos = async (jobId: string) => {
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `media/autoCreateVideos/list?id=${jobId}`,
        {
          headers: { Authorization: `Bearer ${userJwt}` },
        }
      )
      .then((response) => {
        const responseEntries = Object.entries(response.data);
        const [key, value] = responseEntries[0];
        setVideoProcessed({ path: key, url: String(value) });
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  useEffect(() => {
    const routerState = router.state.location.state;

    if (!routerState) {
      setPageSelected("/");
      router.navigate("/");
      return;
    }


    if (routerState.finalJob) {
      const { finalJob } = routerState;
      getFinalVideo(finalJob);
    } else if (routerState.jobId) {
      const { jobId } = routerState;
      getAutoCreateVideos(jobId);
    } else {
      const { video } = routerState;
      setVideoProcessed(video);
    }

    /* getFinalVideoPresentation(); */
  }, []);

  return (
    <main id="video-ready-page" className="h_full-nav btn-inlne">
      <TitlePage title="Your video is ready" classes="uppercase" />
      <VideoProcessed video={videoProcessed} />

      <div className="feedback">
        <div className="feedback-text">
          How was this workflow for you? We would love to hear your ideas.
        </div>
        <button className="default-big default-color bottom h-48">
          <a href="mailto:support@play-tracks.com">Give feedback</a>
        </button>
      </div>
    </main>
  );
};

export default VideoProcessedPage;
