interface Props {
  title: string;
  classes?: string;
}

const TitlePage = ({ title, classes }: Props) => {
  return <h1 className={`title-page ${classes ?? ""}`}>{title}</h1>;
};

export default TitlePage;
