import Svg from "../Svg/Svg";
import Div from "../UI/Div";

import {BiSolidVideos} from "react-icons/bi";
import {Icon} from '@rsuite/icons';
import {useState} from "react";

interface Props {
  videoList:
    | { key: string, id: string; src: any; name: string; file: any; selected: boolean }[]
    | [];
  setVideoList: (videoList: any) => void;
  setAutocreateVideo: (autocreateVideo: string | null) => void;
  category: string;
  autocreate: boolean;
  maxVideo: any;
}

const VideoGridUpload = ({
  videoList, setVideoList, category, autocreate, setAutocreateVideo, maxVideo
}: Props) => {
  const emptySlots = Array.from({ length: maxVideo - videoList.length });
  const addVideoToUpload = (e: InputEvent | any) => {
    if (!e.target.files[0]) return;

    let allVideosLenght = videoList.length;

    if (e.target.files.length === 1) {
      const video = e.target.files[0];
      if (!video.type.startsWith("video/")) return;
      if (videoList.length === maxVideo) return;

      const srcUrl = URL.createObjectURL(video);

      const newArrayVideoToUpload = [
        ...videoList,
        {
          key: String(videoList.length),
          id: String(videoList.length),
          src: srcUrl,
          name: video.name,
          file: video,
          selected: videoList.length === 0 ? true : false,
        },
      ];
      setVideoList(newArrayVideoToUpload);
      setAutocreateVideo(video.name);

      e.target.value = "";
      return;
    }

    const videos = e.target.files;

    let arrayVid = [];

    for (let i = 0; i < videos.length; i++) {
      const vid = videos[i];

      const srcUrl = URL.createObjectURL(vid);

      if (vid.type.startsWith("video/")) {
        arrayVid.push({
          id: String(videoList.length),
          src: srcUrl,
          name: vid.name,
          file: vid,
          selected: allVideosLenght === 0 ? true : false,
        });
      }

      if (i === 0 && allVideosLenght === 0) {
        setAutocreateVideo(vid.name);
      }

      allVideosLenght++;
    }

    if (videoList.length + arrayVid.length <= 6) {
      setVideoList([...videoList, ...arrayVid]);
      e.target.value = "";
    } else {
      const vidNumToAdd = maxVideo - videoList.length;

      let vidToAdd = [];
      for (let i = 0; i < vidNumToAdd; i++) {
        vidToAdd.push(arrayVid[i]);
      }

      setVideoList([...videoList, ...vidToAdd]);
      e.target.value = "";
    }
  };

  const onDeleteVideo = (id: string) => {
    const copyVideoToUpload = videoList;
    const videoToRemoveIndex = copyVideoToUpload.findIndex(
        (video: any) => video.id === id
    );

    // delete video
    copyVideoToUpload.splice(videoToRemoveIndex, 1);

    setVideoList([...copyVideoToUpload]);
  };

  const onSelectVideoAudio = (id: string) => {
    const copyVideoForAudio = videoList;
    const videoSelectedForAudio = copyVideoForAudio.findIndex(
        (video: any) => video.id === id
    );

    // add select to true at selected video
    copyVideoForAudio[videoSelectedForAudio].selected = true;

    // add name of video selected
    setAutocreateVideo(copyVideoForAudio[videoSelectedForAudio].name);

    // remove other video selected
    copyVideoForAudio.forEach((video) => {
      if (video.id === copyVideoForAudio[videoSelectedForAudio].id) return;

      video.selected = false;
    });

    setVideoList([...copyVideoForAudio]);
  };


  return (
      <>
    <div
      className={`video-container ${autocreate? "autocreate" : ""
      }`}
    >
      {videoList.map((video, i) => (
        <div className={`video ${video.selected ? "selected" : "grid-item"}`}>
          <video
            key={`${video.id}-${i}`}
            id={`${i}`}
            preload="metadata"
            muted
            src={video.src + "#t=0.001"}
            playsInline
          />

          {autocreate && (
            <Div
              className="select-audio-action"
              onClick={() => onSelectVideoAudio(video.id)}
            >
              {video.selected ? (
                <>
                  <Svg className={`audio-on`} /> Audio on
                </>
              ) : (
                <>
                  <Svg className={`audio-off`} /> Audio off
                </>
              )}
            </Div>
          )}
          <Svg className="trash" onClick={() => onDeleteVideo(video.id)} />
        </div>

      ))}
      {emptySlots.map((_, index) => (
          <div key={`empty-${index}`} className={"empty"} >
            <Icon as={BiSolidVideos}/>
          </div>
      ))}
    </div>
        <button className={"add-videos " + (videoList.length < maxVideo ? category : "disabled")}
                disabled={videoList.length === maxVideo}
        >
          <label htmlFor="video">Choose up to {maxVideo} videos</label>
          {videoList.length < maxVideo && (
              <input
                  type="file"
                  id="video"
                  name="video"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={addVideoToUpload}
                  multiple={false}
              />
          )}
        </button>
      </>
  );
};

export default VideoGridUpload;
