const ArrowDown = () => {
  return (
    <svg
      width="16px"
      height="10px"
      viewBox="0 0 16 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>arrow-down</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-117.000000, -516.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="arrow-down" transform="translate(117.711111, 517.088889)">
            <path
              d="M11.1111111,-3.28888889 L3.28888889,3.91111111 L11.1111111,11.1111111"
              id="Path-2"
              transform="translate(7.200000, 3.911111) rotate(-90.000000) translate(-7.200000, -3.911111) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowDown;
