import { TitlePage } from "../../components";

const DataProcessingPage = () => {
  return (
    <main id="privacy-page" className="h_full_hidden">
      <TitlePage title="Data Processing Agreement" classes="uppercae" />
      <div className="privacy-text">
        <div className="scroll-container">
          Description:
          <p>
          The legal term for this arrangement is a "data processing agreement" or "data processing addendum" (DPA). This type of agreement is used when one party (in this case, the user) provides personal data to another party (play TRACKS GmbH) for processing purposes (in this case, to train the company's AI models).
          </p><br />
          <p>
          The DPA would outline the specific details of how the data (i.e. videos) will be processed, including the purpose, scope, and duration of the processing, as well as the security measures that will be in place to protect the data. It would also include provisions regarding the user's rights, such as the right to access, rectify, and delete their data.
        </p><br />
          Overall, a well-drafted DPA can help ensure that both parties are clear on their respective rights and obligations with respect to the processing of personal data, and can help to promote transparency and trust between the parties.

          <br /><br />
          <b>DPA</b><br /><br />
<p>
          Data Processing Addendum
        </p><br /><p>
          This Data Processing Addendum ("DPA") forms part of the Terms of Use agreement between the user ("User") and play TRACKS GmbH ("Company"). Capitalized terms used but not defined in this DPA shall have the meaning given to them in the Terms of Use.
        </p><br /><p>
          1. Definitions
        </p><br /><p>
          "Personal Data" means any information relating to an identified or identifiable natural person.
      </p><br /><p>
          "Processing" means any operation or set of operations which is performed on Personal Data, such as collection, recording, organization, structuring, storage, retrieval, use, disclosure by transmission, dissemination, or otherwise making available, alignment or combination, restriction, erasure, or destruction.
    </p><br /><p>
          "Controller" means the natural or legal person, public authority, agency, or other body which, alone or jointly with others, determines the purposes and means of the Processing of Personal Data.
</p><br /><p>
          "Processor" means a natural or legal person, public authority, agency, or other body which processes Personal Data on behalf of the Controller.
</p><br /><p>
          2. Purpose and Scope
        </p><br /><p>
          The purpose of this DPA is to set out the terms and conditions governing the Processing of Personal Data by Company as Processor on behalf of User as Controller. Company will Process Personal Data solely for the purpose of training its AI video models.
        </p><br /><p>
          3. Processing of Personal Data
        </p><br /><p>
          3.1 The Personal Data to be Processed under this DPA consists of the videos uploaded by User to the App.
        </p><br /><p>
          3.2 Company will Process Personal Data only in accordance with User's documented instructions, including those set forth in this DPA and the Terms of Use, and only to the extent necessary to provide the services to User.
        </p><br /><p>
          3.3 Company will implement appropriate technical and organizational measures to ensure the security of the Personal Data, including protection against unauthorized or unlawful Processing, accidental loss, destruction, or damage.
        </p><br /><p>
          4. Subprocessors
        </p><br /><p>
          4.1 User acknowledges and agrees that Company may engage Subprocessors to Process Personal Data on its behalf.
        </p><br /><p>
          4.2 Company shall ensure that any Subprocessor it engages is bound by obligations of confidentiality and data protection at least as strict as those set out in this DPA.
        </p><br /><p>
          5. User Rights
        </p><br /><p>
          5.1 User has the right to access, rectify, and delete Personal Data.
        </p><br /><p>
          5.2 User may request the deletion of Personal Data by deleting their account or contacting Company's customer support team.
        </p><br /><p>
          6. Data Transfers
        </p><br /><p>
          6.1 Company will not transfer Personal Data to any third country outside the European Economic Area without User's prior written consent.
        </p><br /><p>
          6.2 In the event that Personal Data is transferred to a third country, Company will ensure that appropriate safeguards are in place to protect the Personal Data, such as the use of standard contractual clauses or binding corporate rules.
        </p><br /><p>
          7. Term and Termination
        </p><br /><p>
          7.1 This DPA will remain in effect for the duration of the User's use of the App.
        </p><br /><p>
          7.2 Company will delete or return all Personal Data to User upon termination of the Terms of Use, unless otherwise required by applicable law.
        </p><br /><p>
          8. Governing Law and Jurisdiction
        </p><br /><p>
          This DPA shall be governed by and construed in accordance with the laws of Austria, without giving effect to any choice of law or conflict of law provisions. Any disputes arising under or in connection with this DPA shall be resolved exclusively in the courts of Austria.
        </p><br /><p>
          9. Entire Agreement
        </p><br /><p>
          This DPA constitutes the entire agreement between the parties with respect to the Processing of Personal Data and supersedes all prior agreements and understandings, whether written or oral, with respect thereto.
        </p><br /><p>
          By agreeing to the Terms of Use, User acknowledges and agrees to be bound by the terms of this DPA.
        </p>
            <br/>            <br/>            <br/>
        </div>
      </div>
    </main>
  );
};

export default DataProcessingPage;
