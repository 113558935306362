import { useContext, useEffect, useState } from "react";

import GlobalContext from "../../context/GlobalContext";
import { checkResponseErrors, generateName } from "../../utils/FunctionCommon";
import axios from "axios";
import Svg from "../Svg/Svg";
import { TPageSelected } from "../../utils/types";

const OnUploadVideo = () => {
  const { userJwt, router, setPageSelected, setNotify } =
    useContext(GlobalContext);
  const [message, setMessage] = useState<string>("");
  const [videosLength, setVideosLength] = useState<number>(0);
  const [videoUploaded, setVideoUploaded] = useState<number>(0);
  const [videosProgress, setVideosProgress] = useState<Array<number>>();
  const [batchId, setBatchId] = useState<string>("");
  const [jobId, setJobId] = useState<string>("");
  const [configData, setConfigData] = useState<{
    batchId: string;
    videoConfiguration: {
      type: string;
      format: string;
      aspect_ratio: string;
      autocreate: boolean;
      masterAudio: string | null;
      numVideo?: number;
    };
  }>();

  // ONLY FOR PRESENTATION
  const [isAutoCreate, setIsAutoCreate] =
    useState<boolean>(true);

  let tempVideoUploaded = 0;

  const postCreateBatch = async (
    videoToUpload: { id: string; src: any; name: string; file: any }[],
    category: string,
    output: string,
    autocreate: boolean,
    masterAudio: string | null
  ) => {
    setVideosLength(videoToUpload.length);
    const batchName = generateName(8);
    //store.batchName = batchName

    await axios
      .post(
        process.env.REACT_APP_API_URL + `batch/createBatch`,
        { batchName: batchName },
        {
          headers: {
            Authorization: `Bearer ${userJwt}`,
          },
        }
      )
      .then((response) => {
        const batchId = response.data.id;

        setBatchId(batchId);

        // video.name.split(".").slice(-1)[0] ==> formato es "mp4 "
        videoToUpload.forEach((video) =>
          getSignedUrl(
            video.name,
            video.name.split(".").slice(-1)[0],
            video,
            response.data.id,
            category,
            output,
            autocreate,
            masterAudio,
            videoToUpload.length
          )
        );
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  const getSignedUrl = async (
    fileName: string,
    extension: string,
    video: any,
    batchId: string,
    category: string,
    output: string,
    autocreate: boolean,
    masterAudio: string | null,
    allVideoLength: number
  ) => {
    const fileNameWitouhFinalTypeOnName = fileName.split(".")[0];

    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `media/getSignedUrl?fileName=${fileNameWitouhFinalTypeOnName}&extension=${extension}&batchId=${batchId}`,
        { headers: { Authorization: `Bearer ${userJwt}` } }
      )
      .then((response) => {
        putUploadVideo(
          response.data.signedUrl,
          video,
          extension,
          batchId,
          category,
          output,
          autocreate,
          masterAudio,
          allVideoLength
        );
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  const putUploadVideo = async (
    url: string,
    video: any,
    extension: string,
    batchId: string,
    category: string,
    output: string,
    autocreate: boolean,
    masterAudio: string | null,
    allVideoLength: number
  ) => {
    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      if (percent < 100) {
        if(allVideoLength === 1) {
            setMessage(`Uploading your video...  ${percent}%`);
        } else {
          setMessage(`Uploading your videos: ${tempVideoUploaded}/${allVideoLength}`);
        }
        /* console.log(`${loaded} bytes of ${total} bytes. ${percent}%`); */
      }
      if (percent === 100) {
        setVideoUploaded(tempVideoUploaded + 1);
        tempVideoUploaded += 1;

        if (tempVideoUploaded >= allVideoLength && !jobId) {
          /*           console.log("creazione job"); */
          setMessage("Firing up the engines...");
          postCreateJob(batchId, category, output, autocreate, masterAudio);
        }
      }
    };

    await axios
      .put(url, video.file, {
        headers: {
          "Content-Type": "video/" + extension,
        },
        onUploadProgress,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  const postCreateJob = async (
    batchId: string,
    category: string,
    output: string,
    autocreate: boolean,
    masterAudio: string | null
  ) => {
    const data = {
      batchId,
      videoConfiguration: {
        type: category,
        format: "mp4",
        aspect_ratio: output,
        autocreate,
        masterAudio,
        just_copy: true,
      },
    };

    setConfigData(data);

    axios
      .post(process.env.REACT_APP_API_URL + `job/createJob`, data, {
        headers: {
          Authorization: `Bearer ${userJwt}`,
        },
      })
      .then((response) => {
        const newJobId = response.data.id;
        /*   console.log(response.data.id, " jobId"); */
        setJobId(newJobId);
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          //checkResponseErrors( error);

          setTimeout(() => {
            setPageSelected("/something-went-wrong");
            router.navigate(`/something-went-wrong`);
          }, 500);
        }
      });
  };

  useEffect(() => {
    if (!router.state.location.state) {
      setPageSelected("/");
      router.navigate("/");
      return;
    }
    const { videos, category, output, autocreate, masterAudio, isMulticam } =
      router.state.location.state;

    setIsAutoCreate(autocreate);
    console.log( router.state.location.state);
    console.log("IS AUTOCREATE?! " + autocreate);

    postCreateBatch(videos, category, output, autocreate, masterAudio);

    return () => {};
  }, []);

  useEffect(() => {
    /* console.log(`video caricato num: ${videoUploaded} su ${videosLength}`); */

    if (jobId) {
      /*  console.log("polling job Status"); */
      const controlJob = setInterval(() => {
        axios
          .get(process.env.REACT_APP_API_URL + `job/status?id=${jobId}`, {
            headers: { Authorization: `Bearer ${userJwt}` },
          })
          .then((response) => {
            if (response.data.status === "in_progress") {
                setMessage("Processing your videos...");
                return;
            }
            if (response.data.status === "transcoding") {
              setMessage("Transcoding your videos...");
              return;
            }
            if (response.data.status === "detecting_scenes") {
              setMessage("Detecting scenes...");
              return;
            }
            if (response.data.status === "produce") {
              setMessage("Getting the best shots...");
              return;
            }
            if (response.data.status === "created") return;

            if (response.data.status === "completed") {
              if (isAutoCreate) {
                setNotify(true);
                setTimeout(() => {
                  setPageSelected("/video-processed");
                  router.navigate(`/video-processed`, {
                    state: { jobId: jobId },
                  });
                }, 1000);
              }

              if (!isAutoCreate) {
                setNotify(true);
                setTimeout(() => {
                  setPageSelected("/scene-select");
                  router.navigate(`/scene-select/${batchId}`, {
                    state: { newJobId: jobId, config: configData },
                  });
                }, 1000);
              }
            }
            if (response.data.status === "failed") {
              setTimeout(() => {
                setPageSelected("/something-went-wrong");
                router.navigate(`/something-went-wrong`);
              }, 1000);
            }

            clearInterval(controlJob);
            return;
          })

          .catch((error) => {
            if (error) {
              console.log(error);
              checkResponseErrors(error);
            }
          });
      }, 5000);

      return () => {
        clearInterval(controlJob);
      };
    }
  });

  return (
    <div className="container">
      <div className="icon">
        <Svg className="camera" />
      </div>
      <div className="text-primary">
        Please give us some time to upload and process your videos to get the
        best shots for you.
      </div>
      <div className="text-secondary"><strong>{message}</strong></div>
      {/* LOAD ANIMATION */}
      <div className="loader-container">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {jobId ? (
          <div className="text-secondary">
            You can close this tab, we'll notify you via e-mail.
          </div>
      ) : null}
    </div>
  );
};

export default OnUploadVideo;
