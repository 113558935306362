import React, {Component, Fragment, useEffect, useRef, useState} from "react";
import {
    Modal,
    Button,
    Container,
    Content,
    Footer,
    Header,
    Nav,
    Navbar,
    Panel,
    SelectPicker,
    Stack,
    Loader, Divider
} from 'rsuite';
import {useNavigate, useParams} from "react-router-dom";
import {EmptyVideoUploadNotice, Svg} from "../../components";
import axios from "axios";
import {Icon} from "@rsuite/icons";
import {PiVideoFill} from "react-icons/pi";
import {FaCcPaypal} from "react-icons/fa";
import {Interface} from "readline";
import Close from "../../components/Svg/SvgClose";
import {set} from "js-cookie";
import {navigate} from "../../utils";
import {BsCameraReels} from "react-icons/bs";

interface DonatePanelProps {
    full: boolean;
}

function formatDateAndTime(input: string): { date: string; time: string } | null {
    const regex = /^(\d{2})-(\d{2})-(\d{2})-(\d{2})-(\d{2})-(\d{2})_/;
    const match = input.match(regex);

    if (!match) {
        return null; // Return null if the input doesn't match the expected format.
    }

    const [, year, month, day, hour, minute, second] = match;

    // Creating a Date object with the extracted values.
    const dateObject = new Date(`20${year}-${month}-${day}T${hour}:${minute}:${second}`);

    // Formatting the date and time as required.
    const formattedDate = `${dateObject.getDate()}.${dateObject.getMonth() + 1}.${dateObject.getFullYear()}`;
    const formattedTime = `${dateObject.getHours()}:${String(dateObject.getMinutes()).padStart(2, '0')}:${String(dateObject.getSeconds()).padStart(2, '0')}`;

    return {date: formattedDate, time: formattedTime};
}

function DonatePanel(props: DonatePanelProps) {
    return (<Panel className={props.full ? "panel-loc-donate-full" : "panel-loc-donate"}>
            <Stack spacing={0} wrap direction={"column"} alignItems={"center"}
                   justifyContent={"center"}>
                <Stack.Item className={"spaced"}>
                    <a href={"https://www.lakeofcharity.at/"}><img src={"/loc125.png"} width={64}/></a>
                </Stack.Item>
                <Stack.Item className={"donate-for"}>Donate for Lake of Charity
                </Stack.Item>
                <Stack.Item className={"spaced"}>
                    <form action="https://www.paypal.com/donate" method="post" target="_blank">
                        <input type="hidden" name="hosted_button_id" value="GF2XRWCB5BCZQ"/>
                        <Button className={"donate"} type={"submit"} endIcon={<Icon as={FaCcPaypal}/>}>Donate
                            with</Button>
                    </form>
                </Stack.Item>
            </Stack>
        </Panel>
    )
}

interface Props {
    videoList: any[] | [];
    videoCallBack: any;
}


const VideoGrid = ({videoList, videoCallBack}: Props) => {
    const navigate = useNavigate();


    let lastDateTime = formatDateAndTime(videoList[0].name);
    let initial = true;

    return (
        <Panel className={"panel-loc-video"}>

            <Stack spacing={17} wrap direction={"row"} alignItems={"flex-start"}
                   justifyContent={"flex-start"}>
                {videoList.map((video, i) => {
                    let dt = formatDateAndTime(video.name);
                    let next = false;

                    if (dt?.date !== lastDateTime?.date || dt?.time !== lastDateTime?.time) {
                        lastDateTime = dt;
                        next = true;
                    }
                    if (initial) {
                        initial = false;
                        next = true;
                    }
                    return [
                        next ? <Divider
                            className={"divider"}>{lastDateTime?.date} - {lastDateTime?.time}</Divider>
                        : null,
                        <Stack.Item key={"" + i}>
                            <img src={video.thumbnail} className={"video"} onClick={() => {
                                videoCallBack("" + video.id);
                                navigate("/lake-of-charity-2023/video/" + video.id);
                            }}/>
                        </Stack.Item>
                    ];})}
            </Stack>
        </Panel>);
}

interface SelectProps {
    availableDays: any;
    onChangeDay: any;
    onChangeTime: any;
    selection: any;
    isDisabled: boolean
}

const SelectBar = ({availableDays, isDisabled, onChangeDay, selection}: SelectProps) => {
    return (
        <Stack wrap spacing={8} alignItems={"center"} justifyContent={"space-around"}
               className={"select-bar"}>
            <Stack.Item alignSelf={"flex-start"} className={"loc-info-title"}>
                Find your water slide video via day & time</Stack.Item>
            <Stack.Item>
                <SelectPicker
                    placeholder={"Day"} searchable={false}
                    data={availableDays}
                    disabled={isDisabled}
                    onChange={onChangeDay}
                    value={selection.day}
                    className={"play-select"} menuClassName={"play-sel"}/>
            </Stack.Item>

        </Stack>
    );
}

function LakeOfCharity() {
    const [videoList, setVideoList] = useState<any>([]);
    const [filteredVideos, setFilteredVideos] = useState<any>([]);
    const [selection, setSelection] = useState<any>({day: '', time: ''});
    const [selectedVideo, setSelectedVideo] = useState<any>();
    const [open, setOpen] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const [infoOpen, setInfoOpen] = React.useState(false);
    const handleInfoOpen = () => setInfoOpen(true);
    const handleInfoClose = () => setInfoOpen(false);


    const [noticeOpen, setNoticeOpen] = React.useState(false);
    const handleNoticeClose = () => setNoticeOpen(false);

    const handleOpen = (id: string) => {
        setSelectedVideo(videoList[parseInt(id)]);
        setOpen(true);
    }

    const handleClose = () => {
        setIsPlaying(false);
        setOpen(false);
        navigate("/lake-of-charity-2023/");
    }
    const data = [
        {
            label: 'Friday, July 21st 2023',
            value: '23-07-21'
        },
        {
            label: 'Saturday, July 22nd 2023',
            value: '23-07-22'
        },
        {
            label: 'Sunday, July 23rd 2023',
            value: '23-07-23'
        }];


    let {videoId} = useParams();

    useEffect(() => {

        axios.get(process.env.REACT_APP_URL + `public/events/loc2023/manifest.json`).then((response) => {
            const allMedia = response.data.media;
            let list: any[] = [];
            for (let i = 0; i < allMedia.length; i++) {
                const media = allMedia[i];
                list = [...list, {
                    key: media.vid + i,
                    id: i,
                    name: media.vid,
                    url: process.env.REACT_APP_URL + `public/events/loc2023/media/` + media.vid,
                    poster: process.env.REACT_APP_URL + `public/events/loc2023/media/` + media.thumb,
                    thumbnail: process.env.REACT_APP_URL + `public/events/loc2023/media/` + media.thumb.replace(".jpg", "-thumb.jpg")
                }];
            }
            setVideoList(list);
            setFilteredVideos(list);

            if (videoId) {
                setNoticeOpen(false)
                setOpen(true);
                setIsPlaying(false);
                setSelectedVideo(list[parseInt(videoId)]);
            } else {
             //   setNoticeOpen(true)
            }
        }).finally(() => {
            setIsLoaded(true);

        });

    }, []);
    const videoRef = useRef<HTMLVideoElement>(null);

    function filterVideos(day: string) {
        setSelection({...selection, day: day});
        console.log(day);
        let filtered = videoList;

        if (day && day.trim() !== '') {
            filtered = filtered.filter((video: any) => {
                return video.url.includes(day);
            });
        } else
            filtered = videoList;

        setFilteredVideos(filtered);
    }

    return (
        <main id="lake-of-charity" className="h_full scroll-container btn-inline">
            <Container className={"video-container"}>
                <Header>
                    <a href={"https://www.lakeofcharity.at/"} target={"_blank"}><img src={"/lake-of-charity.png"}
                                                                                     className={"loc-header-image"}/></a>
                </Header>
                <Content className={"no-pad"}>
                    <>
                        {!isLoaded ? (
                                <Loader size="md" content="Loading ..." className={"loader"}/>

                            )

                            : (
                                open ? (
                                    <Panel>
                                        <Stack direction={"row"} justifyContent={"flex-end"} className={"stack-pad"}>
                                            <Stack.Item alignSelf={"center"}>
                                                <Button onClick={handleClose} className={"close-button"}>
                                                    <Icon as={Close}/>
                                                </Button>
                                            </Stack.Item>
                                        </Stack>
                                        {selectedVideo ? (
                                            <div className="content-video">
                                                <video
                                                    ref={videoRef}
                                                    key={selectedVideo.key}
                                                    id={selectedVideo.id}
                                                    controls={true}
                                                    loop={true}
                                                    poster={selectedVideo.poster}
                                                    src={selectedVideo.url}
                                                    playsInline
                                                />
                                            </div>
                                        ) : null}
                                        <Stack direction={"row"} wrap alignItems={"center"}
                                               justifyContent={"space-between"} className={"stack-pad"}>
                                            <Stack.Item>
                                                <Button className={"primary-dl"} href={selectedVideo.url}>Download your
                                                    video</Button>
                                            </Stack.Item>
                                            <Button className={"secondary-dl"} href={"mailto:support@play-tracks.com"}>Support
                                                play with your feedback</Button>
                                        </Stack></Panel>
                                ) : (

                                    isLoaded && videoList.length > 0 ?
                                        (<>


                                                <SelectBar
                                                    availableDays={data}
                                                    isDisabled={false}
                                                    selection={selection}
                                                    onChangeDay={(day: string) => {
                                                        setSelection({...selection, day: day});
                                                        filterVideos(day)
                                                    }}
                                                    onChangeTime={(time: string) => {
                                                        setSelection({...selection, time: time});
                                                    }}
                                                />
                                                <VideoGrid videoList={filteredVideos} videoCallBack={handleOpen}/>
                                            </>
                                        )
                                        : (
                                            <>

                                                <SelectBar
                                                    availableDays={data}
                                                    isDisabled={false}
                                                    selection={selection}
                                                    onChangeDay={(day: string) => {
                                                        filterVideos(day)
                                                    }}
                                                    onChangeTime={(time: string) => {
                                                        setSelection({...selection, time: time});
                                                    }}
                                                />
                                                <Panel className={"placeholder"}>
                                                    <Icon as={PiVideoFill} className={"no-video-placeholder"}/>
                                                    <EmptyVideoUploadNotice
                                                        noticeText={"No videos uploaded yet. Check back later"}
                                                    />
                                                </Panel>
                                            </>)
                                ))}
                    </>
                </Content>
                <Footer>  {open ? (
                    <DonatePanel full={true}/>
                ) : (
                    <Stack wrap alignItems="stretch" justifyContent={"space-around"} spacing={0}>

                        <Stack.Item grow={0.5}>
                            <DonatePanel full={false}/>
                        </Stack.Item>
                        <Stack.Item grow={0.5}>
                            <Panel className={"panel-loc-play"}>
                                <Stack spacing={0} wrap direction={"column"} alignItems={"center"}
                                       justifyContent={"center"}>
                                    <span className={"spaced"}><Svg className={"play-logo"}/></span>
                                    <Stack.Item className={"spaced"}>

                                        <span className={"all-videos-automatic"}>All videos  automatically produced with "Holly" the artificial intelligence by play. Soon also available on your mobile. <br/>Want to test or know more?</span>
                                    </Stack.Item><Stack spacing={0} direction={"row"} wrap>
                                    <Button className={"secondary"} href={"https://play-ai.video/home/#cta"}>Get
                                        newsletter</Button>
                                    <Button className={"primary"} href={"https://play-ai.video/"}>Test Holly for
                                        free</Button>
                                </Stack>
                                </Stack>
                            </Panel></Stack.Item>
                    </Stack>
                )}
                    <Navbar className={"rs-theme-dark"}>
                        <Nav>
                            <Navbar.Brand href="/imprint">Imprint</Navbar.Brand>
                            <Navbar.Brand href="/privacy-policy">Privacy</Navbar.Brand>
                            <Navbar.Brand href="/cookie-policy">Cookie Policy</Navbar.Brand>
                            <Navbar.Brand href="/licence-agreement">Licence Agreement</Navbar.Brand>
                            <Navbar.Brand href="/data-processing">Data Processing</Navbar.Brand>
                        </Nav>
                        <Nav pullRight>
                            <Navbar.Brand className={"Copyright-2023-play"}>Copyright 2023 play TRACKS
                                GmbH</Navbar.Brand>
                        </Nav>
                    </Navbar>
                </Footer>
            </Container></main>
    );
}

export default LakeOfCharity;
