import { useContext, useEffect, useState } from "react";
import TitlePage from "../../components/UI/TitlePage";
import { EmptyVideoUploadNotice, VideoGridMyUploads } from "../../components";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import { IUserUploadVideos } from "../../utils/types";
import {
  arraySortForData,
  checkResponseErrors,
} from "../../utils/FunctionCommon";

const MyUploadPage = () => {
  const { router, setPageSelected, userJwt } = useContext(GlobalContext);
  const [userVideos, setUserVideos] = useState<IUserUploadVideos[] | []>([]);

  /* ******** methods ******** */
  const onDeleteVideo = (id: string | number) => {
    /*     const copyUserVideos = userVideos;
    const videoToRemoveIndex = copyUserVideos.findIndex(
      (video) => video.id === id
    );

    // delete video
    copyUserVideos.splice(videoToRemoveIndex, 1);

    setUserVideos([...copyUserVideos]); */
  };

  const goToHome = () => {
    setPageSelected("/");
    router.navigate("/");
  };

  /* ******* API ****** */
  const getBatchList = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + `batch/list`, {
        headers: {
          Authorization: `Bearer ${userJwt}`,
        },
      })
      .then((response) => {
        const allBatch = response.data.response;

        for (let i = 0; i < allBatch.length; i++) {
          const batch = allBatch[i];
          getMediaList(batch.id);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  const getMediaList = async (batchId: string) => {
    await axios
      .get(process.env.REACT_APP_API_URL + `media/list?batchId=${batchId}`, {
        headers: { Authorization: `Bearer ${userJwt}` },
      })
      .then((response) => {
        setUserVideos((prev) => {
          return [...prev, ...response.data];
        });
      })

      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  useEffect(() => {
    getBatchList();

    return () => {};
  }, []);

  return (
    <main id="my-upload-page" className="h_full-nav btn-inline">
      <TitlePage title="My uploads" classes="uppercase" />

      <EmptyVideoUploadNotice
        noticeText={`${
          userVideos.length === 0
            ? "It's pretty empty here. Start your first upload."
            : "Your uploads are ready! Please select one to continue"
        }`}
      />

      <VideoGridMyUploads
        videoList={arraySortForData(userVideos)}
        onDeleteVideo={onDeleteVideo}
      />

      <button
        className="default-big default-color h-48 bottom"
        onClick={goToHome}
      >
        {userVideos.length > 0 ? "Start another upload" : "Go to upload Page"}
      </button>
    </main>
  );
};

export default MyUploadPage;
