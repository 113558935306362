const CheckBig = () => {
  return (
    <svg
      width="24.5097476px"
      height="19.8334359px"
      viewBox="0 0 24.5097476 19.8334359"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>checkbox/default copy</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-314.054199, -387.218728)"
          stroke="#009976"
          strokeWidth="1.5"
        >
          <g
            id="checkbox/default-copy"
            transform="translate(306.000000, 378.000000)"
          >
            <g id="Icons/check-big" transform="translate(8.804206, 9.968750)">
              <path
                d="M1.77635684e-15,0 L14.7459388,18 L23.009719,10.5540393"
                id="Line-Copy"
                transform="translate(11.504859, 9.000000) scale(-1, 1) translate(-11.504859, -9.000000) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckBig;
