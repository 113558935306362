import React from "react";

const SvgCamera = () => {
  return (
    <svg
      width="82px"
      height="105px"
      xmlns="http://www.w3.org/2000/svg"
      id="emoji"
      viewBox="0 0 72 72"
    >
      <g id="color">
        <circle cx="22.2578" cy="26.1104" r="3" fill="#d0cfce" stroke="none" />
        <circle cx="46.1533" cy="26.1104" r="3" fill="#d0cfce" stroke="none" />
        <path
          fill="#3F3F3F"
          stroke="none"
          d="M46.1533,16.1104c-5.5143,0-10,4.4861-10,10c0,5.5142,4.4857,10,10,10s10-4.4858,10-10 C56.1533,20.5965,51.6676,16.1104,46.1533,16.1104z M46.1533,29.1104c-1.6545,0-3-1.3459-3-3c0-1.6542,1.3455-3,3-3 c1.6546,0,3,1.3458,3,3C49.1533,27.7645,47.8079,29.1104,46.1533,29.1104z"
        />
        <path
          fill="#3F3F3F"
          stroke="none"
          d="M22.2578,16.1104c-5.5143,0-10,4.4861-10,10c0,5.5142,4.4857,10,10,10s10-4.4858,10-10 C32.2578,20.5965,27.7721,16.1104,22.2578,16.1104z M22.2578,29.1104c-1.6545,0-3-1.3459-3-3c0-1.6542,1.3455-3,3-3 c1.6546,0,3,1.3458,3,3C25.2578,27.7645,23.9124,29.1104,22.2578,29.1104z"
        />
        <path
          fill="#3F3F3F"
          stroke="none"
          d="M20.429,44.2365l6.998,0.8154c0.3759-0.1465,2.8419-0.3707,2.8419-0.3707s2.8139,1.7829,3.188,4.5719 c0.375,2.7901-4.6428,3.8145-4.8684,3.8145c-2.5157,0-3.6289-4.5602-3.6328-4.6031l-4.5267-0.6947v3.9511l0.9667,3.3692 c0,0.4463,1.3875,1.8082,1.865,1.8082h24.8268V40.0307H20.429V44.2365z"
        />
        <polygon
          fill="#9b9b9a"
          stroke="none"
          points="51.7275,42.8271 51.7224,42.8302 51.886,42.7725"
        />
        <path
          fill="#9b9b9a"
          stroke="none"
          d="M61.1825,54.9705l-0.2379-13.609c0,0-9.2885,1.4989-9.2459,1.4826 c0.0088-0.0034,0.0155-0.0095,0.0237-0.0139l-3.4454,0.9831v9.2196l3.6286,1.0345l-0.1419-0.043 C51.7461,54.0191,61.1825,54.9705,61.1825,54.9705z"
        />
        <path
          fill="#d0cfce"
          stroke="none"
          d="M24.2184,48.4645c0.113,0.0207,2.5968,3.5392,2.6747,3.604c0.0641,0.0534,3.3897,1.0268,3.6015,0.9991 c0.2104-0.0282,2.2949-2.0265,2.3537-2.1349c0.0182-0.0336,1.1749-1.7591,0.8288-3.2001 c-0.2426-1.0102-2.0037-1.8806-2.0689-1.9302c-0.1583-0.1204-3.6895-0.7349-3.6999-0.7367c0,0-15.5371-2.5602-15.6504-2.6373 l-4.3653-3.5156l-1.3312,8.0498l4.7444-0.7342C11.4171,46.2108,24.2184,48.4645,24.2184,48.4645z"
        />
      </g>
      <g id="hair" />
      <g id="skin" />
      <g id="skin-shadow" />
      <g id="line">
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M21.3957,40.0307h26.6918v16.8676H23.2607c-1.03,0-1.865-0.8095-1.865-1.8082v-3.1238"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M7.9148,39.3892l-1.1513,7.5734l5.0161-0.7784l13.5947,2.4922c-0.0187,0.2723-0.0103,0.5506,0.0275,0.8316 c0.3018,2.2475,2.3682,3.8244,4.6154,3.5226c2.2475-0.3018,3.8245-2.3681,3.5227-4.6156 c-0.3018-2.2473-2.3682-3.8243-4.6156-3.5224c-0.4237,0.0569-0.8235,0.1768-1.1914,0.3485l-15.2674-2.7989L7.9148,39.3892z"
        />
        <circle
          cx="46.1537"
          cy="26.1104"
          r="10"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <circle
          cx="46.1537"
          cy="26.1104"
          r="3"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <circle
          cx="22.2579"
          cy="26.1104"
          r="10"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <circle
          cx="22.2579"
          cy="26.1104"
          r="3"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <polyline
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          points="52.0534,53.0676 60.9446,55.1814 60.9446,41.3615 52.0534,43.7729"
        />
      </g>
    </svg>
  );
};

export default SvgCamera;
