import axios from "axios";
import { generateName } from "./FunctionCommon";

const apiUrl: any = process.env.REACT_APP_API_URL;

/* **********  LOGIN-PAGE && REGISTER-PAGE ***********/
const postUserLogin = async (
  jwtState: any,
  emailState: any,
  pswState: any,
  userEmail: string,
  userPassword: string,
  setCookies: any
) => {
  emailState({ mail: userEmail, error: false });
  pswState({ psw: userPassword, error: false });

  await axios
    .post(apiUrl + `auth/login`, { email: userEmail, password: userPassword })
    .then((response: any) => {
      if (response.status !== 200) return;
      jwtState(response.data.jwt);
      setCookies(response.data.jwt)
      /*  localStorage.clear();
       localStorage.setItem("token", response.data.jwt); */
    })
    .catch((error: any) => {
      if (error) {
        if (error.response.status === 400) {
          emailState({ mail: userEmail, error: true });
        } else {
          pswState({ psw: userPassword, error: true });
        }
      }
    });
};

const postUserRegister = async (
  setConfirmation: any,
  emailState: any,
  pswState: any,
  userEmail: string,
  userPassword: string
) => {
  await axios
    .post(apiUrl + `auth/register`, {
      username: generateName(7),
      email: userEmail,
      password: userPassword,
    })
    .then((response) => {
      if (response.status !== 200) return;
      setConfirmation();
    })
    .catch((error) => {
      if (error) {
        if (error.response.data.error === "Email not allowed") {
          emailState({ mail: userEmail, error: error.response.data.error });
        } else if (error.response.data.errors[0]) {
          emailState({
            mail: userEmail,
            error: error.response.data.errors[0].msg,
          });
        } else {
          pswState({ psw: userPassword, error: true });
        }
      }
    });
};

const postVerifyOtp = async (
  goToLogin: any,
  setOtpError: any,
  userEmail: string,
  userOtp: string
) => {
  await axios
    .post(apiUrl + `auth/confirm/user`, {
      email: userEmail,
      otp_code: userOtp,
    })
    .then((response) => {
      if (response.status !== 200) return;
      goToLogin();
    })
    .catch((error) => {
      setOtpError();
    });
};

/* **********  UPLOAD-PAGE  ***********/

export {
  // LOGIN
  postUserLogin,

  // REGISTER
  postUserRegister,

  // VERIFYOTP
  postVerifyOtp,
};
