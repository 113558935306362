import { TitlePage } from "../../components";

const ImprintPage = () => {
  return (
    <main id="imprint-page" className="h_full_hidden">
      <TitlePage title="Imprint" classes="uppercase" />
      <div className="imprint-text">
        <div className="scroll-container">
          <br/>
          Operated by and responsible for product & company information<br/><br/>
          <b>play TRACKS GmbH</b><br/>
          Stererfeldweg 589/4<br/>
          5754 Hinterglemm<br/>
          Austria<br/>
          <br/>
          <b>Objective of the company</b><br/>
          Marketing of hardware and software for video production<br/>
          <br/>
          VAT-ID: ATU72262124<br/>
          Company Registration: 468128 z<br/>
          Register Court: Landesgericht Salzburg (District Court)<br/>
          <br/>
          Phone: +43 660 4981800<br/>
          E-Mail: hello@play-tracks.com<br/>
          Internet: <a href={"https://www.play-tracks.com"}>https://www.play-tracks.com</a><br/>
          <br/>
          Users have the option,<br/>
          complaints to the online<br/>
          EU online dispute resolution platform<br/>
          at http://ec.europa.eu/odr.<br/>
          You can also send any complaints to<br/>
          the e-mail address given above<br/>
          above.<br/>
          <br/>
          <b>Concept, design and development:</b><br/>
          play TRACKS GmbH<br/>
          <br/>
          <br/>
          <b>Disclaimer</b><br/>
          The information provided by play TRACKS GmbH on this website has been compiled with the utmost care. Despite careful control, the fault-freeness can not be guaranteed. The play TRACKS GmbH therefore excludes all liability or guarantee with regard to the accuracy, completeness and actuality of the information provided on this website.<br/>
          <br/>
          Play TRACKS GmbH reserves the right to make changes or additions to the provided information or data at any time without notice. To the extent that our Internet sites contain forward-looking statements, these statements are based on convictions and assessments by play TRACKS GmbH and are therefore subject to risks and uncertainties. Play TRACKS GmbH is not obliged to update such forward-looking statements. Liability for such statements is expressly excluded.<br/>
          <br/>
          This offer contains links to external websites of third parties on whose content we have no influence. Therefore, we can not assume any liability for these third-party content. The respective provider or operator of the pages is always responsible for the contents of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, a permanent control of the content of the linked pages is not reasonable without concrete indications of an infringement. If we become aware of any legal infringements, we will immediately remove such links.<br/>
          <br/>
          <b>Copyright</b><br/>
          The content and works on these pages created or published by the site operators are subject to Austrian copyright law. The copying, processing, distribution and any kind of exploitation beyond the limits of copyright require the written consent of the respective author or creator. Downloads and copies of this page are only permitted for private, non-commercial use. Insofar as the contents on this site were not created by the operator, the copyrights of third parties are respected. If you are nevertheless aware of a copyright infringement, we ask for a corresponding note. If we become aware of legal violations, we will immediately remove such content.<br/>
          <br/>
          Disclosure according to § 25 Austrian Media Act
          <br/>   <br/>   <br/>   <br/>
        </div>
      </div>
    </main>
  );
};

export default ImprintPage;
