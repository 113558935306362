const SvgPlayControl = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18.4146023px"
      height="21.9151103px"
      viewBox="0 0 18.4146023 21.9151103"
      version="1.1"
    >
      <title>icons/video player/close blank copy 3</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="video-player/player-bottom"
          transform="translate(-163.980098, -47.675042)"
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth="1.5"
        >
          <g
            id="icons/video-player/close-blank-copy"
            transform="translate(164.730098, 47.632718)"
          >
            <path
              d="M0,1.79256657 L0,20.2074334 C9.55813795e-16,20.7597182 0.44771525,21.2074334 1,21.2074334 C1.1855446,21.2074334 1.36743045,21.1558119 1.52531213,21.058343 L16.439671,11.8509096 C16.9096154,11.5607877 17.0553904,10.9446323 16.7652685,10.4746879 C16.6835416,10.3423052 16.5720537,10.2308173 16.439671,10.1490904 L1.52531213,0.94165696 C1.05536773,0.651535083 0.439212265,0.797310043 0.149090388,1.26725445 C0.0516215601,1.42513613 -4.6681187e-16,1.60702197 0,1.79256657 Z"
              id="Path-3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgPlayControl;
