import { useEffect, useRef, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { pages } from "./pages";
import { TPageSelected } from "./utils/types";
import GlobalContext from "./context/GlobalContext";
import { Header, ModalFullViewVideo, Nav } from "./components";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import {Navbar} from "rsuite";

//ciao
function  App() {
  const router = createBrowserRouter(pages);
  const [userJwt, setUserJwt] = useState<string | null>();
  const [pageSelected, setPageSelected] = useState<TPageSelected>("/login");
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);
  const [videoUrlToView, setVideoUrlToView] = useState<string>("");
  const [toggleModalFullVideo, setToggleModalFullVideo] =
    useState<boolean>(false);
  /* PER NOTIFICHE  */
  const [notify, setNotify] = useState<boolean>(false);
  const [userIsOn, setUserIsOn] = useState<boolean>(true);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const tabIcon = document.querySelector(
    "link[rel*='icon']"
  ) as HTMLLinkElement;

  const handlerOpenNav = () => {
    setNavIsOpen((prev) => !prev);
  };

  const handleOpenFullVideo = () => {
    setToggleModalFullVideo((prev) => !prev);
  };

  // user per vedere se l'utente è online agganciato all'evento listner dentro allo useEffect(()=> {}, [])
  const changeUserOnline = () => {
    /* console.log("user è online ? ", !document.hidden); */
    setUserIsOn(!document.hidden);
  };

  // INTERVALLO per la tab
  const userIsOnline = () => {
    let pageTitle = "Play";
    let attentionMessage = "(1) Play";
    const pageFavicon = "/favicon.png";
    const notifyFavicon = "/faviconNotify.png";

    if (intervalRef.current === null && notify) {
      intervalRef.current = setInterval(() => {
        console.log("intervallo");
        if (document.title === "(1) Play") {
          document.title = pageTitle;
          tabIcon.href = pageFavicon;
        } else {
          document.title = attentionMessage;
          tabIcon.href = notifyFavicon;
        }
      }, 1000);
    }
  };

  // use effect per attivare l'interval
  useEffect(() => {
    if (userIsOn) {
      /* console.log("notifiche off");
      console.log("stop  l'intervallo   ================>"); */
      document.title = "Play";
      tabIcon.href = "/favicon.png";
      clearInterval(intervalRef.current !== null ? intervalRef.current : 0);
      intervalRef.current = null;
      setNotify(false);
    }
    if (notify && !userIsOn) {
      /* console.log("faccio partire il timout ================>"); */
      userIsOnline();
    }
  }, [notify, userIsOn]);

  useEffect(() => {
    const actualPage: TPageSelected = router.state.location
      .pathname as TPageSelected;

    const jwtExist = Cookies.get("token");

    // per vedere se l'utente è online
    document.addEventListener("visibilitychange", changeUserOnline);

    if (
      jwtExist &&
      !actualPage.includes("/login") &&
      !actualPage.includes("/register")
    ) {
      setUserJwt(jwtExist);
      router.navigate(actualPage);
      setPageSelected(actualPage);
    } else if (!jwtExist && actualPage.includes("/register")) {
      router.navigate(actualPage);
      setPageSelected(actualPage);
    } else if (!jwtExist && actualPage.includes("/login")) {
      return;
    } else if( !jwtExist && actualPage.includes("/lake-of-charity-2023")) {
      setPageSelected(actualPage);
      return;
    } else if( !jwtExist && actualPage.includes("/cinemate")) {
        setPageSelected(actualPage);
        return;
    }  else if(!jwtExist && actualPage.includes("/imprint")) {
      return;
    }  else if(!jwtExist && actualPage.includes("/privacy-policy")) {
      return;
    }  else if(!jwtExist && actualPage.includes("/cookie-policy")) {
      return;
    }  else if(!jwtExist && actualPage.includes("/licence-agreement")) {
      return;
    } else if(!jwtExist && actualPage.includes("/data-processing")) {
      return;
    } else if(!jwtExist && actualPage.includes("/terms")) {
        return;
    } else {
      router.navigate("/login");
      setPageSelected("/login");
    }
  }, []);

  return (
    <>
      <GlobalContext.Provider
        value={{
          router: router,
          userJwt: userJwt,
          setUserJwt: setUserJwt,
          pageSelected: pageSelected,
          setPageSelected: setPageSelected,
          navIsOpen: navIsOpen,
          handlerOpenNav: handlerOpenNav,
          videoToView: videoUrlToView,
          setVideoToView: setVideoUrlToView,
          videoFullIsOpen: toggleModalFullVideo,
          handleOpenFullVideo: handleOpenFullVideo,
          notify: notify,
          setNotify: setNotify,
          userOn: userIsOn,
        }}
      >
        <div className="App">
          { pageSelected.includes("/lake-of-charity-2023") || pageSelected.includes("/cinemate")  ? null : (
              <>
              <Header />
              <Nav />
              </>
            )}
          <RouterProvider router={router} />
        </div>
        {toggleModalFullVideo && <ModalFullViewVideo />}
      </GlobalContext.Provider>

      <ToastContainer
        limit={1}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
