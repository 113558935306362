const SvgLess15 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18.5354187px"
      height="17.8153791px"
      viewBox="0 0 18.5354187 17.8153791"
      version="1.1"
    >
      <title>icons/video player/close blank copy</title>
      <g
        id="layout-mvp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="video-player/player-bottom-copy"
          transform="translate(-110.710581, -48.761621)"
          fill="#FFFFFF"
        >
          <g
            id="icons/video-player/close-blank-copy"
            transform="translate(110.710015, 48.761621)"
          >
            <polygon
              id="Path"
              fillRule="nonzero"
              points="12.3283181 9.86237907 12.3283181 17.1443791 11.6463181 17.7383791 10.9863181 17.7383791 10.9863181 11.3913791 9.71031813 12.4693791 9.07231813 11.6663791 11.2063181 9.86237907"
            />
            <path
              d="M17.9529848,9.86237907 L17.9529848,10.9843791 L15.5329848,10.9843791 L15.4229848,12.9533791 C15.6649848,12.9167124 15.8629848,12.8983791 16.0169848,12.8983791 C16.8529848,12.8983791 17.4983181,13.1623791 17.9529848,13.6903791 C18.3416515,14.1450457 18.5359848,14.7427124 18.5359848,15.4833791 C18.5359848,16.2753791 18.2389848,16.8840457 17.6449848,17.3093791 C17.1683181,17.6467124 16.5669848,17.8153791 15.8409848,17.8153791 C15.3643181,17.8153791 14.8949848,17.7567124 14.4329848,17.6393791 L14.0039848,17.1223791 L14.0039848,16.6053791 C14.5099848,16.7080457 14.9499848,16.7593791 15.3239848,16.7593791 C15.9766515,16.7593791 16.4386515,16.6713791 16.7099848,16.4953791 C17.0326515,16.2900457 17.1939848,15.9307124 17.1939848,15.4173791 C17.1939848,14.8967124 17.0583181,14.5227124 16.7869848,14.2953791 C16.5156515,14.0680457 16.0719848,13.9543791 15.4559848,13.9543791 C15.1993181,13.9543791 14.7959848,13.9837124 14.2459848,14.0423791 C14.1726515,14.0497124 14.1103181,14.0570457 14.0589848,14.0643791 L14.2899848,9.86237907 L17.9529848,9.86237907 Z"
              id="Path"
              fillRule="nonzero"
            />
            <path
              d="M6.50535719,-0.861054814 C6.72074389,-0.853680521 6.93535066,-0.776931723 7.1132406,-0.627664345 L11.7095073,3.22906131 C12.1325819,3.58406311 12.1877659,4.2148187 11.8327641,4.63789337 C11.4777623,5.06096803 10.8470067,5.11615199 10.423932,4.7611502 L7.46162093,2.27437907 L7.46162093,11.0667429 C7.46162093,11.6190277 7.01390568,12.0667429 6.46162093,12.0667429 C5.90933618,12.0667429 5.46162093,11.6190277 5.46162093,11.0667429 L5.46162093,2.27837907 L2.50443183,4.7611502 C2.08135716,5.11615199 1.45060157,5.06096803 1.09559977,4.63789337 C0.740597978,4.2148187 0.795781942,3.58406311 1.21885661,3.22906131 L5.81512326,-0.627664345 C5.9930132,-0.776931723 6.20761997,-0.853680521 6.42300667,-0.861054814 L6.50535719,-0.861054814 Z"
              id="Combined-Shape"
              transform="translate(6.464182, 5.602561) rotate(-90.000000) translate(-6.464182, -5.602561) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgLess15;
