import { useContext } from "react";
import Svg from "../Svg/Svg";
import GlobalContext from "../../context/GlobalContext";

const NeverBeenHere = () => {
  const { router } = useContext(GlobalContext);
  return (
    <section className="never-been-here">
      <h3>Never been here?</h3>
      <button
        className="default default-color text-small"
        onClick={() => router.navigate("/register")}
      >
        Register for free
      </button>
      <div className="tutorial" onClick={() => router.navigate("/tutorial")}>
        <Svg className="play" /> Watch the tutorial
      </div>
    </section>
  );
};

export default NeverBeenHere;
