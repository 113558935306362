import { useContext, useEffect, useState } from "react";
import Svg from "../Svg/Svg";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import { checkResponseErrors } from "../../utils/FunctionCommon";

const OnSendingVideo = () => {
  const { router, setPageSelected, userJwt, setNotify } =
    useContext(GlobalContext);
  const [configData, setConfigData] = useState<{
    jobId: string;
    batchId: string;
    videoConfiguration: {
      type: string;
      format: string;
      aspect_ratio: string;
      autocreate: boolean;
      masterAudio: string;
    };
  }>();
  const [finalJob, setFinalJob] = useState<string>();

  /* ********** API ********** */
  const postCreateFinalJob = async (
    tokenJwt: string | null | undefined,
    videos: any[],
    config: {
      jobId: string;
      batchId: string;
      videoConfiguration: {
        videos?: { id: string; effect: string }[];
        type: string;
        format: string;
        aspect_ratio: string;
        autocreate: boolean;
        masterAudio: string;
      };
    },
    videoEffect: any[] | null
  ) => {
    config.videoConfiguration.videos = videoEffect
      ? videoEffect
      : videos.map((vid) => {
          return { id: vid, effect: "no_effect" };
        });
    const data = { ...config, videos };

    await axios
      .post(process.env.REACT_APP_API_URL + `finalJob/createJob`, data, {
        headers: {
          Authorization: `Bearer ${tokenJwt}`,
        },
      })
      .then((response) => {
        setFinalJob(response.data.id);
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          checkResponseErrors(error);
        }
      });
  };

  useEffect(() => {
    const routerState = router.state.location.state;
    if (!routerState) {
      setPageSelected("/");
      router.navigate("/");
      return;
    }

    if (routerState.videoEffect) {
      const { videoPathKey, config, videoEffect } = routerState;
      setConfigData(config);
      postCreateFinalJob(userJwt, videoPathKey, config, videoEffect);
      return;
    }

    const { videoPathKey, config } = routerState;
    setConfigData(config);
    postCreateFinalJob(userJwt, videoPathKey, config, null);
  }, []);

  useEffect(() => {
    if (!finalJob) return;
    /*  console.log("polling"); */

    const controlFinalJob = setInterval(() => {
      axios
        .get(process.env.REACT_APP_API_URL + `finalJob/status?id=${finalJob}`, {
          headers: { Authorization: `Bearer ${userJwt}` },
        })
        .then((response) => {
          if (response.data.status === "in_progress") return;

          if (response.data.status === "created") return;

          if (response.data.status === "completed") {
            setNotify(true);
            setTimeout(() => {
              setPageSelected("/video-processed");
              router.navigate(`/video-processed`, {
                state: { finalJob: finalJob },
              });
            }, 1000);
          }

          if (response.data.status === "failed") {
            setTimeout(() => {
              setPageSelected("/something-went-wrong");
              router.navigate(`/something-went-wrong`);
            }, 1000);
          }

          clearInterval(controlFinalJob);
          return;
        })

        .catch((error) => {
          if (error) {
            console.log(error);
            checkResponseErrors(error);
          }
        });
    }, 1000);

    return () => {
      clearInterval(controlFinalJob);
    };
  });

  return (
    <div className="container">
      <div className="icon">
        <Svg className="stars" />
      </div>
      <div className="text-primary">Adding some magic.</div>
      <div className="text-secondary">Takes a couple of minutes.</div>

      {/* LOAD ANIMATION */}
      <div className="loader-container">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default OnSendingVideo;
