import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import Svg from "../Svg/Svg";

const OnError = () => {
  const { router, setPageSelected } = useContext(GlobalContext);

  /* ********** Methods ********** */
  const goToYourVideos = () => {
    setPageSelected("/my-videos");
    router.navigate("/my-videos");
  };

  return (
    <>
      <div className="icon">
        <Svg className="monkey" />
      </div>
      <div className="text-primary">Sorry, but something went wrong.</div>
      <div className="text-secondary">
        Our aritficial intelligence still needs the intelligence of our super
        clever team. We'll take a look and get back to you as soon as we find
        the error. Thank you for your understanding.
      </div>

      <button className="default-big default-color " onClick={goToYourVideos}>
        Go to your videos
      </button>
    </>
  );
};

export default OnError;
