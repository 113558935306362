import { useContext, useEffect, useRef, useState } from "react";
import { Slider } from "antd";
import GlobalContext from "../../../context/GlobalContext";
import Svg from "../../Svg/Svg";
import { formatVideoTime } from "../../../utils/FunctionCommon";

interface CustomHTMLVideoElement extends HTMLVideoElement {
  webkitEnterFullScreen?: () => Promise<void>;
}

const ModalFullViewVideo = () => {
  const { handleOpenFullVideo, videoToView } = useContext(GlobalContext);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [videoMaxDuration, setVideoMaxDuration] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);

  const videoRef = useRef<CustomHTMLVideoElement>(null);

  const handleFullScreen = () => {
    const video = videoRef.current;
    if (!video) return;
    if (video.requestFullscreen) video.requestFullscreen();
    if (video.webkitEnterFullScreen) video.webkitEnterFullScreen(); // Safari
  };

  const handleMute = () => {
    const video = videoRef.current;
    if (!video) return;
    if (isMuted === false) {
      setIsMuted(true);
      video.muted = true;
    }

    if (isMuted === true) {
      setIsMuted(false);
      video.muted = false;
    }
  };

  const handlePlay = () => {
    const video = videoRef.current;
    if (!video) return;
    setIsPlay((prev) => !prev);
    video.play();
  };

  const handlePause = () => {
    const video = videoRef.current;
    if (!video) return;
    setIsPlay((prev) => !prev);
    video.pause();
  };

  const handleSkipForward = () => {
    const video = videoRef.current;
    if (!video) return;
    video.currentTime += 15;
  };

  const handleSkipBackward = () => {
    const video = videoRef.current;
    if (!video) return;
    video.currentTime -= 15;
  };

  const onChangeSlider = (value: number) => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = value;
    setCurrentTime(videoRef.current.currentTime);
  };

  useEffect(() => {
    const video = videoRef.current;

    const updateCurrentTime = () => {
      if (video) {
        setCurrentTime(video.currentTime);
      }
    };

    const handleLoadedMetadata = () => {
      if (video) {
        if (videoMaxDuration === 0) setVideoMaxDuration(video.duration);
        setVideoDuration(video.duration);
      }
    };

    video?.addEventListener("loadedmetadata", handleLoadedMetadata);

    video?.addEventListener("timeupdate", updateCurrentTime);

    return () => {
      video?.removeEventListener("timeupdate", updateCurrentTime);
      video?.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  return (
    <section id="modal-full-video">
      <div className="modal-action-container">
        <div className="actions">
          <div className="close-action" onClick={handleOpenFullVideo}>
            <Svg className="close" />
          </div>
          <div className="full" onClick={handleFullScreen}>
            <Svg className="full-screen" />
          </div>
        </div>
        <div className={`sound ${isMuted ? "muted" : ""}`} onClick={handleMute}>
          {isMuted ? (
            <Svg className="speaker-off" />
          ) : (
            <Svg className="speaker-on" />
          )}
        </div>
      </div>

      {/* video element */}

      <div className="video-container">
        <video
          ref={videoRef}
          src={videoToView}
          autoPlay
          playsInline
          muted
        ></video>
      </div>

      {/* video controls */}

      <div className="video-controls">
        <div className="cta-controls">
          <div className="progress-bar-container">
            <Slider
              value={currentTime}
              min={0}
              max={videoMaxDuration}
              step={0.01}
              onChange={onChangeSlider}
              tooltip={{ formatter: null }}
            />
            <div className="time">
              <span className="passed-time">
                {formatVideoTime(currentTime)}
              </span>
              <span className="remaning-time">
                - {formatVideoTime(videoDuration - currentTime)}
              </span>
            </div>
          </div>
          <div className="cta-video">
            <Svg className="less-15" onClick={handleSkipBackward} />
            {isPlay ? (
              <Svg className="play-control" onClick={handlePlay} />
            ) : (
              <Svg className="pause-control" onClick={handlePause} />
            )}
            <Svg className="plus-15" onClick={handleSkipForward} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModalFullViewVideo;
