const ArrowLeft = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>arrow-left</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-112.000000, -70.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="arrow-left" transform="translate(112.000000, 70.000000)">
            <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
            <path
              d="M10.7960988,7.8 L6.6,12 L10.7960988,16.2"
              id="Path-2"
            ></path>
            <line
              x1="12.383694"
              y1="6.8088512"
              x2="12.383694"
              y2="17.1911488"
              id="Line-2"
              transform="translate(12.383694, 12.000000) rotate(-90.000000) translate(-12.383694, -12.000000) "
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeft;
