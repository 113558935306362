const SvgPlus = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>plus</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-471.000000, -70.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="plus" transform="translate(471.000000, 70.000000)">
            <line
              x1="7.20991667"
              y1="11.7"
              x2="16.7525833"
              y2="11.7"
              id="Line"
            ></line>
            <line
              x1="11.98125"
              y1="6.92866667"
              x2="11.98125"
              y2="16.4713333"
              id="Line-Copy"
            ></line>
            <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgPlus;
