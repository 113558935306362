const SvgAudioOn = () => {
  return (
    <svg
      width="25.5px"
      height="25.5px"
      viewBox="0 0 25.5 25.5"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>play copy 2</title>
      <g
        id="design-elements"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="assets"
          transform="translate(-1006.250000, -69.250000)"
          stroke="#01A882"
          strokeWidth="1.5"
        >
          <g id="play-copy-2" transform="translate(1007.000000, 70.000000)">
            <circle id="Oval" cx="12" cy="12" r="12"></circle>
            <g id="Group" transform="translate(5.400000, 5.948939)">
              <rect
                id="Rectangle"
                x="0"
                y="3.15318916"
                width="2.59090909"
                height="5.18181818"
              ></rect>
              <path
                d="M2.59090909,3.35080744 L7.77272727,0 L7.77272727,11.4 L2.59090909,8.38217739 L2.59090909,3.35080744 Z M10.3466287,3.15318916 C10.9775338,4.01682552 11.2929863,4.88046189 11.2929863,5.74409825 C11.2929863,6.60773461 10.9775338,7.47137098 10.3466287,8.33500734 M12.3780473,2.33500734 C13.2546733,3.53500734 13.6929863,4.73500734 13.6929863,5.93500734 C13.6929863,7.13500734 13.2546733,8.33500734 12.3780473,9.53500734"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgAudioOn;
