const SvgPlus15 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="19.8554796px"
      height="18.8153791px"
      viewBox="0 0 19.8554796 18.8153791"
      version="1.1"
    >
      <title>icons/video player/close blank copy 2</title>
      <g
        id="layout-mvp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="video-player/player-bottom-copy"
          transform="translate(-211.782333, -47.761621)"
          fill="#FFFFFF"
        >
          <g
            id="icons/video-player/close-blank-copy-2"
            transform="translate(211.782333, 47.761621)"
          >
            <polygon
              id="Path"
              fillRule="nonzero"
              points="3.256 10.8623791 3.256 18.1443791 2.574 18.7383791 1.914 18.7383791 1.914 12.3913791 0.638 13.4693791 0 12.6663791 2.134 10.8623791"
            />
            <path
              d="M8.88066667,10.8623791 L8.88066667,11.9843791 L6.46066667,11.9843791 L6.35066667,13.9533791 C6.59266667,13.9167124 6.79066667,13.8983791 6.94466667,13.8983791 C7.78066667,13.8983791 8.426,14.1623791 8.88066667,14.6903791 C9.26933333,15.1450457 9.46366667,15.7427124 9.46366667,16.4833791 C9.46366667,17.2753791 9.16666667,17.8840457 8.57266667,18.3093791 C8.096,18.6467124 7.49466667,18.8153791 6.76866667,18.8153791 C6.292,18.8153791 5.82266667,18.7567124 5.36066667,18.6393791 L4.93166667,18.1223791 L4.93166667,17.6053791 C5.43766667,17.7080457 5.87766667,17.7593791 6.25166667,17.7593791 C6.90433333,17.7593791 7.36633333,17.6713791 7.63766667,17.4953791 C7.96033333,17.2900457 8.12166667,16.9307124 8.12166667,16.4173791 C8.12166667,15.8967124 7.986,15.5227124 7.71466667,15.2953791 C7.44333333,15.0680457 6.99966667,14.9543791 6.38366667,14.9543791 C6.127,14.9543791 5.72366667,14.9837124 5.17366667,15.0423791 C5.10033333,15.0497124 5.038,15.0570457 4.98666667,15.0643791 L5.21766667,10.8623791 L8.88066667,10.8623791 Z"
              id="Path"
              fillRule="nonzero"
            />
            <path
              d="M13.4330391,-0.861054814 C13.6484258,-0.853680521 13.8630325,-0.776931723 14.0409225,-0.627664345 L18.6371891,3.22906131 C19.0602638,3.58406311 19.1154478,4.2148187 18.760446,4.63789337 C18.4054442,5.06096803 17.7746886,5.11615199 17.3516139,4.7611502 L14.3893028,2.27437907 L14.3893028,11.0667429 C14.3893028,11.6190277 13.9415876,12.0667429 13.3893028,12.0667429 C12.8370181,12.0667429 12.3893028,11.6190277 12.3893028,11.0667429 L12.3893028,2.27837907 L9.4321137,4.7611502 C9.00903904,5.11615199 8.37828344,5.06096803 8.02328165,4.63789337 C7.66827985,4.2148187 7.72346382,3.58406311 8.14653848,3.22906131 L12.7428051,-0.627664345 C12.9206951,-0.776931723 13.1353018,-0.853680521 13.3506885,-0.861054814 L13.4330391,-0.861054814 Z"
              id="Combined-Shape"
              transform="translate(13.391864, 5.602561) scale(-1, 1) rotate(-90.000000) translate(-13.391864, -5.602561) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgPlus15;
