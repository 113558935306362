import Svg from "../Svg/Svg";
import Div from "../UI/Div";
import { useContext, useRef } from "react";
import GlobalContext from "../../context/GlobalContext";
import { TPageSelected } from "../../utils/types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
interface Props {
  label: string;
  to: TPageSelected;
  visible: boolean;
  svg?: string;
}

const NavItem = ({ label, to, visible, svg }: Props) => {
  const { router, handlerOpenNav, setPageSelected, pageSelected, setUserJwt } =
    useContext(GlobalContext);

  const notify = () =>
    toast.info(
      "A link and a message is copied to your clipboard. Paste and share the app. Thank you!",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        toastId: "id1",
      }
    );

  const goTo = (ev: any) => {
    if (label === "Sign out") {
      Cookies.remove("token");

      setUserJwt(null);
    }

    if (label === "Share the app") {
      const textToCopy =
        "Hi. I'd like to share some new AI for video creation with you from play: " +
        String(process.env.REACT_APP_API_URL).replace("api.", "") +
        'The AI\'s name is "Holly". And she is young and needs a lot of video to learn. Maybe you want to train and try too?';

      notify();

      ev.preventDefault();
      navigator.clipboard.writeText(textToCopy);

      return;
    }

    router.navigate(to);
    setPageSelected(to);
    handlerOpenNav();
  };

  if (!visible) return null;

  return (
    <Div
      className={`item ${label.includes("Copyright 2023") && "reserved"} ${
        pageSelected === to ? (to === "/login" ? "" : "active") : ""
      }`}
      onClick={(ev) => (
          label.includes("Copyright 2023") ? () => {} :
              to.includes("http") ? window.open(to, "_blank") :
              goTo(ev))
    }
    >
      {svg && <Svg className={svg} />}
      {label}
    </Div>
  );
};

export default NavItem;
