import { useContext } from "react";
// import { dateFormatConverter } from "../../utils/FunctionCommon";
import GlobalContext from "../../context/GlobalContext";

interface Props {
  videoList: any[] | [];
}

const VideoGridMyVideos = ({ videoList }: Props) => {
  const { router, setPageSelected } = useContext(GlobalContext);

  /* ********** METHODS ********** */
  const goToSeeVideos = (video: any) => {
    setPageSelected("/video-processed");
    router.navigate(`/video-processed`, {
      state: { video },
    });
  };

  return (
    <div className="my-video-container">
      <div className="video-container-scrollable">
        {videoList.length > 0 ? (
          videoList.map((video, i) => (
            <div key={i} className="video">
              <video
                key={`${video.id}-${i}`}
                preload="metadata"
                muted
                src={video.url + "#t=0.001"}
                playsInline
                onClick={() => {
                  goToSeeVideos(video);
                }}
              />
              {/* <div className="category">{video.category}</div>
              <div className="date">
                {dateFormatConverter(video.creation_date)}
              </div> */}
            </div>
          ))
        ) : (
          <>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoGridMyVideos;
