import { MainInfo, TitlePage } from "../../components";

const DeleteAccountPage = () => {
  return (
    <div id="delete-account-page">
      <TitlePage title="Delete my account" classes="uppercase" />
      <MainInfo text="Coming soon ..." classes="m-normal" />
    </div>
  );
};

export default DeleteAccountPage;
