const SvgReversePlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="28.9047548px"
      height="24.5000395px"
      viewBox="0 0 28.9047548 24.5000395"
      version="1.1"
    >
      <title>Group 2</title>
      <g
        id="layout-mvp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="04-scene-select-06"
          transform="translate(-287.001197, -265.249960)"
          stroke="#FFFFFF"
          strokeWidth="1.5"
        >
          <g id="reverse-active" transform="translate(247.000000, 249.000000)">
            <g id="Group-2" transform="translate(40.751197, 17.000000)">
              <path
                d="M1.62385122,6.92878759 L19.3691483,6.92878759 C23.807091,6.92878759 27.4047545,10.526451 27.4047545,14.9643938 C27.4047545,19.4023366 23.807091,23 19.3691483,23 L1.62385122,23 L1.62385122,23"
                id="Path"
              />
              <path d="M9.2,0 L0,7.01771624 L9.2,13.8" id="Path-2" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgReversePlayIcon;
