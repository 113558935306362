const Download = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>download</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-292.000000, -70.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="download" transform="translate(292.000000, 70.000000)">
            <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
            <path
              d="M17.4,14.4 L17.4,15.6 C17.4,16.9254834 16.4866609,18 15.36,18 L9.24,18 C8.11333911,18 7.2,16.9254834 7.2,15.6 L7.2,14.4"
              id="Path"
            ></path>
            <g
              id="Group"
              transform="translate(12.330425, 10.038493) rotate(180.000000) translate(-12.330425, -10.038493) translate(9.646558, 5.976986)"
            >
              <path
                d="M4.19946303,-1.16827066 L1.16827066,1.51559619 L4.19946303,4.19946303"
                id="Path-2"
                transform="translate(2.683867, 1.515596) rotate(90.000000) translate(-2.683867, -1.515596) "
              ></path>
              <line
                x1="2.65344203"
                y1="0.623013525"
                x2="2.65344203"
                y2="8.12301353"
                id="Line-2"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Download;
