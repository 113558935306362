import { useContext } from "react";
import { MainInfo, Svg, TitlePage } from "../../components";
import GlobalContext from "../../context/GlobalContext";

const SupportPage = () => {
  const { router, setPageSelected } = useContext(GlobalContext);
  return (
    <main id="support-page">
      <TitlePage title="Support" classes="uppercase" />
      <MainInfo text="Do you have any questions, feedback or ideas? We love to hear from you." />
      <button className="default-big  m-inline default-color">
        <a href="mailto:support@play-tracks.com">Send us an email</a>
      </button>
      <div
        className="tutorial"
        onClick={() => {
          setPageSelected("/tutorial");
          router.navigate("/tutorial");
        }}
      >
        <Svg className="play" /> Watch the tutorial
      </div>
    </main>
  );
};

export default SupportPage;
