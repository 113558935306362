const CheckSign = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>check-sign</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-202.000000, -70.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <g id="check-sign" transform="translate(202.000000, 70.000000)">
            <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
            <path
              d="M7.2,7.48125 L13.5899068,15.28125 L17.1708782,12.054667"
              id="Line-Copy"
              transform="translate(12.185439, 11.381250) scale(-1, 1) translate(-12.185439, -11.381250) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckSign;
