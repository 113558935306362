import { useContext } from "react";
import { IUserSelectScene } from "../../utils/types";
import Svg from "../Svg/Svg";
import GlobalContext from "../../context/GlobalContext";
import { mockResolution } from "../../utils/mock";

interface Props {
  videoList: IUserSelectScene[] | [];
  onSceneSelected: (id: string) => void;
}

const VideoGridSceneSelect = ({ videoList, onSceneSelected }: Props) => {
  const { setVideoToView, handleOpenFullVideo } = useContext(GlobalContext);

  // messo per importare la resolution, da spostare BUGBUG  DA TOGLIERE
  const resolutions = mockResolution;

  // ===== DA TENERE, Function per trovare la resolution ====
  // Check on videoList array for searching the resolution
  /**
   *
   * @param {string} path The video.path string file (like : user_100/batch_779/preprod_output/jobId_645/01_output_video-Scene-007_1_1_720p.webm)
   * @returns
   */
  const findVideoResolution = (path: string): string | null => {
    // Extracts the file name from the path
    const fileName = path.substring(
      path.lastIndexOf("/") + 1,
      path.lastIndexOf(".")
    );
    // Finds a matching resolution in the file name
    const matchedResolution = resolutions.find((resolution: any) =>
      fileName.endsWith(resolution)
    );
    // Returns the matched resolution or null if not found
    return matchedResolution || null;
  };

  return (
    <div className="select-video-container">
      <div className="video-container-scrollable">
        {videoList.length > 0 ? (
          videoList.map((video, i) => {
            // DA NON TOCCARE, QUESTA PARTE FUNZIONA
            const resolution = findVideoResolution(video.path);
            return (
              <div
                key={i}
                className={`video ${video.selected ? "selected" : ""}`}
              >
                {resolution ? (
                  <span className="resolution">{resolution}</span>
                ) : null}
                <video
                  key={`${video.id}-${i}`}
                  preload="metadata"
                  playsInline
                  muted
                  src={video.url + "#t=0.001"}
                  onClick={() => {
                    setVideoToView(video.url + "#t=0.001");
                    handleOpenFullVideo();
                  }}
                />
                <div
                  className="select-video-toggle"
                  onClick={() => onSceneSelected(String(video.id))}
                >
                  <Svg className="check-sign" />
                  {video.selected ? "Selected" : "Select"}
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="placeholder-container">
              <div className="video"></div>
              <div className="text">
                <span></span>
                <span></span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoGridSceneSelect;
