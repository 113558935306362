import { TitlePage } from "../../components";

const PrivacyPage = () => {
  return (
    <main id="privacy-page" className="h_full_hidden">
      <TitlePage title="Privacy" classes="uppercae" />
      <div className="privacy-text">
        <div className="scroll-container">
         <br/>
          <b>Privacy Policy for play TRACKS GmbH and Holly App   </b>     <br/>
          <br/>
          At play TRACKS GmbH, we take your privacy seriously and are committed to protecting your personal data.
          This Privacy Policy explains how we collect, use, and protect your personal data when you use our website,
          https://play-ai.video        <br/>
          <br/>
          <b>Data Controller </b>       <br/>
          The data controller for the personal data we collect on our website is play TRACKS GmbH, with registered office at Stererfeldweg 589, 5754 Hinterglemm, Austria. You can contact us at hello@play-tracks.com if you have any questions about this Privacy Policy.
            <br/>      <br/>
          <b>Data We Collect   </b>     <br/>
          We collect personal data that you provide to us when you use our website, such as when you sign up for our newsletter or submit a contact form. The personal data we collect may include your name, email address, phone number, and any other information you provide to us.
            <br/>      <br/>
          We also collect non-personal data when you use our website, such as your IP address, browser type, and operating system.
            <br/>      <br/>
          <b>How We Use Your Data   </b>     <br/>
          We use your personal data to provide you with the services you request, such as sending you our newsletter or responding to your inquiries. We may also use your personal data to send you marketing communications about our products and services, but only if you have given us your consent to do so.
            <br/>      <br/>
          We use non-personal data to analyze the performance and functionality of our website and to improve our services.
            <br/>      <br/>
          <b>Legal Basis for Processing Your Data</b>
            <br/>
          We process your personal data on the basis of your consent, which you give us when you sign up for our newsletter or submit a contact form. You have the right to withdraw your consent at any time by contacting us at hello@play-tracks.com.
            <br/>
          We may also process your personal data if it is necessary for our legitimate interests, such as improving our website or responding to your inquiries.
            <br/>      <br/>
          <b>Data Sharing and Transfers</b>
            <br/>
          We may share your personal data with third-party service providers who perform services on our behalf, such as email marketing services or website hosting services. We will only share your personal data with third-party service providers who have agreed to comply with our data protection standards and who are bound by a contract to keep your personal data confidential and secure.
            <br/>      <br/>
          We may transfer your personal data to countries outside the European Economic Area (EEA) if we have your consent to do so, or if it is necessary for the performance of a contract with you or for our legitimate interests. If we transfer your personal data outside the EEA, we will ensure that appropriate safeguards are in place to protect your personal data, such as standard contractual clauses.
            <br/>      <br/>
          <b>Data Retention</b>
            <br/>
          We will retain your personal data for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required by law.
            <br/>      <br/>
          <b>Your Rights</b>
            <br/>
          You have the right to access, rectify, or erase your personal data, as well as the right to restrict or object to the processing of your personal data. You also have the right to data portability and the right to lodge a complaint with a supervisory authority if you believe your personal data has been processed in violation of applicable data protection laws.
            <br/>      <br/>
          <b>Contact Us</b>
            <br/>
          If you have any questions or concerns about our Privacy Policy or how we process your personal data, please contact us at
          <a href="mailto:hello@play-tracks.com">hello@play-tracks.com</a>
          <br/>            <br/>            <br/>

        </div>
      </div>
    </main>
  );
};

export default PrivacyPage;
