const SvgSpeakerOff = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="23px"
      height="20.5023602px"
      viewBox="0 0 23 20.5023602"
      version="1.1"
    >
      <title>icons/video player/close blank copy</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="video-player/button-audio"
          transform="translate(-14.250000, -10.163666)"
          stroke="#D8D8D8"
          strokeWidth="1.5"
        >
          <g id="Group" transform="translate(15.000000, 10.914898)">
            <rect
              id="Rectangle"
              x="0"
              y="5.25531526"
              width="4.31818182"
              height="8.63636364"
            />
            <path
              d="M4.31818182,5.58467906 L12.9545455,0 L12.9545455,19 L4.31818182,13.9702957 L4.31818182,5.58467906 Z M16.5,7.58510188 L21.5,12.5851019 M16.5,12.5851019 L21.5,7.58510188"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgSpeakerOff;
