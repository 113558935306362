interface Props {
  text: string;
  classes?: string;
}

const MainInfo = ({ text, classes }: Props) => {
  return <p className={`main-info ${classes ?? ""}`}>{text}</p>;
};

export default MainInfo;
