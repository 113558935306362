const Support = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>support</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="assets" transform="translate(-381.000000, -70.000000)">
          <g id="support" transform="translate(381.000000, 70.000000)">
            <circle
              id="Oval"
              stroke="currentColor"
              strokeWidth="1.5"
              cx="12"
              cy="12"
              r="11.25"
            ></circle>
            <path
              d="M9.75588774,9.74670493 C9.80136275,8.15983739 10.4192797,7.04215531 12.1854391,7.04215531 C13.9515986,7.04215531 14.4383409,8.2522795 14.4383409,9.42608492 C14.4383409,10.5998903 13.1952,11.3891104 12.6336,12 C12.072,12.6108896 12,12.9096817 11.9856,14.0156168"
              id="Path-4"
              stroke="currentColor"
              strokeWidth="1.5"
            ></path>
            <circle
              id="Oval"
              fill="currentColor"
              cx="12"
              cy="16.8"
              r="1.2"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Support;
