import React from "react";

const SvgUpload = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>play copy</title>
      <g
        id="design-elements"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="assets"
          transform="translate(-901.000000, -70.000000)"
          stroke=""
          strokeWidth="1.5"
        >
          <g id="play-copy" transform="translate(901.000000, 70.000000)">
            <circle id="Oval" cx="12" cy="12" r="11.25"></circle>
            <g id="Group" transform="translate(7.200000, 5.376986)">
              <path
                d="M6.64602099,-1.76827066 L3.61482862,1.51559619 L6.64602099,4.79946303"
                id="Path-2"
                transform="translate(5.130425, 1.515596) rotate(90.000000) translate(-5.130425, -1.515596) "
              ></path>
              <path
                d="M10.2,7.22301353 L10.2,9.62301353 C10.2,10.9484969 9.28666089,12.0230135 8.16,12.0230135 L2.04,12.0230135 C0.91333911,12.0230135 0,10.9484969 0,9.62301353 L0,7.22301353"
                id="Path"
              ></path>
              <line
                x1="5.1"
                y1="0.623013525"
                x2="5.1"
                y2="8.12301353"
                id="Line-2"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgUpload;
