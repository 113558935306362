import OnError from "../../components/UI/OnError";

const ErrorPage = () => {
  return (
    <main id="error-page" className="h_full-nav btn_inline">
      <OnError />
    </main>
  );
};

export default ErrorPage;
