import ArrowDown from "./SvgArrowDown";
import ArrowLeft from "./SvgArrowLeft";
import CheckSign from "./SvgCheckSign";
import PlayLogo from "./SvgPlayLogo";
import PlaySign from "./SvgPlaySign";
import ReversePlay from "./SvgReversePlay";
import SignOut from "./SvgSignOut";
import SlowMotion from "./SvgSlowMotion";
import Close from "./SvgClose";
import Download from "./SvgDownload";
import Menu from "./SvgMenu";
import Play from "./SvgPlay";
import Plus from "./SvgPlus";
import Share from "./SvgShare";
import Support from "./SvgSupport";
import Trash from "./SvgTrash";
import SvgUpload from "./SvgUpload";
import CheckBig from "./SvgCheckBig";
import SvgCamera from "./SvgCamera";
import SvgStars from "./SvgStars";
import SvgHand from "./SvgHand";
import SvgMonkey from "./SvgMonkey";
import SvgSpeaker from "./SvgSpeaker";
import SvgAudioOn from "./SvgAudioOn";
import SvgAudioOff from "./SvgAudioOff";
import SvgReversePlayIcon from "./SvgReversePlayIcon";
import SvgSlowMotionIcon from "./SvgSlowMotionIcon";
import SvgFullScreen from "./SvgFullScreen";
import SvgSpeakerOff from "./SvgSpeakerOff";
import SvgPlayControl from "./SvgPlayControl";
import SvgPauseControl from "./SvgPauseControl";
import SvgPlus15 from "./SvgPlus15";
import SvgLess15 from "./SvgLess15";
import SvgSpeakerOn from "./SvgSpeakerOn";

export type SvgProps = {
  classes?: string;
  className?: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Svg = ({ classes, className, ...rest }: SvgProps) => {
  const supportClass = classes ? classes : "";

  const preClick = (e: any) => {
    rest.onClick && rest.onClick(e);
  };

  const renderSvg = () => {
    switch (className) {
      case "play-logo":
        return <PlayLogo />;
      case "menu":
        return <Menu />;

      case "play-sign":
        return <PlaySign />;

      case "play":
        return <Play />;

      case "check-sign":
        return <CheckSign />;

      case "check-big":
        return <CheckBig />;

      case "sign-out":
        return <SignOut />;

      case "close":
        return <Close />;

      case "plus":
        return <Plus />;

      case "reverse-play":
        return <ReversePlay />;

      case "slow-motion":
        return <SlowMotion />;

      case "reverse-play-icon":
        return <SvgReversePlayIcon />;

      case "slow-motion-icon":
        return <SvgSlowMotionIcon />;

      case "support":
        return <Support />;

      case "trash":
        return <Trash />;

      case "share":
        return <Share />;

      case "download":
        return <Download />;

      case "arrow-down":
        return <ArrowDown />;

      case "arrow-left":
        return <ArrowLeft />;

      case "upload":
        return <SvgUpload />;

      case "camera":
        return <SvgCamera />;

      case "stars":
        return <SvgStars />;

      case "hand":
        return <SvgHand />;

      case "monkey":
        return <SvgMonkey />;

      case "speaker":
        return <SvgSpeaker />;

      case "audio-on":
        return <SvgAudioOn />;

      case "audio-off":
        return <SvgAudioOff />;

      case "speaker-off":
        return <SvgSpeakerOff />;

      case "speaker-on":
        return <SvgSpeakerOn />; // non va bene

      case "play-control":
        return <SvgPlayControl />;

      case "pause-control":
        return <SvgPauseControl />;

      case "plus-15":
        return <SvgPlus15 />;

      case "less-15":
        return <SvgLess15 />;

      case "full-screen":
        return <SvgFullScreen />; // non va bene

      default:
        return <></>;
    }
  };

  return (
    <div
      className={"svg " + className + " " + supportClass}
      onClick={!!rest.onClick ? preClick : undefined}
      style={{ ...rest.style, cursor: !!rest.onClick ? "pointer" : "" }}
      {...rest}
    >
      {renderSvg()}
    </div>
  );
};

export default Svg;
