const SvgReversePlay = () => {
  return (
    <svg
      width="80px"
      height="54px"
      viewBox="0 0 80 54"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>reverse-play</title>
      <g
        id="design-elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="assets" transform="translate(-253.000000, -209.000000)">
          <g id="reverse-play" transform="translate(253.000000, 209.000000)">
            <rect
              id="Rectangle-Copy-19"
              fill="#E2BC00"
              opacity="0.15"
              x="0"
              y="0"
              width="80"
              height="54"
            ></rect>
            <g
              id="reverse"
              transform="translate(31.000000, 19.358491)"
              stroke="#E2BC00"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path
                d="M2.09351616,4.60403441 L13.5676565,4.60403441 C16.5165766,4.60403441 18.9071487,6.99460651 18.9071487,9.94352664 C18.9071487,12.8924468 16.5165766,15.2830189 13.5676565,15.2830189 L2.09351616,15.2830189 L2.09351616,15.2830189"
                id="Path"
              ></path>
              <polyline
                id="Path-2"
                points="6 0 0 4.66312564 6 9.16981132"
              ></polyline>
            </g>
            <line
              x1="58"
              y1="29.5471698"
              x2="80"
              y2="29.5471698"
              id="Path-Copy-8"
              stroke="#E2BC00"
              strokeWidth="2"
            ></line>
            <line
              x1="0"
              y1="29.5471698"
              x2="24"
              y2="29.5471698"
              id="Path-Copy-9"
              stroke="#E2BC00"
              strokeWidth="2"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgReversePlay;
