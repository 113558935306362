import { useContext, useEffect, useState } from "react";

import { IUserSelectScene } from "../../utils/types";
import { MainInfo, Svg, TitlePage } from "../../components";
import VideoGridManualEdit from "../../components/VideosGrid/VideoGridManualEdit";
import GlobalContext from "../../context/GlobalContext";

const ManualEditPage = () => {
  const { router, setPageSelected, navIsOpen } = useContext(GlobalContext);
  const [draggableVideos, setDraggableVideos] = useState<IUserSelectScene[]>(
    []
  );
  const [batchId, setBatchId] = useState<string>();
  const [jobId, setJobId] = useState<string>();
  const [configData, setConfigData] = useState<{
    jobId: string;
    batchId: string;
    videoConfiguration: {
      type: string;
      format: string;
      aspect_ratio: string;
      autocreate: boolean;
      masterAudio: string;
    };
  }>();

  /* ********** Methods ********** */
  const goBack = () => {
    setPageSelected("/manual-edit");
    router.navigate(-1);
  };

  const goNext = () => {
    setPageSelected("/add-effects");

    let videos = [];

    for (let i = 0; i < draggableVideos.length; i++) {
      const video = draggableVideos[i];

      video.selected = false;

      const videoForEffect = { ...video, effect: "no_effect" };

      videos.push(videoForEffect);
    }

    router.navigate(`/scene-select/${batchId}/manual-edit/add-effects`, {
      state: {
        videos,
        config: configData,
      },
    });
  };

  const onDropVideos = (newArrayVideos: IUserSelectScene[]) =>
    setDraggableVideos(newArrayVideos);

  useEffect(() => {
    const routerState = router.state.location.state;

    if (!draggableVideos) {
      if (!routerState) {
        setPageSelected("/");
        router.navigate("/");
      }
      return;
    }
    const { videos, newJobId, config } = routerState;
    setBatchId(config.batchId);
    setJobId(newJobId);
    setConfigData({ ...config, jobId: newJobId });
    setDraggableVideos(videos);
  }, []);

  return (
    <main id="manual-edit-page" className="h_full-nav btn-inline">
      <TitlePage title="Manual edit" classes="uppercase" />
      <MainInfo text="Drag the scenes into your favorite order" />

      <VideoGridManualEdit
        videos={draggableVideos}
        onDropVideos={onDropVideos}
      />

      <div className="action-container">
        <div className="go-back" onClick={goBack}>
          <Svg className="arrow-left" /> Back
        </div>
        <button
          className={`default w-96 ${navIsOpen ? "btn-translate" : ""}`}
          onClick={goNext}
        >
          Next
        </button>
      </div>
    </main>
  );
};

export default ManualEditPage;
